import React, {useState, useContext, useEffect} from 'react'
import {AuthContext} from "../AuthContext"
import axiosAPI from '../services/axiosapi'

export default function ChangeCity(props) {

    const {setToken} = useContext(AuthContext)
   // const firstTime= useRef(true)
    const [city, setCity]=useState(props.city)
    const [cities, setCities]=useState('') 
    

    function handleCity(ev){
        const { value} = ev.target
        if(value !== city ){
            sessionStorage.clear()
        }
        setCity(value)
      }
      
    useEffect(() => {
        /*if(firstTime.current){
            firstTime.current=false
            return
        }*/
        const tkn=localStorage.getItem('token')||false
        const headers= {
            'Content-Type':'application/json',
            'Authorization': `Bearer ${tkn}`,
        }
        axiosAPI.post("refresh.php",{city:city},{headers})
        .then(response =>{
           // console.log(response)
            const {error, token, cities}=response.data
           // console.log("resp",response)
            if(parseInt(error)<0){
                localStorage.removeItem('token')
                setToken(false)
            }else{
                //sessionStorage.clear()
                localStorage.setItem('token',token)
                setToken(token)
                setCities(cities)
            
            } 

        })
          
    }, [city,setToken])

    const cityOptions = cities?cities.map(c=><option key={`opt_${c.city_id}`} value={c.city_id}>{c.city}</option>):''
    return (
        <>
          <label htmlFor='selectCity'>Φορέας</label>
          <select name="selectCity" id="selectCity" className='change-city' value={city} onChange={(ev)=>handleCity(ev)}>
            <option  value={0}>Επιλέξτε Φορέα</option>
            {cityOptions}
          </select>
        </>
    )
}
