import React, {useEffect, useState, useRef, useContext} from 'react'
import {useParams, useNavigate} from 'react-router-dom'
import { AuthContext } from '../AuthContext'
import axiosAPI from '../services/axiosapi'
import CreatePass from './CreatePass'
//import { decodeToken } from '../utils/util'
import '../utils/fontawesome'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import "../style/login.css"
//import { tr } from 'date-fns/locale'
export default function Gauth(props) {

    const {setToken}  = useContext(AuthContext)
    //const payload = decodeToken(token)
    const PASSLEN = 8
    const timerID=useRef(null)
    const passInput = useRef(null)
    const navigate = useNavigate()
    const {key} = useParams()
    const [inputType, setInputType]=useState('password')
    const [actionStatus, setActionStatus]=useState(null)
    const [message, setMessage]=useState('')
    const [msgClass, setMsgClass] = useState('')
    const [image, setImage] = useState('')
    const [qrurl, setQrurl] = useState('')
    const [newPassWord, setNewPassWord]=useState('')
    const [verifyPassWord, setVerifyPassWord]=useState('')
    const [isUpdatePassPressed, setIsUpdatePassPressed]=useState(false)
    const [isCompleteChangeVCodePressed, setCompleteChangeVCodePressed]=useState(false)
    


    useEffect(() => {
        axiosAPI.post("checkauth.php",{urlkey:key})
        .then(response=>{
            console.log(response.data)
            const {error, errorMessage, status}=response.data
            if(error>0){
                setMsgClass('err')
                setMessage(errorMessage)

            }else{
                if(status && status==="CHVC"){
                    const {imageData, qrCodeUrl}=response.data
                    setImage(imageData)
                    setQrurl(qrCodeUrl)
                }
                
            }
            setActionStatus(status)
        })
    }, [key])

    useEffect(() => {
        return () => {
              clearTimeout( timerID.current)
        }
    }, [])

    function copyUrl(ev){
        try {
            navigator.clipboard.writeText(qrurl)
            .then(()=>{

                setMsgClass('suc')
                setMessage('Ο σύνδεσμος αντιγράφηκε')
                timerID.current=setTimeout(()=>{
                    setMessage('')
                    setMsgClass('')
                },1500)
            })
            .catch((err)=>{
                setMsgClass('err')
                setMessage(err)
            })
        }catch (err){
            setMsgClass('err')
            setMessage(`Σφάλμα ${err}`)
        }
    }

    function handleChangePass(ev){
        const {name, value} = ev.target
        if(name==='newPassWord'){
            setNewPassWord(value)
        }else if(name==='verifyPassWord'){
            setVerifyPassWord(value)
        }
    }

    function updatePass(ev){
        ev.preventDefault()
        setMessage('')
        setIsUpdatePassPressed(true)
        if(newPassWord.length < PASSLEN){
            const msg=`Ο κωδικός πρόσβασης πρέπει να έχει τουλάχιστο ${PASSLEN} χαρακτήρες!`
            setMsgClass('err')
            setMessage(msg)
            setIsUpdatePassPressed(false)
            return
        }
        if(newPassWord !== verifyPassWord){
            setMsgClass('err')
            const msg="Ο νέος κωδικός πρόσβασης δεν ταιριάζει με την επιβεβαίωση!"
            setMessage(msg)
            setIsUpdatePassPressed(false)
            return
        }
        axiosAPI.post("checkauth.php",{urlkey:key, newpass:newPassWord, verifypass:verifyPassWord})
        .then(response=>{
            console.log(response.data)
            const {error, errorMessage}=response.data
            if(error>0){
                setMsgClass('err')
                setMessage(errorMessage)
                setIsUpdatePassPressed(false)
            }else{
                setMsgClass('suc')
                setMessage(errorMessage)
                timerID.current=setTimeout(()=>{
                    setMessage('')
                    setIsUpdatePassPressed(false)
                    navigate('/', {replace:true})
                },1500)
            }
        })

    }

    function mouseDown(ev){
        if(ev.button===0){
            setInputType("text")
        }
    }
    function mouseUp(ev){
        if(ev.button===0){
            setInputType("password")
            passInput.current.focus()
        }
    }

    function completeChangeVCode(ev){
        ev.preventDefault()
        setCompleteChangeVCodePressed(true)
        axiosAPI.post("checkauth.php",{urlkey:key,status:actionStatus})
        .then(response=>{
            const {error, errorMessage, token}=response.data
            if(error !== 0){
                setMessage(errorMessage)
                setCompleteChangeVCodePressed(false)
            }else{
                setMsgClass('suc')
                setMessage(errorMessage)
                timerID.current=setTimeout(()=>{
                    setMessage('')
                    setIsUpdatePassPressed(false)
                    localStorage.setItem('token', token)
                    setToken(token)
                    navigate('/', {replace:true})
                },1500)

            }

        })
    }
    
    function autoPass(p){
        setNewPassWord(p)
        setVerifyPassWord(p)
    }

    //console.log(actionStatus)
    if(!actionStatus)
        return(
            <div className="login-container">
                <h1>Έλεγχος url</h1>
                <p>check...</p>
                
            </div>
        )
    else if(actionStatus==="CHVC")
        return (
            <div className="login-container">
                <h1>Authenticator Key</h1>
                <p className="help">Αν χρησιμοποιείτε την εφαρμογή Επαληθευτής (Google Validator) σκανάρετε την παρακάτω εικόνα</p>
                <img src={`data:image/png;base64,${image}`} className="qrcode" alt="QR-CODE"/>
                <p className="help">
                   Aν χρησιμοποιείτε την εφαρμογή WinAuth <button className="link" onClick={(ev)=>copyUrl(ev)}>αντιγράψτε</button> το URL
                </p>
                <div className={`login-message ${msgClass}`}>
                    {message}
                </div>
                <button className="login-btn" 
                    onClick={(e)=>completeChangeVCode(e)} 
                    disabled = {isCompleteChangeVCodePressed}
                >
                    
                    Συνέχεια
                </button>
                
            </div>
        )
    else if(actionStatus==="CHP")
        return (
            <div className="login-container">
                <h1>Αλλαγή κωδικού πρόσβασης</h1>
                <CreatePass updatePass={false} updateUser={autoPass}/>
                <div>
                    <label htmlFor="newPassWord" >Νέος Κωδικός Πρόσβασης</label>
                    <input ref={passInput} name="newPassWord" id="newPassWord" type={inputType} value={newPassWord} onChange={(e)=>handleChangePass(e)} onFocus={()=>setMessage('')}/>
                    <FontAwesomeIcon icon={['fas', 'eye']} className="icons" onMouseDown={(e)=>mouseDown(e)} onMouseUp={(e)=>mouseUp(e)}/>
                    {/*<FontAwesomeIcon icon={['fas', 'copy']} className="icons" />   */}  
                </div>
                <label htmlFor="verifyPassWord" >Επιβεβαίωση Κωδικού Πρόσβασης</label>
                <input name="verifyPassWord" id="verifyPassWord" type="password" value={verifyPassWord} onChange={(e)=>handleChangePass(e)} onFocus={()=>setMessage('')}/>
                <div className={`login-message ${msgClass && 'msgClass'}`}>
                    {message}
                </div>
                
                <button className="login-btn" disabled={isUpdatePassPressed} onClick={(e)=>updatePass(e)}>Συνέχεια</button>
            </div>
        )
    else if (actionStatus==="ERR")
        return (
            <div className="login-container">
                <h1>Σφάλμα</h1>
               
                <p className={`login-message err`}>{message}</p>
                <button className="ret-link"  onClick={(e)=>{navigate('/', {replace:true})}}>Επιστροφή</button>
            </div>
        )
    
}
