import React, {useState, useContext, useRef, useEffect} from 'react'
import axios from '../services/axiosapi'
import Select from 'react-select'
import { AuthContext } from '../AuthContext'
import "../style/report.css"
import DatePicker ,{registerLocale, setDefaultLocale} from 'react-datepicker'
import InputMask from 'react-input-mask'
import "react-datepicker/dist/react-datepicker.css"
import {el} from 'date-fns/locale/'
import Loader from "./Loader"



registerLocale('el',el)
setDefaultLocale('el')

export default function Report(props) {
 
    const { setToken}  = useContext(AuthContext)
    const [reportYear,setReportYear] = useState(new Date().getFullYear())
    const [reportMonth, setReportMonth]= useState([])
    const [info , setInfo] = useState({err:0,msg:""})
    const [customReport, setCustomReport] = useState(false)
    const [reportDateFrom, setReportDateFrom] = useState(null)
    const [reportDateTo, setReportDateTo] = useState(null)
    const [isLoad, setIsLoad] = useState(true)
    const timerID=useRef(null)

    const noOptionsText="Δεν υπάρχουν επιλογές"
    const brandColor = 'var(--bd-color)'
    const monthOptions = [
        {value:1, label:'Ιανουάριος'},
        {value:2, label:'Φεβρουάριος'},
        {value:3, label:'Μάρτιος'},
        {value:4, label:'Απρίλιος'},
        {value:5, label:'Μάιος'},
        {value:6, label:'Ιούνιος'},
        {value:7, label:'Ιούλιος'},
        {value:8, label:'Αύγουστος'},
        {value:9, label:'Σεπτέμβριος'},
        {value:10, label:'Οκτώβριος'},
        {value:11, label:'Νοέμβριος'},
        {value:12, label:'Δεκέμβριος'}
    ]

    const periodOptions = [
        {value:61, label:'1ο Εξάμηνο'},
        {value:62, label:'2ο Εξάμηνο'},
        {value:41, label:'1ο Τετράμηνο'},
        {value:42, label:'2ο Τετράμηνο'},
        {value:43, label:'3ο Τετράμηνο'},
        {value:31, label:'1ο Τρίμηνο'},
        {value:32, label:'2ο Τρίμηνο'},
        {value:33, label:'3ο Τρίμηνο'},
        {value:34, label:'4ο Τρίμηνο'}
    ]

    function handleMultiSelectChange(e){
        //console.log(e)
        setReportMonth(Array.isArray(e)? e.map(x=>x.value):[])
    }

    function handleSelectChange(v){
            setReportMonth(v?[v.value]:[])
    }

    function handleChange(ev){
        const {value} = ev.target
        setReportYear(value||null)
    }

    function handleChangeDate(field, value){
        const  dateVal  = value ? value.toLocaleDateString('fr-CA') :null
        if(field==='reportDateFrom'){
            setReportDateFrom(dateVal)
        }else if (field==='reportDateTo'){
            setReportDateTo(dateVal)
        }
        
    }

    function handleChangeRadio(ev){
        const {value} = ev.target
        //console.log(value)
        setCustomReport(parseInt(value)===0?false:true)
    }
          
        

    function handleClick(ev){
        const offset = new Date(reportDateFrom).getTimezoneOffset()
        const offset2 = new Date(reportDateTo).getTimezoneOffset()
        const df =reportDateFrom ? new Date(Date.parse(reportDateFrom) + offset * 60*1000) : null
        const dt = reportDateTo ? new Date(Date.parse(reportDateTo)  +offset2 * 60 *1000) : null
        const diff = (dt - df ) / (1000 * 3600 *24) + 1
        let error=0
        if (diff > 366) {
            setInfo({err:1,msg:"Το διάστημα αναζήτησης δεν πρέπει να υπερβαίνει το ένα έτος"})
            error++
        }else if (diff <=0){
            setInfo({err:1,msg:"Η ημερομηνία Έως δεν πρέπει να είναι μικρότερη από την ημερομηνία από"})
            error++
        }
        if(error > 0){
            timerID.current=setTimeout(()=>{
                setInfo({err:0,msg:""})
            },5000)
            return
        }
        setIsLoad(false)
        setInfo({err:0,msg:""})
        const tkn=localStorage.getItem('token')||false
        const headers= {
        'Authorization': `Bearer ${tkn}`,
        'Content-Type' : "application/json",
        }
        axios.post("report.php",{reportYear:reportYear,reportMonth:reportMonth,reportDateFrom, reportDateTo, mode:"CRRP",customReport },{headers})
        .then(response=>{
            //console.log(response.data)
            setIsLoad(true)
            const {error, message,  token}=response.data
            if(parseInt(error)<0){
                //setUpdateMessage({error,message,affected:affected})
                localStorage.removeItem('token')
                setToken(false)
            } else {
                localStorage.setItem('token',token)
                setToken(token)
                setInfo({err:error,msg:message})
                if(parseInt(error)===0){
                    const {tempFile, fileName, ext} =response.data
                    const newURL = `${axios.defaults.baseURL}download.php?t=${tempFile}&f=${fileName}&e=${ext}`;
                    window.location.replace(newURL)
                } 
                timerID.current=setTimeout(()=>{
                    setInfo({err:0,msg:""})
                },5000)
            }

        })
    }


    const customStyles = {
        control: (base, state) => ({
            ...base,
            paddingTop:'0.4em',
            paddingBottom:'0.4em',
            boxShadow: state.isFocused ? 0 : 0,
            borderColor: state.isFocused
            ? brandColor
            : base.borderColor,
            '&:hover': {
            borderColor: state.isFocused
                ? brandColor
                : base.borderColor,
            },
        }),
            multiValueLabel: (styles) => ({
                ...styles,
                color:'dimgrey',backgroundColor:'aliceblue',fontSize:'1em', padding:'0.5em'
            }),
        }
            
        function  filterOption (option, inputValue){
            return inputValue ?
                    !option.label.substring(0,inputValue.length).localeCompare(inputValue, 'el-GR',{sensitivity:'base'}): true
        }

        useEffect(() => {
      
            return () => {
                  clearTimeout( timerID.current)
            }
        }, [])
        useEffect(() => {
            if(customReport){
                setReportYear(null)
                setReportMonth([])
            }else{
                setReportYear(new Date().getFullYear())
            }
            
        }, [customReport])
        //console.log(reportMonth)
  return (
      <div className="content">
           <h1>Report</h1>
           <div className="section current" >
                <div className="left-side">
                {/*
                <button className='switch-period' onClick={()=>{setCustomReport(prev=>!prev)}}>
                        {!customReport?`Προσαρμοσμένη επιλογή Περιόδου`:`Επιλογή Μήνα ${props.admin ?` - Περιόδου`:''}`}
                </button>
                */}
                <input type="radio" name="customReport" id="cr0" value={0} checked={!customReport} onChange={(e)=>handleChangeRadio(e)}/>
                <label htmlFor='cr0' className='lbl-switch-period'>{`Επιλογή Μήνα ${props.admin ?' - Περιόδου':''}`}</label>
                
                <input type="radio" name="customReport" id="cr1" value={1} checked={customReport} onChange={(e)=>handleChangeRadio(e)}/>
                <label htmlFor='cr1' className='lbl-switch-period'>Προσαρμοσμένη επιλογή Περιόδου</label>
               
                    {!customReport ?
                    <>
                    
                    <label htmlFor="reportYear">Έτος</label>
                    <input name="reportYear" className='short' id="reportYear" type="text" value={reportYear} onChange={(e)=>handleChange(e)} />
                    <label htmlFor="reportMonth">Μήνας</label>
                    <Select 
                        name='reportMonth' 
                        id="reportMonth"
                        options={monthOptions} 
                        isClearable={true}
                        placeholder={'Επιλέξτε ...'}
                        isMulti={props.admin}
                        value={props.admin?monthOptions.filter(x=>reportMonth.includes(x.value)):monthOptions.find(o=>parseInt(o.value) === parseInt(reportMonth[0]))||null}
                        onChange={props.admin?handleMultiSelectChange:v=>handleSelectChange(v)}
                        noOptionsMessage={()=>noOptionsText}
                        className="sel-country"
                        styles={customStyles}
                        menuPlacement='auto'
                        filterOption = {filterOption}   
                    />
                    {props.admin ? 
                        <>
                        
                        <label htmlFor="period">Περίοδος</label>
                        <Select 
                            name='period' 
                            id="period"
                            options={periodOptions} 
                            isClearable={true}
                            placeholder={'Επιλέξτε ...'}
                            isMulti={false}
                            value={periodOptions.find(o=>parseInt(o.value) === parseInt(reportMonth[0]))||null}
                            onChange={v=>handleSelectChange(v)}
                            noOptionsMessage={()=>noOptionsText}
                            className="sel-country"
                            styles={customStyles}
                            menuPlacement='auto'
                            filterOption = {filterOption}   
                        />
                        
                        </>
                    :
                        ''
                    }
                    </>
                    :
                    <div className='date-sep'>
                        <label htmlFor="reportDateFrom">Από</label>
                        <DatePicker locale={el} name="reportDateFrom" id="reportDateFrom" 
                            selected={reportDateFrom ?new Date(reportDateFrom):''} 
                            onChange={date=>handleChangeDate('reportDateFrom', date)}
                            dateFormat="dd / MM / yyyy"
                            preventOpenOnFocus={true}
                            isClearable
                            showMonthDropdown={true}
                            showYearDropdown={true}
                            adjustDateOnChange
                            dropdownMode="select"
                            className="date-input"
                            customInput={<InputMask mask="99 / 99 / 9999"  maskPlaceholder="_"  alwaysShowMask={true} />}
                        ></DatePicker>
                        <label htmlFor="reportDateTo">Έως</label>
                        <DatePicker locale={el} name="reportDateTo" id="reportDateTo" 
                            selected={reportDateTo ?new Date(reportDateTo):''} 
                            onChange={date=>handleChangeDate('reportDateTo', date)}
                            dateFormat="dd / MM / yyyy"
                            preventOpenOnFocus={true}
                            isClearable
                            showMonthDropdown={true}
                            showYearDropdown={true}
                            adjustDateOnChange
                            dropdownMode="select"
                            className="date-input"
                            customInput={<InputMask mask="99 / 99 / 9999"  maskPlaceholder="_"  alwaysShowMask={true} />}
                        ></DatePicker>
                    </div>
                }
                    
                   
                    <button type='button' className='download' onClick={(e)=>handleClick(e)}>Download</button>
                </div>
                <div className="right-side">
                      <p className={info.err > 0?"err":info.msg!==""?"suc":''}>{info.msg}</p>
                </div>
            </div>
            {!isLoad ? <Loader />:'' }
           
      </div>
   
  )
}
