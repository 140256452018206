import React, {useState} from 'react'
import {useDialog, ModalContent, ModalFooter} from 'react-st-modal'

function CurrentPass(props) {
    const dialog = useDialog()
    const [currentPass, setCurrentPass] = useState(null)
    function handleChange(e){
        const {value}=e.target
        setCurrentPass(value)
    }
    return (
        <div>
            <ModalContent>
                <label >Τρέχων κωδικός πρόσβασης</label>
                <input name="currentPass" id="currentPass" type="password" value={currentPass||''} onChange={(e)=>handleChange(e)}/>
            </ModalContent>
            <ModalFooter>
                <button className="return-btn" onClick={()=>{dialog.close(currentPass)}}>ΟΚ</button>
                <button className="return-btn" onClick={()=>{dialog.close(false)}}>Άκυρο</button>
            </ModalFooter>
        </div>
    )
}

export default CurrentPass
