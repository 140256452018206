import React from 'react'
//import {CustomDialog} from 'react-st-modal'
//import DiagnosisDialogContent from "./DiagnosisDialogContent"
import '../style/actiondependedfield.css'
import '../utils/fontawesome'
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function DiagnosisList(props) {
        const fld=props.field
        //const { opt_diagnosis, last_actions, access_action}=props.profile
        const { opt_diagnosis, last_actions}=props.profile
        
        //const resultDiagnosis = diagnosis.slice(0,3).map((a,j)=>{
            const resultDiagnosis = last_actions.map((a,j)=>{
            const actionDate = a.action_date ? new Date(a.action_date).toLocaleDateString('el-GR'):null
            //const actionId= a.id
            const values = props.profile[fld].filter(d=> a.id === d.action_id)
            //console.log("values", values)
            const diagn = values.map((d,i)=>{
                const optionDes=opt_diagnosis.find(o=>o.id === d.option_id)
                const itemContent=optionDes?<><span>{`(${optionDes.diagnosis_code})`}</span><span>{optionDes.des}</span></>:''
                return <li className="list-item list-flex" key={`dgn_${i}`}>{itemContent}</li>
            })
            return(
                <div className="comp-grid-container" key={`grkd_${j}`}>
                    
                    <span className="grid-comp-tool">
                        {/*{access_action ?
                        <FontAwesomeIcon icon={['fas', 'edit']}  className="grid-icons" 
                            onClick={ async ()=>{
                                const result = await CustomDialog(<DiagnosisDialogContent options={opt_diagnosis} values={values} />,
                                     { showCloseIcon:true,  title:"Διάγνωση",  isCanClose:false , className:"diagnclass"})
                                if(result){
                                   
                                   const resArr=result.map(r=>({action_id:a.id, option_id:r,des:null}))
                                   props.update(resArr, fld, a.id)
                                }
                            }}
                        />
                        :
                        ''
                        }*/}
                        {/** className:"diagnclass" */}
                    </span>
                    <ul className="grid-content-list">
                        {diagn }
                    </ul>
                    <h5>Πράξη {a.id} ({actionDate})</h5>
                </div>
            )
        })
       
    return (
        <div>
            <h3>Διάγνωση</h3>
           {resultDiagnosis}
        </div>
    )
}
