import React from 'react'
import {Link} from 'react-router-dom'
import { Confirm } from 'react-st-modal'
import Pagination from './Pagination'
import '../utils/fontawesome'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function RendezvousList(props) {
    const {appointments, deleteAppointment, handleChange, appid, search, totalRows, changePage,storeLocal} = props
   
    const columns=[
        {text:'#',cn:'aa', rst:false},
        {text:'ID',cn:'rj', rst:false},
        {text:'Ημερομηνία',cn:null, rst:false},
        {text:'Ώρα',cn:null, rst:false},
        {text:'Συμμετέχοντες',cn:null, rst:false},
        {text:'Χώρος', cn:null, rst:false},
        {text:'Ωφελούμενος',cn:null, rst:false},
        {text:'Θέμα',cn:null, rst:false},
        {text:'Εργαλεία',cn:null, rst:false},
        {text:'Αποτέλεσμα',cn:null, rst:false},
        {text:'Διαγραφή',cn:null, rst:true}
    ]
    const optionNum=[5,10,20,50,100,200,500]
    const options=optionNum.map(op=>
        <option key={`ok_${op}`} value={op}>{op}</option>
        )

    const columnsHeader= props.restrict ? 
    columns.filter(c=>c.rst === false).map((c,i)=><th key={`ck_${i+1}`} className={c.cn}>{c.text}</th>)
    :columns.map((c,i)=><th key={`ck_${i+1}`} className={c.cn}>{c.text}</th>)
    const numColumns = columnsHeader.length

    const deleteMessage="Να Διαγραφεί το Ραντεβού ?"
    const noData=<tr><td colSpan={numColumns}>Δεν υπάρχουν εγγραφές</td></tr>
    const loadData=<tr><td colSpan={numColumns}>Load...</td></tr>
    //console.log(appointments)
    const tableFields = appointments && appointments.length>0 ? appointments.map((appointment, i)=>{
            return (
            <tr key={`pl_${appointment.id ||i}`} className={parseInt(appid)===appointment.id?'last-visit':''}>
                <td className="aa" >{(search.page - 1) * search.rpp + (i+1)}</td>
                <td className="rj">
                    <Link to={`/appointment/${appointment.id}`} onClick={()=>storeLocal(appointment.id)} >
                        {appointment.id}
                    </Link>
                </td>
                <td>{appointment.appointment_date}</td>
                <td>{`${appointment.start_time} - ${appointment.end_time} (${appointment.duration}΄)`}</td>
                <td>{appointment.participants}</td>
                <td>{appointment.room_name}</td>
                <td>{appointment.profile_name} {appointment.folder_number && ` (φ: ${appointment.folder_number})`}</td>
                <td>{appointment.subject}</td>
                <td>{appointment.tool}</td>
                <td className='cnt'>{appointment.result}</td>
                {
                !props.restrict 
                &&
                <td className="cnt">
                    <FontAwesomeIcon icon={['fas', 'trash-alt']} className="icon-sm-mail"  onClick={async () => {
                        const result = await Confirm(deleteMessage, 
                        'Διαγραφή Ραντεβού', 'Ναι', 'Όχι')
                        if (result) {
                            deleteAppointment(appointment.id)
                        } 
                    }}/>
                </td>
                
                }
            </tr>)}
    ):!appointments ? loadData:noData
  return (
    <table className="list-comp">
         <thead className='rv-search-list-head'>
            <tr>
                {appointments && appointments.length>0 ?columnsHeader:<td></td>}
            </tr>
         </thead>
         <tbody>
            {tableFields}
         </tbody>
         <tfoot>
         <tr>
            <td colSpan={numColumns}>
                <ul>
                    <li>
                        Εγγραφές / Σελίδα:
                        <select name="rpp" value={search.rpp} onChange={(e)=>handleChange(e, true)}>
                            {options}
                        </select>
                    </li>
                    <li>
                        {totalRows 
                            ?
                            `Βρέθηκαν: ${totalRows} εγγραφές. Σελίδα: ${search.page} / ${Math.ceil(totalRows/search.rpp)}` 
                            :''
                        }
                        
                    </li>
                    <li>
                        <Pagination totalRows={totalRows} currentPage={parseInt(search.page)} rpp={parseInt(search.rpp)} changePage={changePage}/>
                    </li>
                </ul>
            </td>
            </tr>
         </tfoot>
    </table>
  )
}

RendezvousList.defaultProps={
    restrict:false
}

export default RendezvousList