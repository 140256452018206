import React from 'react'
import ActionItem from './ActionItem'
import Pagination from './Pagination'
import '../utils/fontawesome'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '../style/actionprofile.css'

export default function ActionProfileList(props) {
    const {actions, metadata, action_diagnosis, opt_diagnosis, action_participants, action_medication, access_action} = props.profile
    const {actionsTotalRows, actionsRPP, actionCurrentPage, users} = props.profile
    const actionCount = actions.length
    const actionTypeOptions = metadata.filter(o=>o.field==="action_type_id")
    const visitTypeOptions = metadata.filter(o=>o.field==="visit_type_id")
    const actionPlaceOptions = metadata.filter(o=>o.field==="action_place_id")
    const medicationOptions = metadata.filter(o=>o.field==="medication")
    
   

    const actionItems=actions.map((a,i)=>{
        const itemData ={
            actionID : a.id,
            is_new : a.is_new,
            //m_unit : a.m_unit,
            action_place_des : a.action_place_des,
            action_type_des : a.action_type_des,
            actionDate : a.action_date ? new Date(a.action_date).toLocaleDateString('fr-CA'):null,
            actionType : actionTypeOptions.find(o=>o.id === a.action_type_id) || null,
            visitType : visitTypeOptions.find(o=>o.id===a.visit_type_id) || null,
            actionPlace : actionPlaceOptions.find(o=>o.id===a.action_place_id) || null,
            diagnosis : action_diagnosis.filter(d=>parseInt(d.action_id)===parseInt(a.id)) ||[],
            medication : action_medication.filter(m=>parseInt(m.action_id)===parseInt(a.id)) ||[],
            opt_medication:medicationOptions||[],
            members : action_participants.filter(p=>p.action_id===a.id)||[],
            opt_diagnosis:opt_diagnosis,
            users:users
        }   

        return(
            <ActionItem key={`action_${i}`} itemData={itemData} edit={props.edit} curItem={a.id===props.curAction} contact={false} delete={props.deleteAction}/>
        )
    })
    if(access_action ) 
        return (
            <>
                <div className='action-list-header'>
                    <div>Πράξεις: {actionCount} {`${actionCount >0 ? `(${(actionCurrentPage-1)*actionsRPP + 1} ~ ${(actionCurrentPage-1)*actionsRPP +actionCount}) από ${actionsTotalRows}`:''}`}</div>
                    <div>
                        {props.profile.contact.id && props.profile.contact.id >0 ?
                        <FontAwesomeIcon  icon={['fas', 'plus-circle']} className="icon-add-action" onClick={()=>{props.edit(-1)}} />
                        :''
                        }
                    </div>
                    
                </div>
                <div style={{textAlign:'right', marginRight:'2em'}}>
                    {actionCount > 0 ?
                    <Pagination totalRows={actionsTotalRows} currentPage={actionCurrentPage} rpp={actionsRPP} changePage={props.changePage}/>
                    :''}
                </div>
                {actionItems}
                <div style={{textAlign:'right', marginRight:'2em'}}>
                {actionCount > 0 ?
                    <Pagination totalRows={actionsTotalRows} currentPage={actionCurrentPage} rpp={actionsRPP} changePage={props.changePage}/>
                    :''}
                </div>
            </>
        )
    else
       return  ''
            
    
}
