import React from 'react'
import '../style/rendezvousdialog.css'
import '../utils/fontawesome'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function RendezvousDialog(props) {
    const {rvdata} = props
    const {app} = rvdata
    console.log('app:',app)
    const dur={h:Math.floor(app?.duration/60),m:app?.duration % 60}
    const part = app.participants.split(',')
    const participantsList = part ? part.map((p,i)=><li key={`pts_${i+1}`}>{p}</li>):''
  return (
    <div className='tooltip' style={{top:`${rvdata.y}px`, left:`${rvdata.x}px` }}>
        <h3>{app?.appointment_date ? app.appointment_date.split('-').reverse().join('/'):''} {app?.subject || ''}</h3>
        <div className='tp-container'>
            <FontAwesomeIcon icon={['fas', 'clock']} className="tp-icon"/>
            <span>
            {app?.start_time || ''} - {app?.end_time || ''} {`(${dur.h}h, ${dur.m } min) `}
            {app?.room_name || ''}
            </span>
        </div>
        
        <div className='tp-container'>
            <FontAwesomeIcon icon={['fas', 'user-nurse']}  className="tp-icon"/>
            <ul className='tp-list'>
                {participantsList}
            </ul>
        </div>
        <div className='tp-container'>
            <FontAwesomeIcon icon={['fas', 'user-shield']}  className="tp-icon"/>
            {app.folder_number ?<span className='f-n'>{`(${app.folder_number})`}</span>:''}
            <span>{app.profile_name || '-'}</span>
            
        </div>
        <div className='tp-container'>
            <FontAwesomeIcon icon={['fas', 'mobile-alt']}  className="tp-icon"/>
            <span>{app.tool || '-'}</span>
        </div>
        <div className='tp-container'>
            <span className={`tp-result ${app.color?`res-${app.color}`:''}`}>{app.appointment_result || ''}</span>
        </div>
    </div>
  )
}

export default RendezvousDialog