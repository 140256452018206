
import React,{useState, useEffect, useContext, useRef} from 'react'
import {useNavigate} from 'react-router-dom'
import { AuthContext } from '../AuthContext'
//import '../utils/fontawesome'
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axiosAPI from '../services/axiosapi'
import { decodeToken } from '../utils/util'

import "../style/login.css"
//import Loader from './Loader'

export default function Login(props) {
    const timerID=useRef(null)
    const btnLogIn=useRef(null)
    const passf=useRef(null)
    const {token, setToken}  = useContext(AuthContext)
    const [isPressed, setIsPressed]=useState(false)
    const [isVPressed, setIsVPressed]=useState(false)
    const [isResetVPressed, setIsResetVPressed]=useState(false)
    const [isResetPassPressed, setIsResetPassPressed]=useState(false)
    const [loginMessage, setLoginMessage]=useState({message:'', cl:''})
    const [credentials, setCredentials]=useState({username:'', passWord:'',vcode:''})
    const [vkeyAction, setVkAction]=useState(null)
    const [passAction, setPassAction]=useState(null)
    const [recoveryMail, setRecoveryMail]=useState('')
    const payload = decodeToken(token)
    const [connectionStatus, setConnectionStatus]=useState(payload ? payload.connection_status :"NC")
    const navigate = useNavigate()
    
    

    function handleLogin(ev){
        //console.log(credentials)
        let msg=null
        let msgClass=null
        
        if(checkLogin()){
            msg="send Data"
            msgClass="info"
            const m={message:msg,cl:msgClass}
            setLoginMessage(m)
            axiosAPI.post("login.php",{username:credentials.username, password:credentials.passWord})
            .then(response=>{
                //alert('ok')
                clearLoginMessage()
                setIsPressed(false)
                //console.log("response:", response.data)
                const {error, errorMessage, token}=response.data
                if(parseInt(error) !==0){
                    setLoginMessage({message:errorMessage, cl:'err'})
                }else{
                    //const payload = decodeToken(token)
                    //console.log("payload", payload)
                    localStorage.setItem('token', token)
                    setToken(token)
                    
                    navigate('/', {replace:true})
                }
                
            })

        }else{
            
            msg="Τo όνομα χρήστη και ο κωδικός πρόσβασης δεν πρέπει να είναι κενά!"
            msgClass="err"
            const m={message:msg,cl:msgClass}
            setLoginMessage(m)
        }
        
    }

    function handleKeyDown(ev){
        if(ev.key==='Enter' || ev.key==='NumpadEnter')
            handleVerification()
    }

    function handleKeyDownUNP(ev){
        if(ev.key==='Enter' || ev.key==='NumpadEnter'){
            if(ev.target.name==='username')
                passf.current.focus()
            else if(ev.target.name==='passWord')
                btnLogIn.current.focus()
        }
    }

    

    function handleVerification(){
        let msg=null
        let msgClass=null
        
        if(checkVerification()){
            
            msg="send Data"
            msgClass="suc"
            const m={message:msg,cl:msgClass}
            setLoginMessage(m)
            setIsVPressed(true);
            
            const tkn = localStorage.getItem('token')|| false
            const payload = decodeToken(tkn)
            const headers= {
                'Content-Type':'application/json',
                'Authorization': `Bearer ${tkn}`,
              }
            axiosAPI.post("login.php",{vcode:credentials.vcode, userID:payload.userID||null},{headers})
            .then(response=>{
                clearLoginMessage()
                const {error, errorMessage, token} = response.data
                //console.log("verify:",response.data)
                if(parseInt(error) < 0){
                    const m={message:errorMessage,cl:"err"}
                    setLoginMessage(m)
                    
                    timerID.current=setTimeout(()=>{
                        clearLoginMessage()
                        localStorage.removeItem('token')
                        setToken(false)
                        navigate('/', {replace:true})
                        //clearTimeout(timerID)
                    },2000)
                    
                }else if(parseInt(error)>0){
                    setLoginMessage({message:errorMessage, cl:'err'})
                    localStorage.setItem('token',token)
                    setToken(token)
                    setIsVPressed(false)
                }else{
                    setLoginMessage({message:errorMessage, cl:'suc'})
                    //
                    
                    timerID.current=setTimeout(()=>{
                        localStorage.setItem('token',token)
                        //clearTimeout(timerID)
                        setIsVPressed(false)
                        setToken(token)
                        //navigate('/',{replace:true})
                    },1000)
                    
                    
                }
                
            })
            

        }else{
            msg="O Κωδικός επιβεβαίωασης δεν είναι αποδεκτός"
            msgClass="err"
            const m={message:msg,cl:msgClass}
            setLoginMessage(m)
        }
        
    }

    function resetPassRequest(){
        if(!passAction){
            const msg="Δεν έχετε επιλέξει ενέργεια!"
            const  msgClass="err"
            const m={message:msg,cl:msgClass}
            setLoginMessage(m)
            return
        }
        setIsResetPassPressed(true)
        axiosAPI.post("login.php",{recoveryAction:passAction, recoveryMail:recoveryMail, resetPass:"RSTP"})
        .then(response=>{
            clearLoginMessage()
            const {error, errorMessage} = response.data
            //console.log("response:", response.data)
            if(parseInt(error) < 0){
                const m={message:errorMessage,cl:"err"}
                setLoginMessage(m)
                timerID.current=setTimeout(()=>{
                    clearLoginMessage()
                    localStorage.removeItem('token')
                    setToken(false)
                    //clearTimeout(timerID)
                    //navigate('/',{replace:true})
                },2000)
            }else if(parseInt(error)>0){
                setLoginMessage({message:errorMessage, cl:'err'})
                setIsResetPassPressed(false)
            }else{
                setLoginMessage({message:errorMessage, cl:'suc'})
            }
        })
    }
    


    function resetVCodeRequest(){
        if(!vkeyAction){
            const msg="Δεν έχετε επιλέξει ενέργεια!"
            const  msgClass="err"
            const m={message:msg,cl:msgClass}
            setLoginMessage(m)
            return
        }
        setIsResetVPressed(true)
        const tkn = localStorage.getItem('token')||false
        const payload = decodeToken(tkn)
        const headers= {
            'Content-Type':'application/json',
            'Authorization': `Bearer ${tkn}`,
        }
        axiosAPI.post("login.php",{recoveryAction:vkeyAction, userID:payload.userID||null, recoveryMail:recoveryMail},{headers})
        .then(response=>{
            clearLoginMessage()
            const {error, errorMessage, token} = response.data
            //console.log(response.data)
            if(parseInt(error) < 0){
                const m={message:errorMessage,cl:"err"}
                setLoginMessage(m)
    
                timerID.current=setTimeout(()=>{
                    clearLoginMessage()
                    localStorage.removeItem('token')
                    setToken(false)
                    //clearTimeout(timerID)
                    navigate('/', {replace:true})
                },2000)
                
            }else if(parseInt(error)>0){
                setLoginMessage({message:errorMessage, cl:'err'})
                localStorage.setItem('token',token)
                setToken(token)
                setIsResetVPressed(false)
            }else{
                setLoginMessage({message:errorMessage, cl:'suc'})
                timerID.current=setTimeout(()=>{
                    localStorage.setItem('token',token)
                    //clearTimeout(timerID)
                    setToken(token)

                },1000)
            }
            
        })
            
    }


    function resetVerifyCode(ev){
        ev.preventDefault();
        clearLoginMessage()
        setConnectionStatus('RQVC')
    }

    function resetPass(ev){
        ev.preventDefault()
        clearLoginMessage()
        setConnectionStatus('RQCP')
    }

    function handlePassReset(ev){
        if(ev.target.type==="radio"){
            if(ev.target.name==='passAction')
                setPassAction(ev.target.value)
            
        }else if(ev.target.type==='text'){
            setRecoveryMail(ev.target.value)
        }
        clearLoginMessage()
    }

    function handleVerificationReset(ev){
        if(ev.target.type==="radio"){
            if(ev.target.name==='verificationKeyAction')
                setVkAction(ev.target.value)
            
        }else if(ev.target.type==='text'){
            setRecoveryMail(ev.target.value)
        }
        clearLoginMessage()
    }




    function clearLoginMessage(){
        setLoginMessage({message:'', cl:''})
    }

    function handleChange(ev){
        setCredentials((prev)=>({...prev, [ev.target.name]:ev.target.value}))
    }

    function checkLogin(){
        const {username, passWord} = credentials
        return username!=='' && passWord!=='' ? true : false

    }

    function checkVerification(){
        const {vcode}=credentials
        return vcode!==''?true:false
    }

    function returnFromVC(){
        clearLoginMessage()
        setIsResetVPressed(false)
        setCredentials({username:'', passWord:'',vcode:''})
        setConnectionStatus('VF')
        
    }

    function returnToLogin(){
        clearLoginMessage()
        setRecoveryMail('')
        setPassAction(null)
        setCredentials({username:'', passWord:'',vcode:''})
        setConnectionStatus('NC')
    }
    function clearAll(){
        setLoginMessage({message:'', cl:''})
        setCredentials({username:'', passWord:'',vcode:''}) 
        localStorage.removeItem('token')
        setToken(false)
        navigate('/', {replace:true})
    }

    

  useEffect(() => {
      
      return () => {
            clearTimeout( timerID.current)
      }
  }, [])

    if(connectionStatus==='NC')
    return (
        <div className="login-container">
            <h1>Σύνδεση ...</h1>
            
            <label htmlFor="username" >Όνομα χρήστη</label>
            <input name="username" id="username"  type="text" value={credentials.username} autoFocus onChange={(e)=>handleChange(e)} onFocus={()=>clearLoginMessage()} onKeyDown={(e)=>handleKeyDownUNP(e)}/>
            <label htmlFor="passWord" >Κωδικός Πρόσβασης</label>
            <input name="passWord" ref={passf} id="passWord" type="password" value={credentials.passWord} onChange={(e)=>handleChange(e)} onFocus={()=>clearLoginMessage()} onKeyDown={(e)=>handleKeyDownUNP(e)}/>
            <div className={`login-message ${loginMessage.cl}`}> 
                {loginMessage.message}
            </div>
            <button name="login" ref={btnLogIn}  type="button" disabled={isPressed} className="login-btn" onClick={(e)=>handleLogin(e)}>Είσοδος</button>
            <button className="ret-link" onClick={(e)=>resetPass(e)}>Επαναφορά κωδικού πρόσβασης</button>
        </div>
    )
    else if(connectionStatus==='VF')
        return(
            <div className="login-container">
                <h1>Verification...</h1>
                 <label htmlFor="vcode" >Κωδικός επιβεβαίωσης</label>
                 <input name="vcode" autoFocus={true} className="vf-code" id="vcode" type="text" value={credentials.vcode} onChange={(e)=>handleChange(e)} onFocus={()=>clearLoginMessage()} onKeyDown={(e)=>handleKeyDown(e)}/>
                 <div className={`login-message ${loginMessage.cl}`}> 
                    {loginMessage.message}
                </div>
                <button name="verify"  disabled={isVPressed} type="button" className="login-btn" onClick={()=>handleVerification()} onFocus={()=>clearLoginMessage()} >Επιβεβαίωση</button>
                <button className="ret-link" onClick={(e)=>resetVerifyCode(e)}>Επαναφορά κλειδιού επιβεβαίωσης</button>
                <button className="ret-link" onClick={(e)=>clearAll(e)}>Επιστροφή</button>
            </div>
        )  
    else if(connectionStatus==='RQVC')
        return(
            <div className="login-container">
                <h1>Επαναφορά κλειδιού επιβεβαίωσης</h1>
                <label htmlFor="remind_key" className="lbl-inline">
                    <input type="radio" id="remind_key" name="verificationKeyAction" value="rmd" checked={vkeyAction==="rmd"||false} onChange={(e)=>handleVerificationReset(e)} />
                    Υπενθύμηση κλειδιού επιβεβαίωσης
                </label>
                
                <label htmlFor="new_key" className="lbl-inline">
                    <input type="radio" id="new_key" name="verificationKeyAction" value="nvk" checked={vkeyAction==="nvk"||false} onChange={(e)=>handleVerificationReset(e)} />
                    Δημιουργία ΝΕΟΥ κλειδιού επιβεβαίωασης
                </label> 
                <label htmlFor="first_name">e-mail επαναφοράς</label>
                <input type="text" name="recovery_mail" id="recovery_mail" value={recoveryMail} onChange={(e)=>handleVerificationReset(e)} />
                <div className={`login-message ${loginMessage.cl}`}> 
                    {loginMessage.message}
                </div>
                <button type="button" className="login-btn" onClick={()=>resetVCodeRequest()} disabled={isResetVPressed}>Συνέχεια</button>
                <button type="button" className="ret-link" onClick={()=>returnFromVC()}>Επιστροφή</button>
            </div>
        )
    else if(connectionStatus==="RQCP")
        return(
            <div className="login-container">
                <h1>Επαναφορά κωδικού πρόσβασης</h1>
                <label htmlFor="remind_pass" className="lbl-inline">
                    <input type="radio" id="remind_pass" name="passAction" value="rmdp" checked={passAction==="rmdp"||false} onChange={(e)=>handlePassReset(e)} />
                    Υπενθύμηση κωδικού πρόσβασης
                </label>
                
                <label htmlFor="new_pass" className="lbl-inline">
                    <input type="radio" id="new_pass" name="passAction" value="np" checked={passAction==="np"||false} onChange={(e)=>handlePassReset(e)} />
                    Αλλαγή κωδικού πρόσβασης
                </label> 
                <label htmlFor="first_name">e-mail επαναφοράς</label>
                <input type="text" name="recovery_mail" id="recovery_mail" value={recoveryMail} onChange={(e)=>handlePassReset(e)} />
                <div className={`login-message ${loginMessage.cl}`}> 
                    {loginMessage.message}
                </div>
                <button type="button" className="login-btn" onClick={()=>resetPassRequest()} disabled={isResetPassPressed}>Συνέχεια</button>
                <button type="button" className="ret-link" onClick={()=>returnToLogin()}>Επιστροφή</button>
            </div>
        )
    else if(connectionStatus==='NVC')
        return(<h1>New Code</h1>)
        
}
