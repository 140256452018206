import React from 'react'

function EditTextarea(props) {
    const {close, title} = props
    const containerStyle={
        position:'fixed',
        boxSizing:'border-box',
        top:'3.8rem',
        left:'0',
        width:'100%',
        height:'100%',
        backgroundColor:'#f0f0f0',
        padding:'0 2vw',
        zIndex:'2',
        overflow:'hidden'
    }
    const customStyle ={
        boxSizing:'border-box',
        width:'100%',
        maxWidth:'1600px',
        height:'calc(100vh - 3.8rem)',
       
        display:'flex',
        flexDirection:'column',
        margin:'0 auto',
        alignItems:'first-baseline'  
    }
    const headerStyle={
        fontSize:'0.8rem',
        fontWeight:'bold',
        color:'var(--dec)',
        padding:'1rem 0',
        display:'flex',
        gap:'2rem',
        justifyContent:'flex-start',
        alignSelf:'first-baseline'
    }
    const returnButton ={
        margin:'1rem 0 2rem 0', 
        alignSelf:'flex-start',
        color:'#fff',
        backgroundColor:'var(--dec)',
        padding:'0.5rem 3rem',
        fontSize:'1.2rem',
        
    }
    const titleContent = title.map((t,i)=><span key={`kt_${i}`}>{t}</span>)
  return (
    <div  style={containerStyle} className='cont-ed'>
        <div style={customStyle}>
            <header style={headerStyle}>{titleContent}</header>
            {props.children}
            <button style={returnButton} onClick={()=>{close()}}>Επιστροφή</button>
        </div>
    </div>
  )
}

EditTextarea.defaultProps={
    title:[]
}

export default EditTextarea