import React, { useState, useEffect, useContext, useRef} from 'react';
import { useParams, useNavigate } from 'react-router-dom'
import { AuthContext } from '../AuthContext'
import axiosAPI from '../services/axiosapi'
import Loader from "./Loader"
import {getMessageClass} from '../utils/util'
import Action from "./Action"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '../style/action.css'
import '../utils/fontawesome'

export default function ActionForm(props) {

    const { setToken}  = useContext(AuthContext)
    const {id, profileId}=useParams()

    const navigate=useNavigate()

    const [isLoad,setIsLoad]=useState(false)
    const [updateMessage,setUpdateMessage]=useState({error:0,message:'', affected:0})
    const [profile, setProfile]=useState(null)

    const timer = useRef(null)

    function returnFromActionForm(e, back){
        navigate(back-1) // -1
    }
    
    //console.log("pf",profileId)


    useEffect(()=>{
        const tkn=localStorage.getItem('token')||false
        const headers= {
        'Content-Type':'application/json',
        'Authorization': `Bearer ${tkn}`,
        }
        
        //console.log("id", postParam.id, "pf", postParam.profile_id)
        axiosAPI.post("action.php",{id:id||0, profile_id:profileId||0}, {headers})
        .then(response=>{
        //console.log("response:", response.data)
        const {error, errorMessage, token} = response.data
        if(parseInt(error)<0){
            setUpdateMessage({error:error,message:errorMessage,affected:0})
            localStorage.removeItem('token')
            setToken(false)
        }else if (parseInt(error)===0){
            setProfile(response.data)
            setIsLoad(true)
            localStorage.setItem('token',token)
            setToken(token)
        }else{
            setIsLoad(true)
            setUpdateMessage({error:error,message:errorMessage,affected:0})
            localStorage.setItem('token',token)
            setToken(token)
        }
        })
        //return ()=>{ }
    },[id, profileId, setToken])

    useEffect(() => {
        if(updateMessage.message){
            const err = parseInt(updateMessage.error) === 0 ? 500:3000
            timer.current = setTimeout(()=>{
                setUpdateMessage({error:0,message:"",affected:0})
            },err)
         }
        return () => {
              clearTimeout(timer.current)
          }
    }, [updateMessage])
    
    if(isLoad){
        const {contact} = profile || null
        const gender = contact && contact.gender_id ? contact.gender_id % 2===0?'female':'male' : false
        const age = contact && contact.birth_year? new Date().getFullYear() - contact.birth_year:''
        return ( 
            <div>
                <div className='contact-fields'>
                    <div>{contact.surname} {contact.first_name}</div>
                    <div>{gender ? <FontAwesomeIcon icon={['fas' ,`${gender}`]} />:'-'} {age ? `(${age})`:''}</div>
                    <div>{contact.amka}</div>
                    <div><span>Α.Φ:</span>{contact.folder_number||'-'}</div>
                </div>
                {/*<h2>Πράξη {id}</h2>*/}
                <Action profile={profile} saveAction={returnFromActionForm} actionId={id} msg={setUpdateMessage} updateProfile={false} actiononly={true}/>
                {updateMessage.message ? <pre className={`message-box ${getMessageClass(updateMessage.error, updateMessage.affected)}`}>{updateMessage.message}</pre>:''}
            </div>
        )
    }else{
        return <Loader text="Load..." />
    }

   
}

