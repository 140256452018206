import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
//import { AuthContext } from '../AuthContext'
import ContextMenu from './ContextMenu'
import RendezvousDialog from './RendezvousDialog'
import "../style/schedulerday.css"

function RendezvousDay(props) {
    // console.log(props)
    const {appointments, search, rooms, occupiedParticipants, workingHours, occupiedTools, occupiedProfile} = props
    const {searchParticipants,profileIdSearch,toolSearch, subjectSearch } = search
    //const {setToken}  = useContext(AuthContext)
    const [showContextMenu, setShowContextMenu] = useState(false)
    const [contextMenuPosition, setContextMenuPosition] = useState({x:0,y:0,room_id:null, participants:[]})
    const [showOver, setShowOver] = useState({status:false, x:0, y:0, app:{}})
    
    


    useEffect(() => {
      const closeContentMenu = ()=>setShowContextMenu(false)
      window.addEventListener('click',closeContentMenu )
      return () => window.removeEventListener('click',closeContentMenu )
    }, [])
    
    
    const hoursTbl = ['09:00','10:00', '11:00','12:00', '13:00','14:00', '15:00','16:00', '17:00', '18:00','19:00']
    const headHours = hoursTbl.map((h,i)=>
            <div key={`h_${i+1}`} className='flexroomshours'>
                <div>{h}</div>
            </div>
        )
    function handleContextMenu(rid,h,prts, pfl, tool, sbj, ev){
        ev.preventDefault()
        
        //console.log('x:', ev.pageX, 'y:',ev.pageY, 'w:', window.innerWidth)
        const xPos = ev.pageX + 150 > window.innerWidth ?  window.innerWidth - 150 - 10 :ev.pageX 
        const pflid= pfl?.value ? pfl.value:null
        //-----
        setContextMenuPosition({x:xPos, y:ev.pageY, start_time:h, room_id:rid, profile_id:pflid, tool:tool, subject:sbj, participants:prts.map(p=>({user_id:p}))})
        setShowContextMenu(true)

    }
    function handleOver(app, ev){
        const xPos = ev.pageX + 350 > window.innerWidth ?  window.innerWidth - 350 - 100 :ev.pageX - 50
        const yPos = ev.pageX + 350 > window.innerWidth ?  ev.pageY -75 :ev.pageY -75
        setShowOver({status:true, x:xPos, y:yPos, app:app})
    }
    const contentHours = hoursTbl.map((h,i)=>{
        const roomsHours = rooms.map((r,j)=>(
            <div key={`r_${i}_${j}`} onContextMenu={(e)=>handleContextMenu(r.id, h, searchParticipants, profileIdSearch, toolSearch, subjectSearch ,e)}></div>
            )) // doubleClick here
        return <div key={`h_${i}`} className='flexroomshours-content'>
            {roomsHours}
        </div>
    })

    const appointmentContent = appointments ? appointments.map((a,k)=>{
        //parseInt(a.room_id)
        const idx= rooms.findIndex(r=>parseInt(r.id)===parseInt(a.room_id))
        const left = (parseInt(a.left) * 9.0909) / 60
        const width = (parseInt(a.duration) * 9.0909) / 60
        const adjustWidth = left + width  > 100 ?  left + width - 100 : width
        //console.log(a)
        return (<Link to={`/appointment/${a.id}`} style={{color:'#000'}} key={`Lk_${k}`} >
                    <div className='appointment-day' 
                        style={{top:`calc(3rem * ${idx})`,left:`${left}%`, width:`${adjustWidth}%`, borderBottom:`${a.color? `4px solid ${a.color}`:'1px solid rgb(80, 192, 229)'}`}} 
                        onMouseOver={(e)=>handleOver(a, e)}
                        onMouseOut={()=>setShowOver({status:false, x:0,y:0,app:{}})}
                    >
                        
                        <div>{a.start_time}</div>
                        <div>{a.end_time}</div>
                        
                    </div>
                </Link>)
    }):''

    const occPartContent = occupiedParticipants ? occupiedParticipants.map((op, i)=>{
        const left = (parseInt(op.left) * 9.0909) / 60
        const width = (parseInt(op.width) * 9.0909) / 60
        const adjustWidth = left + width  > 100 ?   100 - left-0.01 : width
        const bgColor = op.specialty_id!==18?'#00205b':'#002873'
        return (
            <div key={`ocp_${i}`} className='occ-part' 
                style={{left:`${left}%`, width:`${adjustWidth}%`, background:`${bgColor}`}} >
                    {/*<span>{op.fullname}</span>*/}
            </div>
    )}):'' 

    const startWHContent = workingHours ? workingHours.filter(w=>parseInt(w.start_width) > 0 ).map((f,i)=>{
        const left = 0
        const width = (parseInt(f.start_width) * 9.0909) / 60
        return(
            <div key={`whs_${i}`} className='occ-wkhr' style={{left:`${left}%`, width:`${width}%`}}>

            </div>
    )}):''

    const endWHContent = workingHours ? workingHours.filter(w=>w.width && parseInt(w.width) > 0 ).map((f,i)=>{
        const left = (parseInt(f.left) * 9.0909) / 60
        const width = (parseInt(f.width) * 9.0909) / 60
        const adjustWidth = left + width  > 100 ?   100 - left-0.01 : width
        return(
            <div key={`whe_${i}`} className='occ-wkhr' style={{left:`${left}%`, width:`${adjustWidth}%`}}>

            </div>
    )}):''

    const occToolContent = occupiedTools ? occupiedTools.map((ot, i)=>{
        const left = (parseInt(ot.left) * 9.0909) / 60
        const width = (parseInt(ot.width) * 9.0909) / 60
        const adjustWidth = left + width  > 100 ?   100 - left-0.01 : width
        return (
            <div key={`oct_${i}`} className='occ-tool' 
                style={{left:`${left}%`, width:`${adjustWidth}%`}} >
                    {/*<span>{op.fullname}</span>*/}
            </div>
    )}):'' 

    const occProfileContent = occupiedProfile ? occupiedProfile.map((opf, i)=>{
        const left = (parseInt(opf.left) * 9.0909) / 60
        const width = (parseInt(opf.width) * 9.0909) / 60
        const adjustWidth = left + width  > 100 ?   100 - left-0.01 : width
        return (
            <div key={`opf_${i}`} className='occ-profile' 
                style={{left:`${left}%`, width:`${adjustWidth}%`}} >
                    {/*<span>{op.fullname}</span>*/}
            </div>
    )}):'' 
    


    const roomsContent = rooms.map((r,j )=> <div key={`r_${j}_m`}>{r.room_name}</div>)

  return (
    <>
    <div className='schdl-day-container'>
        <div className='schdl-day-flexcol-container'>

            <div className='schdl-day-flexrow head-hours'>
                <div className='flexrooms'>
                    <div className='nb'></div>
                </div>
                <div className='rooms-hours'>
                    <div className='flexroomshours-container'>
                        {headHours}
                    </div>
                </div>
            </div>
            <div className='schdl-day-flexrow'>
                <div className='flexrooms'>
                    {roomsContent}
                </div>
                <div className='rooms-hours'>
                    <div className='flexroomshours-container'>
                        { contentHours }
                    </div>
                    
                    {appointmentContent}

                    {occToolContent}

                    {occProfileContent}

                    {occPartContent}
                    {startWHContent}
                    {endWHContent }                  
                </div>
            </div>

        </div>
    </div>
    {showContextMenu && <ContextMenu app={contextMenuPosition} />}
    {showOver.status && <RendezvousDialog rvdata={showOver}/>}
    </>
  )
}

export default RendezvousDay