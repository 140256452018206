import React from 'react'
import '../style/actiondependedfield.css'
//import '../style/monitored.css'
import '../utils/fontawesome'
import {selectComp} from '../utils/util'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CustomDialog, Confirm } from 'react-st-modal'
import HistoryDialogContent from './HistoryDialogContent'
import { v4 as uuidv4 } from 'uuid'

export default function HistoryListComponents(props) {

    const {title, field, profile} =props
    const options = profile.metadata.filter(i=>i.field === field)
    const dataArr = profile[field]
    const groupKeys = dataArr.map(i=>i.groupkey).filter((item, i, t)=>t.indexOf(item)===i)
    //const uniqueGroupKeys  = groupKeys.filter((item, i, t)=>t.indexOf(item)===i)
    const result = groupKeys.map(item => {
        const {start_date} = dataArr.find(d=>d.groupkey===item)||null
        return ({
                    groupkey:item,
                    start_date:start_date, 
                    data:dataArr.filter(d2 =>d2.groupkey === item).map(o=>({option_id:o.option_id,des:o.des}))
        })
    })

    const listComponent=result.map((item, idx)=>{
        const {data, groupkey, start_date} = item
        const selectedItems = data.map((si,j)=>{
            const option = options.find(o=>si.option_id===o.id)
            return(
                <div key={`sel_${j}`}>
                    {option ? option.des:''} {si.des?` - ${si.des}`:''}
                </div>
            )
        })
        return (
            <div key={`r_${idx}`} className="comp-container">
                <div className="comp-field">
                    {selectedItems}
                </div>
                <div className="comp-date">
                     από: {start_date ? new Date(start_date).toLocaleDateString('el-GR') :'__/__/____'}
                </div>
                <div className="comp-tool">
                    <FontAwesomeIcon icon={['fas', 'edit']}  className="icons" 
                        onClick={async ()=>{
                            const result = await CustomDialog(<HistoryDialogContent field={field} options={options} values={item} title={title}/>,
                                {title, showCloseIcon:true, isCanClose:false, })
                                if(result){
                                   // console.log("occupation", result)
                                    props.update(field, result,'u')
                                }
                                
                            }}
                    />
                    <FontAwesomeIcon icon={['fas', 'trash-alt']} className="icons" 
                        onClick={async () => {
                            const result = await Confirm('Να Διαγραφεί το στοιχείο ?', 
                            'Διαγραφή', 'Ναι', 'Όχι')
                            if (result) {
                                props.update(field, {groupkey:groupkey},'d')
                            } 
                      }}
                    />
                </div>
            </div>
        )
    })
    return (
        <>
            <h3>
               
                <FontAwesomeIcon icon={['fas', 'plus-circle']} className="icons add-new-item" 
                onClick={async ()=>{
                    const start_date= new Date().toLocaleDateString('fr-CA')
                    const gk=uuidv4()
                    const newItem= {start_date:start_date,groupkey:gk, data:[{option_id:null,des:null}]}
                    const result = await CustomDialog(<HistoryDialogContent field={field} options={options} values={newItem} title={title}/>,
                        {title, showCloseIcon:true, isCanClose:false, className:selectComp.includes(field)?" smallwin":"myclass"})
                        if(result){
                            //console.log("occupation", result)
                            props.update(field, result,'i')
                        }
                }}
                /> 
                
             {title} 
            </h3>
            <div className="history-group">
                {listComponent}
                
            </div>
            
            
        </>
    )
}
