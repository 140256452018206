import React, {useState} from 'react'
import {useDialog, ModalContent, ModalFooter} from 'react-st-modal'
import DatePicker ,{registerLocale, setDefaultLocale} from 'react-datepicker'
import InputMask from 'react-input-mask'
import {el} from 'date-fns/locale/'
import CheckBoxField from './CheckBoxField'
import '../utils/fontawesome'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import "react-datepicker/dist/react-datepicker.css"
import '../style/modal.css'



registerLocale('el',el)
setDefaultLocale('el')

export default function RequestDialogComponent(props) {
    const dialog = useDialog()
    const [request, setRequest]=useState(props.request)
    const [currentSection, setCurrentSection]=useState(props.section)
   
    const {requestOptions,answerOptions, outcomeOptions }=props.options

    function handleChange(e, id=0, field=''){
        if(field==='request_options'){
            if(e.target.type==='checkbox'){
            const newArr =!e.target.checked ?
            request.request_options.filter(item => item.option_id!==parseInt(e.target.value))
            :
            [...request.request_options, {request_id:request.id, option_id:parseInt(e.target.value),request_option_des:null}]
            setRequest((prev)=>({...prev,[field]:newArr}))
            } else{
            const newArr = request.request_options.map(item => item.option_id===id ?{...item, [e.target.name]:e.target.value}:item)
            setRequest((prev)=>({...prev,[field]:newArr}))
            }
        }else if(field==='request_answer_id'){
            if(e.target.type==='radio'){
                setRequest((prev)=>({...prev,[e.target.name]:parseInt(e.target.value), request_answer_des:null}))
            }else{
                setRequest((prev)=>({...prev,[e.target.name]:e.target.value?e.target.value:null}))
            }
        }else if(field==='outcome_id'){
            //setRequest((prev)=>({...prev,[e.target.name]:parseInt(e.target.value)}))
            if(e.target.type==='radio'){
                setRequest((prev)=>({...prev,[e.target.name]:parseInt(e.target.value), outcome_des:null}))
            }else{
                setRequest((prev)=>({...prev,[e.target.name]:e.target.value?e.target.value:null}))
            }
        }else{
            setRequest((prev)=>({...prev,[e.target.name]:e.target.value}))
        }
    }

    function clearRadio(ev, field){
        if(field==='request_answer_id'){
            setRequest((prev)=>({...prev,[field]:null, request_answer_des:null}))
        }else if(field==='outcome_id'){
            setRequest((prev)=>({...prev,[field]:null, outcome_des:null}))
        }
    }

    function createComponent(fieldName, options, value){
        const comp = options.map((opt, i)=>{
            const inpjson =  opt.extra && parseInt(value) === parseInt(opt.id) ? JSON.parse(opt.extra):null
            const textFields = inpjson && inpjson.map((t,i)=>{
                return  <input key={`${props.field}_tdes_${i}`} type="text" name={t.field} value={request[t.field]} className="details" onChange={(e)=>handleChange(e, 0 ,fieldName)}/>
                //props.change(e, field, k)    
            })
            return(
                <li key={`${fieldName}_${i}`}>
                    <label key={`${props.field}_${opt.id}`} className="modal-label">
                        <input name={fieldName} type="radio" value={opt.id} checked={parseInt(value) === parseInt(opt.id)} onChange={(e)=>handleChange(e, 0, fieldName)}/> 
                        {opt.des}
                        {textFields}
                    </label>
                </li>
            )
        // props.change(e,field,k)

        })
        const clearField = <li>
            <label>
                <FontAwesomeIcon icon={['fas', 'eraser']}  className="icons " onClick={(e)=>clearRadio(e, fieldName)}/>
                </label>
            </li>
        return <ul>{comp}{clearField}</ul>
    }
    const answerComp = createComponent("request_answer_id", answerOptions, request.request_answer_id)
    const outcomeComp = createComponent("outcome_id", outcomeOptions, request.outcome_id)

    return (
        <div style={{minHeight:"70vh"}}>
           
                <ul className="menu">
                    <li className={currentSection===1?'active':null} onClick={(e)=>{setCurrentSection(1)}}>Αίτημα</li>
                    <li className={currentSection===2?'active':null} onClick={(e)=>{setCurrentSection(2)}}>Τρόπος αντιμετώπισης</li>
                    <li className={currentSection===3?'active':null} onClick={(e)=>{setCurrentSection(3)}}>Έκβαση</li>
                </ul>
            
            <ModalContent>
                <div className={`section ${currentSection===1?'current':''}`}>
                    <div className="left-side">
                        <label htmlFor="request_date">Ημερομηνία</label>
                        <DatePicker  name="request_date" id="request_date" 
                        locale={el}
                        selected={ request.request_date? new Date(request.request_date):''} 
                        onChange={date=>setRequest((prev)=> ( {...prev, request_date:date?date.toLocaleDateString('fr-CA') :null} ))}
                        dateFormat="dd / MM / yyyy"
                        preventOpenOnFocus={true}
                        isClearable={true}
                        showMonthDropdown={true}
                        showYearDropdown={true}
                        adjustDateOnChange
                        dropdownMode="select"
                        className="date-input"
                        customInput={<InputMask mask="99 / 99 / 9999"  maskPlaceholder="_"  alwaysShowMask={true} />}
                        ></DatePicker>

                        <CheckBoxField title="Τύπος αιτήματος" field="request_options" options={requestOptions} values={request.request_options} change={handleChange}/>
                    </div>
                    <div className="right-side">
                        <label htmlFor="des">περιγραφή</label>
                        <textarea name="des" id="des"  value={request.des||''} onChange={(e)=>handleChange(e)} maxLength="1000"/>
                        <p className={`text-info ${request.des && request.des.length > 950 ?`red`: request.des && request.des.length > 900?`orange`:''}`}>({request.des?request.des.length:0})</p>  
                            
                    </div>
            </div>
            <div className={`section ${currentSection===2?'current':''}`}>
                <div>
                    <h3>Τρόπος αντιμετώπισης του αιτήματος</h3>
                    {answerComp}
                </div>
            </div>
            <div className={`section ${currentSection===3?'current':''}`}>
                <div>
                    <h3>Έκβαση περιστατικού</h3>
                    {outcomeComp}
                </div>
            </div>
           { /*<p>{JSON.stringify(request)}</p>*/}
            
            </ModalContent>
            
            <div style={{position:"sticky", bottom:"0"}}>
                <ModalFooter>
                <button className="return-btn" onClick={()=>{dialog.close(request)}}>Εφαρμογή</button>
                <button className="return-btn" onClick={()=>{dialog.close(false)}}>Άκυρο</button>
                </ModalFooter>
            </div>
           
            
        </div>
    )
}
