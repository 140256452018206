import React,{useState,useContext, useEffect, useRef} from 'react'
import { AuthContext } from '../AuthContext'
import axiosAPI from '../services/axiosapi'
import '../utils/fontawesome'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import  {generatePass} from "../utils/util"
import "../style/selectfield.css"

export default function CreatePass(props) {
    const MAX_PASS = 20
    const {user} = props
    const [loading, setLoading]=useState(false)
    const [message, setMessage]=useState({cl:'',message:''})
    const [newPass, setNewPass] = useState(null)
    
    const [passLen, setPassLen]=useState(8)
    const { setToken}  = useContext(AuthContext)
    
    //console.log('np:',newPass)
    const tm = useRef(null)

    
    function handleChange(ev){
        const {value} = ev.target
        setNewPass(value)
    }
    function changeRange(ev){
        setPassLen(ev.target.value)
        setNewPass(null)
    }

    function applyNewPass(np){
        setLoading(true)
        if(!np){
            setMessage({cl:"err",message:"Ο Κωδικός πρόσβασης δεν μπορεί να είναι κενός!"}) 
        }else if (np.length < 8){
            setMessage({cl:"err",message:"Ο Κωδικός πρόσβασης πρέπει να έχει το λιγότερο 8 χαρακτήρες!"}) 
        }else{
            setMessage({cl:"suc",message:"OK"}) 
            props.updateUser(np)
        }
        setLoading(false)
        tm.current = setTimeout(()=>{
            setMessage({cl:"",message:""})
            setNewPass(null)
        },1000)
        setLoading(false)
    }
/*
    function clearNewPass(){
        setNewPass(null)
        props.updateUser(null)
    }
*/

  function saveNewPass( id, np ){
    setLoading(true)
    if(!id){
        
        if(!np){
            setMessage({cl:"err",message:"Ο Κωδικός πρόσβασης δεν μπορεί να είναι κενός!"}) 
        }else if (np.length < 8){
            setMessage({cl:"err",message:"Ο Κωδικός πρόσβασης πρέπει να έχει το λιγότερο 8 χαρακτήρες!"}) 
        }else{
            setMessage({cl:"suc",message:"OK"}) 
            props.updateUser(np)
        }
        tm.current = setTimeout(()=>{
            setMessage({cl:"",message:""})
            setNewPass(null)
        },3000)
        setLoading(false)
        return
    }

    const tkn=localStorage.getItem('token')||false
    const headers= {
      'Content-Type':'application/json',
      'Authorization': `Bearer ${tkn}`,
    }
    axiosAPI.post("users.php",{id:id, newp:np, mode:"chpass"}, {headers})
    .then(response=>{
        setLoading(false)
        const {error, errorMessage,token} = response.data
        if(parseInt(error)<0){
            //setMessage({error:error,message:errorMessage})
            localStorage.removeItem('token')
            setToken(false)
        }else{
            const {affected}=response.data
            if(affected > 0){
                props.updateUser(np)
            }
            const errorClass = affected && affected > 0 ?"suc":error>0?"err":"info"
            setMessage({cl:errorClass,message:errorMessage}) 
            tm.current = setTimeout(()=>{
                setMessage({cl:"",message:""})
                setNewPass(null)
            },3000)
            localStorage.setItem('token',token)
            setToken(token)  
        } 
    })

  }



  useEffect(() => {
      
      return () => {
         clearTimeout(tm.current) 
      }
  }, [])
  return (
        <fieldset disabled={props.dis} className={props.dis?'disabled-fieldset':''}>
            <legend>Δημιουργία Κωδικού Πρόσβασης</legend>
        
            <label htmlFor="myRange">Μήκος Κωδικού Πρόσβασης</label>
            <input type="range" name="passLen" className="slider" min="4" max={MAX_PASS} value={passLen}  id="myRange" onChange={(e)=>changeRange(e)}/>
            <span className={`slider-txt ${passLen < 8 ? 'weak':''}`}>{passLen}</span>
            
            <FontAwesomeIcon icon={['fas', 'key']}  className="icons icon-sm" onClick={(e)=>setNewPass(generatePass(passLen))}></FontAwesomeIcon>
            
            {!loading && props.updatePass?
            <FontAwesomeIcon icon={['fas', 'save']} className="icons icon-sm" onClick={()=>saveNewPass(user.id,newPass )}></FontAwesomeIcon>
            :''
            }
             {!loading && !props.updatePass?
             <>
                <FontAwesomeIcon icon={['fas', 'exchange-alt']} className="icons icon-sm" onClick={()=>applyNewPass(newPass)}></FontAwesomeIcon>
                
            </>
            :''
            }
           
            {props.updatePass?
                <input style={{marginTop:"0.5em"}}type="text" name="newPass" readOnly={false}  value={newPass||''} maxLength={passLen}  onChange={(e)=>handleChange(e)}/>    
                :
                <div style={{minHeight:'1.2em'}}>{newPass}</div>
            }
            {
                loading 
                ? 
                <FontAwesomeIcon icon={['fas', 'spinner']}   className="icons-sm fa-spin" style={{marginLeft:'1em'}} />
                :
                ''
            }   
            <div style={{minHeight:'1.5em',marginTop:'0.5em'}} className={message.cl||null}>{ message.message||''}</div>     
            
             
        </fieldset>
    )


    
}

CreatePass.defaultProps={
    updatePass:true,
    user:null,
    updateUser:false,
    dis:false
}
