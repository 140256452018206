import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {Confirm} from 'react-st-modal'
import '../utils/fontawesome'
import '../style/actionprofile.css'

function ActionItem(props) {

    const {
            actionID,
            //action_auth_id,
            actionDate, 
            is_new, 
            actionPlace, 
            action_place_des, 
            visitType, 
            actionType, 
            action_type_des,
            diagnosis,
            members,
            opt_diagnosis,
            users,
           medication,
           opt_medication
        } = props.itemData
        
        const  {contact} = props
        const gender = contact && contact.gender ? contact.gender % 2===0?'female':'male' : false
        const age = contact && contact.birthYear? new Date().getFullYear() - contact.birthYear:''
        
        const diagnosisList = diagnosis.map((d,dindex)=>{ 
            const optDiagn = opt_diagnosis.find(o=>o.id===d.option_id) || null
             return   <li key={dindex}><span className='d-code'>{optDiagn.diagnosis_code} [{optDiagn.group_code}]</span> <span>{optDiagn.des}</span></li>
        })

        const participantsInfo = members.map(p=> {
            const member = users.find(u=>u.id===p.user_id) 
            return member ? `${member.fullname} (${member.specialty}) `:'-'}).join(', ')
         
        const medicationInfo = medication.map(m=> {
            const optMed = opt_medication.find(o=>o.id===m.option_id) 
            return optMed ? `${optMed.des}: (${m.des})`:'-'}).join(', ')
 
        
        //console.log(props.curItem)
    return (
        <div className={`action-profile-container ${props.curItem?'current-action':''}` }>
            <div className="action-date">
                <div className='a-date'>{actionDate ? actionDate.split('-').reverse().join('-'):''}</div> 
                <div className={`is-new ${is_new ?'new-y':''}`}>{is_new ? 'Ν' : parseInt(is_new)===0?'Ε':''}</div>
                    
            </div>
            { !props.contact ? 
            <div className='blank-cell'></div>
            :
            <>
                <div className='contact-name'>{contact.name}</div>
                <div className='contact-info'>{gender ? <FontAwesomeIcon icon={['fas' ,`${gender}`]} />:'-'} {age ? `(${age})`:''}</div>
                <div className='contact-amka'>{contact.amka}</div>
                <div className='contact-folder'><span>Α.Φ:</span>{contact.folderNumber||'-'}</div>
            </>}
            
            <div className='action-place'>
                {actionPlace?actionPlace.des:''} {action_place_des? `- ${action_place_des}`:''}
                {/*serviceType?serviceType.des:''} {a.service_type_des?`(${a.service_type_des})`:''*/}
            </div>
           
            <div className='action-visit-type'>{visitType? visitType.des:''}</div>
            <div className='action-type'>
                {actionType ? actionType.des:''} {action_type_des ? `- ${action_type_des}`:''}
            </div>
            <div className='a-diagnosis'>
                <ul>
                {diagnosisList}
                </ul>
            </div>
            <div className='a-participants'>{participantsInfo}</div>
            <div className='a-medication'>{medicationInfo}</div>
            <div className='action-tool'>
                <FontAwesomeIcon  icon={['fas', 'edit']} onClick={()=>props.edit(actionID)}/>
                {<div >ID-{actionID}</div>}
                
                <FontAwesomeIcon icon={['fas', 'trash-alt']} onClick={async () => {
                            const result = await Confirm('Να Διαγραφεί η Πράξη ?', 
                            'Διαγραφή', 'Ναι', 'Όχι')
                            if (result) {
                                props.delete(actionID)
                            } 
                      }}/>
                
            </div>
        </div>
    )
}

export default ActionItem
