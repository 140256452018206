import { Buffer } from "buffer";
export function createContent(title, fieldName, curObject, metadata){
    const opt = metadata.filter(item=>item.field === fieldName)
    let content ={}
    let extra=[]
    let temp=[]
    if(opt){
        temp=opt.map(i=> i.extra?JSON.parse(i.extra):null) 
        extra = [].concat(...temp)
        extra =extra.reduce((acc, item,i)=>{
          return item!==null ? [...acc, item.field]:[...acc]
        },[])
        extra=extra.filter((item, i)=> extra.indexOf(item)===i)
       
        
        //console.log("extra: ", extra)
    
      content ={
          labelTitle:title,
          currentValue:curObject[fieldName],
          fieldName:fieldName,
          extraFields:extra,
          options:opt.map(i=>{
            const obj ={value:i.id,des:i.des,extra:i.extra?JSON.parse(i.extra):[]}
            return obj
          })
      
        }
      }
      return content
    }

    // Returns a flag emoji from a 2-letter country code
  export  function country2emoji2(country_code) {
        const OFFSET = 127397;
        const codeArray = Array.from(country_code.toUpperCase());
        return String.fromCodePoint(...codeArray.map((c) => c.charCodeAt() + OFFSET));
    }

    export const selectComp = ['occupation', 'children', 'brothers', 'insurance']

    export function decodeToken(token){
      //const properties=['']
      try{
        const tkn=  token ? JSON.parse(Buffer.from(token.split('.')[1],'base64').toString('utf8')) : false
        return tkn
      }catch(e){
        //console.log(e)
        return false
      }
    }

    export function remainColor(val, type="t"){
      if(val <=180)
        return type==="t" ? "color-180":"c-180"
      if(val <200)
        return type==="t" ? "color-200":"c-200"
      else if(val < 300)
        return type==="t"?"color-300":"c-300"
      else if(val < 400)
        return type==="t"?"color-400":"c-400"
      else if(val < 500)
        return type==="t"?"color-500":"c-500"
      else if(val < 600)
        return type==="t"?"color-600":"c-600"
      else if(val < 700)
        return type==="t"?"color-700":"c-700"
      else if(val < 800)
        return type==="t"?"color-800":"c-800"
      else if(val < 850)
        return type==="t"?"color-850":"c-850"
      else if(val < 950)
        return type==="t"?"color-950":"c-950"
      else if(val < 1000)
        return type==="t"?"color-1000":"c-1000"
      else if(val < 1200)
        return type==="t"?"color-1100":"c-1100"
      else if(val < 1200)
        return type==="t"?"color-1200":"c-1200"
      else if(val < 1300)
        return type==="t"?"color-1300":"c-1300"
      else if(val < 1400)
        return type==="t"? "color-1400":"c-1400"
       else if(val < 1500)
        return type==="t"? "color-1500":"c-1500"
      else if(val < 1600)
        return type==="t"?"color-1600":"c-1600"
       else if(val < 1700)
        return type==="t"?"color-1700":"c-1700"
      else if(val <1800)
        return type==="t"? "color-1800" :"c-1800"
      else 
        return type==="t"? "color-1800" :"c-1800"
    }

    export function spinIcon(val){
      return val <= 200? "spin":""
    }


    export function shuffle(arr) {
      for (let i = arr.length - 1; i > 0; i--) {
        let j = Math.floor(Math.random() * (i + 1))
        let temp = arr[i]
        arr[i] = arr[j]
        arr[j] = temp
      }
      return arr
    }

    export function generatePass(len=8){
      const letUp="ABCDEFGHJKLMNPQRSTUVWXYZ"
      const letLo="abcdefghijkmnpqrstuvwxyz"
      const num="23456789"
      const sym = "!@#$%^&*-+=?"
      const all=letUp + letLo + num + sym
      const fullArr  = shuffle(all.split(''))
      
      let idx= Math.floor(Math.random() * letUp.length)
      const letterUp = letUp.split('')[idx]
      
      idx=Math.floor(Math.random() * letLo.length)
      const letterLo = letLo.split('')[idx]
     
      idx=Math.floor(Math.random() * num.length)
      const digit = num.split('')[idx]

      idx=Math.floor(Math.random() * sym.length)
      const symbol = sym.split('')[idx]
      const finalArr =[letterUp ,letterLo, digit, symbol ] 
      for(let i =0 ; i < len-4;i++){
        //idx=Math.floor(Math.random() * fullArr.length)
        finalArr.push(fullArr[i])
      }
       
      return finalArr.join('')
    }
    
    export function getMessageClass(err, aff=0){
      if(err === 0 && aff > 0)
        return 'suc'
      else if (err===0 && aff===0)
        return 'info' 
      else
        return 'err'
    }


    export function checkAMKA(num, len=11){
      if(num){
        const tempNum = num.toString()
        if(tempNum.length === len){
          const tempTbl = tempNum.split('')
          const checkDigit = parseInt(tempTbl.pop())
          const lenPayload=tempTbl.length
          let sum=checkDigit
          for(let i=0; i<lenPayload; i++){
            const digit =  parseInt(tempTbl[lenPayload-1-i])
            sum+= i % 2 ? digit:digit*2 > 9 ? digit*2-9:digit*2
          }
          return sum % 10 ? false :true
        }
        return false
      }
      return true 
    }

    /*
     if( a.action_date < b.action_date)
       return 1
      else if(a.action_date> b.action_date)
       return -1
      else if (a.action_id > b.action_id)
       return 1
      else if (a.action_id < b.action_id)
       return -1
      else if (a.option_id > b.option_id)
       return 1
      else if (a.option_id < b.option_id)
       return -1
      else
        return 0
    */
    