import React, {useState, useContext} from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import Select from 'react-select'
import DatePicker ,{registerLocale, setDefaultLocale} from 'react-datepicker'
import InputMask from 'react-input-mask'
import {CustomDialog} from 'react-st-modal'
import ActionDialogContent from './ActionDialogContent'
import DiagnosisDialogContent from './DiagnosisDialogContent'
import axiosAPI from '../services/axiosapi'
import {el} from 'date-fns/locale/'
import "../style/profile.css"
import "react-datepicker/dist/react-datepicker.css"
import '../style/selectfield.css'
import '../style/actiondependedfield.css'
import '../utils/fontawesome'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '../style/action.css'
import { AuthContext } from '../AuthContext'
import {decodeToken} from "../utils/util"



function Action(props) {
    const { token, setToken}  = useContext(AuthContext)
    const noOptionsText="Δεν υπάρχουν επιλογές"
    const brandColor = 'var(--bd-color)'
    const navigate = useNavigate()
    const location = useLocation()
    registerLocale('el',el)
    setDefaultLocale('el')

    const payload = decodeToken(token)
    
    const {actionId}=props
    const {
            actions, 
            metadata, 
            action_diagnosis, 
            action_symptoms, 
            action_therapeutic_plan, 
            action_medication, 
            opt_diagnosis, 
            action_participants, 
            users} = props.profile

    //const {m_unit_default_id} = props.profile.contact.m_unit_default_id

    const blancAction={
        id:null,
        profile_id:props.profile.contact.id,
        action_date:null,
        action_type_id:null,
        action_type_des:null,
		visit_type_id:null,
		action_place_id:null,
		action_place_des:null,
        action_auth_id:parseInt(payload.userID)||null,
        is_new:null,
        action_rem:null
    }
    
    const partUsers =  action_participants.filter(p=>parseInt(actionId)===parseInt(p.action_id)).map(p=>p.user_id)
    //const partUsers =  action_participants.filter(p=>parseInt(actionId)===parseInt(p.action_id) && p.auth===0 ).map(p=>p.user_id)
   // const userAuth = actionId > 0 ?action_participants.find(p=>parseInt(actionId)===parseInt(p.action_id) && p.auth===1) : users.map(u=>({user_id:u.id})).find(u=>parseInt(u.user_id)===parseInt(payload.userID))|| null
    const userOptions = users.filter(v=>parseInt(v.specialty_id)!==20).map(u=>({value:u.id, label:u.fullname, is_active:u.is_active}))
    const volOptions = users.filter(v=>parseInt(v.specialty_id)===20).map(u=>({value:u.id, label:u.fullname, is_active:u.is_active}))

    const currentAction = actions.find(a=>a.id===parseInt(actionId)) || blancAction
    //console.log("ca:", currentAction)

    const [action, setAction] = useState(currentAction)
    const [inProgress, setInProgress] = useState(false)

    let tempSymptoms = action_symptoms.filter(ad=>ad.action_id===parseInt(actionId)).map(m=>({option_id:m.option_id, des:m.des}))||null
    if(!tempSymptoms.length){
        tempSymptoms=props.profile.symptoms ? props.profile.symptoms.map(m=>({option_id:m.option_id, des:m.des})):[]
    }
    //const [symptoms,setSymptoms]=useState(action_symptoms.filter(as=>as.action_id===parseInt(actionId)).map(m=>({option_id:m.option_id, des:m.des}))||[])
    const [symptoms,setSymptoms]=useState(tempSymptoms)

    let tempDiagnosis = action_diagnosis.filter(ad=>ad.action_id===parseInt(actionId)).map(m=>({option_id:m.option_id, des:m.des}))||null
    if(!tempDiagnosis.length){
        tempDiagnosis=props.profile.diagnosis ? props.profile.diagnosis.map(m=>({option_id:m.option_id, des:m.des})):[]
    }
    //console.log(tempDiagnosis)
    //const [diagnosis,setDiagnosis]=useState(action_diagnosis.filter(ad=>ad.action_id===parseInt(actionId)).map(m=>({option_id:m.option_id, des:m.des}))||[])
    const [diagnosis,setDiagnosis]=useState(tempDiagnosis)

    let tempTherapeuticPlan = action_therapeutic_plan.filter(ad=>ad.action_id===parseInt(actionId)).map(m=>({option_id:m.option_id, des:m.des}))||null
    if(!tempTherapeuticPlan.length){
        tempTherapeuticPlan=props.profile.therapeutic_plan ? props.profile.therapeutic_plan.map(m=>({option_id:m.option_id, des:m.des})):[]
    }
    //const [therapeuticPlan,setTherapeuticPlan]=useState(action_therapeutic_plan.filter(as=>as.action_id===parseInt(actionId)).map(m=>({option_id:m.option_id, des:m.des}))||[])
    const [therapeuticPlan,setTherapeuticPlan]=useState(tempTherapeuticPlan)

    
    let tempMedication = action_medication.filter(ad=>ad.action_id===parseInt(actionId)).map(m=>({option_id:m.option_id, des:m.des}))||null
    if(!tempMedication.length){
        tempMedication=props.profile.medication ? props.profile.medication.map(m=>({option_id:m.option_id, des:m.des})):[]
    }
    //const [medication,setMedication]=useState(action_medication.filter(ad=>ad.action_id===parseInt(actionId)).map(m=>({option_id:m.option_id, des:m.des}))||[])
    const [medication,setMedication]=useState(tempMedication)
    
    const [participants, setParticipants]= useState(partUsers)
    //const [auth, setAuth] = useState(userAuth ? userAuth.user_id:null)
    

    const actionTypes = metadata.filter(o=>o.field==="action_type_id")
    const actionTypeOptions = actionTypes.map(t =>({value:t.id,label:t.des}))
    const actionTypeExtra =getExtraFields(actionTypes, 'action_type_id')    

    const actionPlaces = metadata.filter(o=>o.field==="action_place_id")
    const actionPlaceOptions = actionPlaces.map(t =>({value:t.id,label:t.des}))
    const actionPlaceExtra =getExtraFields(actionPlaces, 'action_place_id')

    const visitTypes = metadata.filter(o=>o.field==="visit_type_id")
    const visitTypeOptions = visitTypes.map(t =>({value:t.id,label:t.des}))
   // const actionPlaceExtra =getExtraFields(actionPlaces, 'action_place_id')

  
   const symptomOptions = metadata.filter(o=>o.field==="symptoms") //.map(s=>({value:s.id, label:s.des}))
   const symptomList = symptoms.map((s,i)=>{
       const option =  symptomOptions.find(o=>o.id===s.option_id)
       if(option)
        return <li key={`k_${i+1}`}>{option.des} {s.des? ` - ${s.des}`:''}</li>
       return ''
   })
   
   const medicationOptions = metadata.filter(o=>o.field==="medication") //.map(s=>({value:s.id, label:s.des}))
   const medicationList = medication.map((s,i)=>{
       const option = medicationOptions.find(o=>o.id===s.option_id)
       if(option)
        return <li key={`k_${i+1}`}>{option.des} {s.des? ` - ${s.des}`:''}</li>
       return ''
   })
   
   const therapeuticPlanOptions = metadata.filter(o=>o.field==="therapeutic_plan") //.map(s=>({value:s.id, label:s.des}))
   //console.log(therapeuticPlanOptions)
   const therapeuticPlanList = therapeuticPlan.map((s,i)=>{
       const option =  therapeuticPlanOptions.find(o=>o.id===s.option_id) 
       if(option)
        return <li key={`k_${i+1}`}>{option.des} {s.des? ` - ${s.des}`:''}</li> 
       return ''
   })



   const diagnosisList = diagnosis.map((d,i)=>{
    const option =  opt_diagnosis.find(o=>o.id===d.option_id)
    if(option)
        return <li key={`k_${i+1}`}>[{option.diagnosis_code}] {option.des} {d.des? ` - ${d.des}`:''}</li>
    return ''
   })
    
    

    function getExtraFields(opt, fld, postFix=""){
        const extraComp =opt.map(item =>{
            const extra=item.extra?JSON.parse(item.extra):[]
            const extraFields = extra.map((i, j)=>{
                //console.log(i.field)
                const fieldName = `${i.field}${postFix}`
                return(
                action[fld] && action[fld] === item.id ? 
                <input type="text" className="details" key={`t${j}`} name={fieldName} value={action[fieldName]||''} onChange={(e)=>handleTextchange(e)}/>
                    :''
                )
            })
            return extraFields
        })
        return extraComp
    }
    
   
    function handleChangeDate(field, value){ 
        //console.log(ev)
        const  dateVal  = value ? value.toLocaleDateString('fr-CA') :null
        setAction(prev => ({...prev,[field]:dateVal}))
    }
    function handleSelectChange(v, fld){
       
        const fldId = `${fld}_id`
        const fldDes = fld==="m_unit_from" ? "m_unit_des_from":`${fld}_des`
        action[fldDes]?
            setAction(prev=>({...prev, [fldId]:v? parseInt(v.value):null, [fldDes]:null}))
        :
            setAction(prev=>({...prev, [fldId]:v? parseInt(v.value):null}))
    }

    function handleMultiSelectChange(e, fld){
        const tempParticipants=[...participants]

        //const tempUsers = users.filter(u=>u.specialty_id!==20).map(u=>u.id).filter(u=>tempParticipants.includes(u))
        const tempVol = users.filter(u=>u.specialty_id===20).map(u=>u.id).filter(u=>tempParticipants.includes(u))
        const tempUsers = tempParticipants.filter(u=>!tempVol.includes(u))
        /* const finalTbl = fld==='usr' 
        ?[...Array.isArray(e)? e.map(x=>x.value):[], ...tempVol]
        :[...tempUsers, ...Array.isArray(e)? e.map(x=>x.value):[]] */
        const finalTbl =[...Array.isArray(e)? e.map(x=>x.value):[], ...fld==='usr' ?tempVol :tempUsers]

        setParticipants(finalTbl)
    }

    function handleTextchange(ev){
        const {name, value} = ev.target
        //console.log(name, value)
       /*  const patternKlimakio = /^[1-5]{1}$/
        if(name === 'klimakio' && !patternKlimakio.test(value) && value!=="")
            return */
      
        setAction(prev=>({...prev, [name]:value||null}))
    }

    const customStyles = {
    control: (base, state) => ({
        ...base,
        paddingTop:'0.4em',
        paddingBottom:'0.4em',
        boxShadow: state.isFocused ? 0 : 0,
        borderColor: state.isFocused
        ? brandColor
        : base.borderColor,
        '&:hover': {
        borderColor: state.isFocused
            ? brandColor
            : base.borderColor,
        },
    }),
        multiValueLabel: (styles) => ({
            ...styles,
            color:brandColor,backgroundColor:'whitesmoke',fontSize:'1em', padding:'0.5em'
        }),
    }
        
    function  filterOption (option, inputValue){
        return inputValue ?
                !option.label.substring(0,inputValue.length).localeCompare(inputValue, 'el-GR',{sensitivity:'base'}): true
    }
    function  filterOptionFull (option, inputValue){
        //console.log(option)
        const translate={ά:'α', έ:'ε',ή:'η',ί:'ι',ϊ:'ι',ΐ:'ι',ό:'ο',ύ:'υ',ϋ:'υ',ΰ:'υ', ώ:'ω',ς:'σ', ü :'u', é:'e', ä:'a'}
        const pattern = /[άέήίϊΐόύϋΰώςüéä]/g
        const optionStr = option.data.label.trim().toLowerCase().replace(pattern, match=>translate[match])
        const inputStr = inputValue ? inputValue.trim().toLowerCase().replace(pattern, match=>translate[match]) :''
        //console.log(option)
        return inputValue ? optionStr.includes(inputStr) : true
      }

    function formatUserOptionLabel({ label, is_active}){
        return <>
                <FontAwesomeIcon icon={['far','user']}  style={{float:'right',fontSize:'0.7rem',marginLeft:'0.2rem',color:`${is_active? 'var(--dec)':'#800000'}`}} />
                {label}
            </>
    }


    function updateProfileAction(ev){
       // const start = Date.now()
        setInProgress(true)
        const tkn=localStorage.getItem('token')||false
        const headers= {
        'Content-Type':'application/json',
        'Authorization': `Bearer ${tkn}`,
        }

        axiosAPI.post("updateprofile.php",{
            contact:props.profile.contact, 
            children:props.profile.children,
            brothers:props.profile.brothers,
            symptoms:props.profile.symptoms,
            therapeutic_plan:props.profile.therapeutic_plan, 
            diagnosis:props.profile.diagnosis,
            medication:props.profile.medication,
            occupation:props.profile.occupation,
            insurance:props.profile.insurance,
            request:props.profile.request,
            addiction:props.profile.addiction,

            actionCurrentPage:props.profile.actionCurrentPage,
            action:action,
            action_symptoms:symptoms,
            action_diagnosis:diagnosis,
            action_therapeutic_plan:therapeuticPlan,
            action_medication:medication,
            action_participants:participants,
            //action_auth:auth,
            update_mode:"PFLACN"

        },{headers})
        .then(response=>{
            //const end=Date.now()
            const {error, message, affected, token}=response.data
            if(affected>0){
                const {actionID} = response.data
                setAction(prev=>({...prev, id:actionID }))
                props.saveAction(null,actionID )
                const {
                    symptoms, 
                    diagnosis, 
                    therapeutic_plan, 
                    medication, 
                    last_actions,
                    actionsRPP,
                    actions,
                    actionsTotalRows,
                    actionCurrentPage,
                    action_symptoms,
                    action_diagnosis,
                    action_therapeutic_plan,
                    action_medication,
                    action_participants
                 } = response.data
                props.updateProfile(prev=>({
                    ...prev,
                    symptoms, 
                    diagnosis, 
                    therapeutic_plan, 
                    medication, 
                    last_actions,
                    actionsRPP,
                    actions,
                    actionsTotalRows,
                    actionCurrentPage,
                    action_symptoms,
                    action_diagnosis,
                    action_therapeutic_plan,
                    action_medication,
                    action_participants
                  }))

            }
            props.msg({error,message,affected})
            //const newUrl=`${location.pathname.slice(0,location.pathname.lastIndexOf("/"))}/${props.profile.contact.id}-1`
            //history.replace(newUrl)
            localStorage.setItem('token',token)
            setToken(token)
            //console.log((end-start)/1000)
            //console.log(response.data)
            setInProgress(false)
        })
    }

    function updateAction(){
        setInProgress(true)
        const tkn=localStorage.getItem('token')||false
        const headers= {
            'Content-Type':'application/json',
            'Authorization': `Bearer ${tkn}`,
        }
        axiosAPI.post("updateprofile.php",{
            actionCurrentPage:null,
            action:action,
            action_symptoms:symptoms,
            action_diagnosis:diagnosis,
            action_therapeutic_plan:therapeuticPlan,
            action_medication:medication,
            action_participants:participants,
            //action_auth:auth,
            update_mode:"ACN"
        },{headers})
        .then(response=>{
            const {error, message, affected, token}=response.data
            props.msg({error,message,affected})
            if(parseInt(error)<0){
                //props.msg({error,message,affected})
                localStorage.removeItem('token')
                setToken(false)
              }else {
                localStorage.setItem('token',token)
                setToken(token)
                if(parseInt(error)===0 && parseInt(affected) > 0){
                    const {actionID}=response.data
                    //setProfile(profile)
                    if(actionId<=0){
                        const newUrl=`${location.pathname.slice(0,location.pathname.lastIndexOf("/"))}/${actionID}`
                        navigate(newUrl, { replace: true });
                    }
                    //props.msg({error,message,affected})
                }
            }
            setInProgress(false)
        })
    }



//------------------------------------------------------------------------------------------------------------------------------------
   //console.log(action)
//------------------------------------------------------------------------------------------------------------------------------------
    return (
        <>
            <h3 className='action-title'>Επεξεργασία <span>{actionId > 0 ? `ID-${actionId} `:`Νέας `}</span>Πράξης...</h3>
            <div className='action-content'>
                <div className="section-action">
                    <div className='left-mid-side'>
                        <label htmlFor='action_date'>ημερομηνία Πράξης</label>
                        <DatePicker locale={el} name="action_date" id="action_date" 
                        selected={action.action_date ?new Date(action.action_date):''} 
                        onChange={(date)=>handleChangeDate('action_date',date)}
                        dateFormat="dd / MM / yyyy"
                        preventOpenOnFocus={true}
                        isClearable
                        showMonthDropdown={true}
                        showYearDropdown={true}
                        adjustDateOnChange
                        dropdownMode="select"
                        className="date-input"
                        customInput={<InputMask mask="99 / 99 / 9999"  maskPlaceholder="_"  alwaysShowMask={true} />}

                    ></DatePicker>
                    
                    <label htmlFor="action_type">Τύπος Πράξης</label>
                    <Select 
                        name='action_type' 
                        options={actionTypeOptions} 
                        isClearable={true}
                        placeholder={'Επιλέξτε ...'}
                        isMulti={false}
                        value={actionTypeOptions.find(o=>o.value===action.action_type_id)||null}
                        onChange={v=>handleSelectChange(v, 'action_type')}
                        noOptionsMessage={()=>noOptionsText}
                        className="sel-country"
                        styles={customStyles}
                        menuPlacement='auto'
                        filterOption = {filterOptionFull}
                        
                        />
                        {actionTypeExtra}

                        <label htmlFor="visit_type">Τύπος Επίσκεψης</label>
                        <Select 
                            name='visit_type' 
                            options={visitTypeOptions} 
                            isClearable={true}
                            placeholder={'Επιλέξτε ...'}
                            isMulti={false}
                            value={visitTypeOptions.find(o=>o.value===action.visit_type_id)||null}
                            onChange={v=>handleSelectChange(v, 'visit_type')}
                            noOptionsMessage={()=>noOptionsText}
                            className="sel-country"
                            styles={customStyles}
                            menuPlacement='auto'
                            filterOption = {filterOption}
                            
                        />
                        <label htmlFor="action_place">Τόπος Πράξης</label>
                        <Select 
                            name='action_place' 
                            options={actionPlaceOptions} 
                            isClearable={true}
                            placeholder={'Επιλέξτε ...'}
                            isMulti={false}
                            value={actionPlaceOptions.find(o=>o.value===action.action_place_id)||null}
                            onChange={v=>handleSelectChange(v, 'action_place')}
                            noOptionsMessage={()=>noOptionsText}
                            className="sel-country"
                            styles={customStyles}
                            menuPlacement='auto'
                            filterOption = {filterOption}
                            
                        />
                        {actionPlaceExtra}
                        <label>Νέος Λήπτης
                            <input type="radio" name="is_new" id="is_new" value={1} style={{marginLeft:'2em'}}
                            checked={parseInt(action.is_new)===1?true:false} onChange={(e)=>{handleTextchange(e)}}
                            />
                            <label htmlFor="is_new" className='lbl-inline'>Ν</label>
                            
                            <input type="radio" name="is_new" id="is_no_new" value={0}
                            checked={parseInt(action.is_new)===0?true:false} onChange={(e)=>{handleTextchange(e)}}
                            />
                            <label htmlFor="is_no_new" className='lbl-inline'>Ε</label>
                        </label>
                    </div>
                    <div className="left-mid-side">
                        
                        <label htmlFor="auth">Υπεύθυνος Πράξης</label>
                        <Select 
                            name='auth' 
                            options={userOptions} 
                            isClearable={true}
                            placeholder={'Επιλέξτε ...'}
                            isMulti={false}
                            value={userOptions.find(o=>parseInt(o.value)===parseInt(action.action_auth_id))}
                            //value={action.action_auth_id}
                            onChange={(v)=>setAction(prev=>({...prev, action_auth_id:v ? parseInt(v.value) :null}))}
                            noOptionsMessage={()=>noOptionsText}
                            className="sel-country"
                            styles={customStyles}
                            menuPlacement='auto'
                            filterOption = {filterOption}
                        />

                        <label htmlFor="participants">Συμμετέχοντες</label>
                        <Select 
                            name='participants' 
                            options={userOptions} 
                            isClearable={true}
                            placeholder={'Επιλέξτε ...'}
                            isMulti={true}
                            value={userOptions.filter(x=>participants.includes(x.value))}
                            onChange={(e)=>handleMultiSelectChange(e, 'usr')}
                            noOptionsMessage={()=>noOptionsText}
                            className="sel-country"
                            styles={customStyles}
                            menuPlacement='auto'
                            filterOption = {filterOption}
                            formatOptionLabel = {formatUserOptionLabel}
                            
                        />

                        <label htmlFor="volontaires">Εθελοντές</label>
                        <Select 
                            name='volontaires' 
                            options={volOptions} 
                            isClearable={true}
                            placeholder={'Επιλέξτε ...'}
                            isMulti={true}
                            value={volOptions.filter(x=>participants.includes(x.value))}
                            onChange={(e)=>handleMultiSelectChange(e, 'vol')}
                            noOptionsMessage={()=>noOptionsText}
                            className="sel-country"
                            styles={customStyles}
                            menuPlacement='auto'
                            filterOption = {filterOption}
                            formatOptionLabel = {formatUserOptionLabel}
                            
                        />
                    
                    
                    </div>
                    <div className='right-mid-side'>
                        <label className='sm-lbl'>
                            Συμπτώματα 
                            <FontAwesomeIcon icon={['fas', 'edit']}  className="grid-icons" 
                                    onClick={ async ()=>{
                                        const result = await CustomDialog(<ActionDialogContent options={symptomOptions} values={symptoms} field='symptoms' />,
                                            {title:"Συμπτώματα", showCloseIcon:true, isCanClose:false, className:"diagnclass myclass"})
                                            
                                        if(result){
                                            //console.log('symptom:',result)
                                            setSymptoms(result)
                                        }
                                        
                                    }
                                } 
                            />

                        </label>
                        <ul className='nosos'>
                            {symptomList}
                        </ul>
                        <label className='sm-lbl'>
                            Διάγνωση
                            <FontAwesomeIcon icon={['fas', 'edit']}  className="grid-icons" 
                                    onClick={ async ()=>{
                                        const result = await CustomDialog(<DiagnosisDialogContent options={opt_diagnosis} values={diagnosis}  />,
                                            {title:"Διάγνωση", showCloseIcon:true, isCanClose:false, className:"diagnclass"})
                                            
                                        if(result){
                                        // console.log(result, diagnosis)
                                            //props.update(result, fld, a.id)
                                            setDiagnosis(result.map(r=>({option_id:r,des:null})))
                                        // props.updateName(result)
                                        }
                                    }
                                } 
                            />
                        </label>
                        <ul className='nosos'>
                            {diagnosisList}
                        </ul>

                        <label className='sm-lbl'>
                            Φαρμακευτική αγωγή 
                            <FontAwesomeIcon icon={['fas', 'edit']}  className="grid-icons" 
                                    onClick={ async ()=>{
                                        const result = await CustomDialog(<ActionDialogContent options={medicationOptions} values={medication} field='medication' />,
                                            {title:"Φαρμακευτική αγωγή", showCloseIcon:true, isCanClose:false, className:"myclass"})
                                            
                                        if(result){
                                        //console.log(result)
                                            //props.update(result, fld, a.id)
                                            setMedication(result)
                                        // props.updateName(result)
                                        }
                                    }
                                } 
                            />

                        </label>
                        <ul className='nosos'>
                            {medicationList}
                        </ul>

                        <label className='sm-lbl'>
                            Θεραπευτικό πλάνο 
                            <FontAwesomeIcon icon={['fas', 'edit']}  className="grid-icons" 
                                    onClick={ async ()=>{
                                        const result = await CustomDialog(<ActionDialogContent options={therapeuticPlanOptions} values={therapeuticPlan} field='therapeutic_plan'  />,
                                            {title:"Θεραπευτικό πλάνο", showCloseIcon:true, isCanClose:false, className:"myclass"})
                                            
                                        if(result){
                                       // console.log(result)
                                            //props.update(result, fld, a.id)
                                            setTherapeuticPlan(result)
                                        // props.updateName(result)
                                        }
                                    }
                                } 
                            />

                        </label>
                        <ul className='nosos'>
                            {therapeuticPlanList}
                        </ul>
                    

                    </div>
                </div>
                <div className='rem-area'>
                    <label htmlFor="action_rem">Παρατηρήσεις - Σημειώσεις</label>
                    <textarea id="action_rem" name="action_rem"  value={action.action_rem||''} onChange={(e)=>{handleTextchange(e)}} />
                </div>
            </div>
            {!props.actiononly ?
               <button type="button" disabled={inProgress} name="btnSave" className='update-btn' onClick={(e)=>updateProfileAction(e)}>
                    {inProgress?<FontAwesomeIcon icon={['fas', 'spinner']}  spin />:'Αποθήκευση'}
                </button>
            :
                <button type="button" disabled={inProgress} name="btnSave" className='update-btn' onClick={(e)=>updateAction()}>
                    {inProgress?<FontAwesomeIcon icon={['fas', 'spinner']}  spin />:'Αποθήκευση'}
                </button>
            }
            <span className='ch-pass-btn' style={{marginLeft:'4em'}} onClick={(e)=>props.saveAction(e, 0)}>Επιστροφή</span>
        </>
    )
}

Action.defaultProps={
    actiononly:false
}

export default Action
