import React, {useState, useEffect, useContext, useRef} from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import DatePicker ,{registerLocale, setDefaultLocale} from 'react-datepicker'
import {el} from 'date-fns/locale/'
import axiosAPI from '../services/axiosapi'
import Loader from "./Loader"
import { AuthContext } from '../AuthContext'
import { getMessageClass} from '../utils/util'
import InputMask from 'react-input-mask'
import Select from 'react-select'
import { AsyncPaginate } from 'react-select-async-paginate'
import '../utils/fontawesome'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import "../style/appointment.css"

registerLocale('el',el)
setDefaultLocale('el')

function Appointment() {
    const noOptionsText="Δεν υπάρχουν επιλογές"
    const brandColor = 'var(--bd-color)'
    const weekDays=['ΔΕΥ', 'ΤΡΙ', 'ΤΕΤ', 'ΠΕΜ', 'ΠΑΡ', 'ΣΑΒ', 'ΚΥΡ']
    const navigate = useNavigate()
    const location = useLocation()
    const startTimeRef = useRef(null)
    const endTimeRef = useRef(null)
    const roomRef = useRef(null)

    const { setToken}  = useContext(AuthContext)
    const {id, pflid}=useParams()
    const timer = useRef(null)
    const [appointment, setAppointment]=useState(null)
    const [metadata, setMetadata]=useState(null)
    const [isLoad, setIsLoad] = useState(false)
    const [updateMessage,setUpdateMessage]=useState({error:0,message:'', affected:0})
    const [profile, setProfile] = useState(null)
    const [participants, setParticipants]=useState([])
    const [repeatType, setRepeatType] = useState(null)
    const [repeat, setRpeat]=useState(null)
    const [errorInfo , setErrorInfo] =useState(null)
    const roomOptions = metadata ? metadata?.rooms.map(r=>({label:r.room_name, value:r.id})):[]
    const subjectOptions = metadata ? metadata?.options.filter(o=>o.field==='appointment_subject_id').map(s=>({label:s.des,value:s.id})):[]
    const toolOptions = metadata ? metadata?.options.filter(o=>o.field==='appointment_tool_id').map(s=>({label:s.des,value:s.id})):[]
    const resultOptions = metadata ? metadata?.options.filter(o=>o.field==='appointment_result_id').map(s=>({label:s.des,value:s.id})):[]
    const userOptions = metadata ? metadata?.userList.map(u=>({value:u.id, label:u.fullname, is_active:u.is_active})):[]
    const repeatTypeOptions = [{value:1, label:'ημέρα'},{value:7, label:'εβδομάδα'}]
    
    const roomError = errorInfo?.rooms.map((r,i)=>
            <tr key={`kr${i}`}>
                <td>{r.id}</td>
                <td>{r.appointment_date}</td> 
                <td>{r.start_time} - {r.end_time}</td> 
                <td>{r.room_name}</td>
            </tr>)

    const userError = errorInfo?.users.map((r,i)=><tr key={`ku${i}`}>
        <td>{r.id}</td>
        <td>{r.appointment_date}</td> 
        <td>{r.start_time} - {r.end_time}</td> 
        <td>{r.surname} {r.first_name}</td></tr>
        )
    
    const workHoursError = errorInfo?.work_hours.map((w,i)=> <tr key={`wh${i}`}>
        <td>{w.fullname}</td>
        <td>{weekDays[w.work_day]}: {w.enabled ? `${w.hour_start} - ${w.hour_end}`:'Δεν έχει ωράριο'}</td>

    </tr>)

    const toolError = errorInfo?.tools.map((t,i)=>
        <tr key={`kt${i}`}>
            <td>{t.id}</td>
            <td>{t.appointment_date}</td> 
            <td>{t.start_time} - {t.end_time}</td> 
            <td>{t.tool}</td>
        </tr>)
    
    const profileError = errorInfo?.profiles.map((p,i)=>
        <tr key={`kp${i}`}>
            <td>{p.id}</td>
            <td>{p.appointment_date}</td> 
            <td>{p.start_time} - {p.end_time}</td> 
            <td>{p.profile_name} {` (Α.Φ: ${p.folder_number || '-'})`}</td>
        </tr>)

    function   checkPeriod(start, end){
        if( start===null || end===null) 
            return false
        const [hs,ms]=start.split(':')
        const [he,me]=end.split(':')
        const reg=/^\d+$/
        const res_st =  !isNaN(parseInt(hs)) && !isNaN(parseInt(ms)) && hs.match(reg) && ms.match(reg) ? true : false
        const res_e = !isNaN(parseInt(he)) && !isNaN(parseInt(me)) && he.match(reg) && me.match(reg) ? true : false
        //console.log(res_st, res_e)
        if(res_st && res_e){
            const startTime = parseInt(hs) * 60 + parseInt(ms) - 8*60
            const endTime = parseInt(he) * 60 + parseInt(me) - 8*60
            if(endTime > startTime){
               return endTime - startTime
            }
            return false   
        }
        return false
    }
   
    function handleChangeDate(field, value){ 
        const  dateVal  = value ? value.toLocaleDateString('fr-CA') :null
        setAppointment(prev => ({...prev,[field]:dateVal}))
         //startTimeRef.current.getElement().focus()
         //console.log(startTimeRef)
         startTimeRef.current.getInputDOMNode().focus()
        
    } 
    function handleChangeTime(ev){
        const {name, value} = ev.target
        const {start, end} = name==='start_time'? {start:value, end:appointment.end_time}:{start:appointment.start_time, end:value}
        const duration = checkPeriod(start, end)
        
        setAppointment(prev=>({...prev, [name]:value, duration:!duration && duration!==0?null:duration}))
    }

    function handleKeyDownUNP(ev){
        if(ev.key==='Enter' || ev.key==='NumpadEnter'){
            if(ev.target.name==='start_time')
             //endTimeRef.current.focus()
             endTimeRef.current.getInputDOMNode().focus()
            else if(ev.target.name==='end_time')
               roomRef.current.focus()
            else if(ev.target.name==='appointment_date'){
                //startTimeRef.current.focus()
               //console.log(startTimeRef)
               startTimeRef.current.getInputDOMNode().focus()
            }
        }
    }

    function handleChangeText(ev){
        const {name, value} =ev.target
        const patternRepeat = /^\d{0,3}$/
        
        if(name==='repeat'){
            if( !patternRepeat.test(value) && value!=="")
                return
            setRpeat(value)
        }else{
            setAppointment(prev=>({...prev, [name]:value?value:null}))
        }
    }

    function handleSelectChange(v, fld){

        if(fld==='repeat_type'){
            //console.log(v)
            setRepeatType(v ? parseInt(v.value) : null)
        }else{
            setAppointment(prev=>({...prev, [fld]:v? parseInt(v.value):null}))
        }
    }

    function handleMultiSelectChange(e){
        //console.log(e)
        setParticipants(Array.isArray(e)? e.map(x=>x.value):[])
    }


    function asyncSelectChange(e){
       console.log(e)
        setAppointment(prev =>({...prev,profile_id:e? e.value:null}))
        setProfile(e)
    }

    function updateAppointment(e){
        //console.log('id',id)
        
        setIsLoad(false)
        const tkn=localStorage.getItem('token')||false
        const headers= {
        'Content-Type':'application/json',
        'Authorization': `Bearer ${tkn}`,
        }
        axiosAPI.post("appointment.php",{mode:'UPD', appointment, participants,repeatType,repeat}, {headers})
        .then(response => {
            setIsLoad(true)
            const {error, errorMessage, affected, token} = response.data
            if(parseInt(error)<0){
                setUpdateMessage({error:error,message:errorMessage,affected:0})
                localStorage.removeItem('token')
                setToken(false)
            }else {
                localStorage.setItem('token',token)
                setToken(token)
                if(parseInt(error)===0 && parseInt(affected) > 0){
                  const {appointmentID}=response.data
                  //setProfile(profile)
                  if(id==='new'){
                    const newUrl=`${location.pathname.slice(0,location.pathname.lastIndexOf("/new"))}/${appointmentID}`
                    navigate(newUrl, {replace:true})
                  }
                }
                const {errorInfo} = response.data
                setErrorInfo(errorInfo)
                console.log(errorInfo)
                setUpdateMessage({error, message:errorMessage, affected})
            } 
        })
        
    }




    useEffect(() => {
        if(updateMessage.message){
            const err = parseInt(updateMessage.error) === 0 ? 500:3000
            timer.current = setTimeout(()=>{
                setUpdateMessage({error:0,message:"",affected:0})
            },err)
         }
        return () => {
              clearTimeout(timer.current)
          }
    }, [updateMessage])

    useEffect(() => {
        setIsLoad(false)
        const appId = id==="new" || id==="newrv" ? -1 : parseInt(id)
        const search = sessionStorage.getItem('rvsearch')?JSON.parse(sessionStorage.rvsearch):''
        const appDate = search?.appDateStartSearch?new Date(search.appDateStartSearch)?.toLocaleDateString('fr-CA'):null
        const tkn=localStorage.getItem('token')||false
        //------------------------------------------------------------------------------------*******
        const {room_id, start_time, participants,profile_id, tool, subject} = id==="newrv" ?
             sessionStorage.getItem('newrv') ? JSON.parse(sessionStorage.newrv):''
             :''
        const profileAllId = profile_id ? profile_id:pflid
        const headers= {
        'Content-Type':'application/json',
        'Authorization': `Bearer ${tkn}`,
        }
        axiosAPI.post("appointment.php",{id:appId,appDate, profileId:profileAllId||null, room_id, start_time, participants,tool, subject}, {headers})
        .then(response =>{
            const {error, errorMessage, token} = response.data
            if(parseInt(error)<0){
                setUpdateMessage({error:error,message:errorMessage,affected:0})
                localStorage.removeItem('token')
                setToken(false)
            }else if (parseInt(error)===0){
                const {appointment, metadata, participants} = response.data
                const {profile_id, profile_name, profile_folder} = appointment || {profile_id:null, profile_name:null, profile_folder:null}
                setAppointment(appointment)
                setMetadata(metadata)
                setProfile(profile_id ? {label:profile_name, value:profile_id, folder:profile_folder}: null)
                setParticipants(participants.map(p=>p.user_id))
                setIsLoad(true)
                localStorage.setItem('token',token)
                setToken(token)
            }else{
                setIsLoad(true)
                setUpdateMessage({error:error,message:errorMessage,affected:0})
                localStorage.setItem('token',token)
                setToken(token)
                
            }
        })
    }, [id, pflid, setToken])

    const customStyles = {
        control: (base, state) => ({
            ...base,
            maxHeight: '15rem',
            overflowY: 'auto',
            paddingTop:'0.4em',
            paddingBottom:'0.4em',
            boxShadow: state.isFocused ? 0 : 0,
            borderColor: state.isFocused
            ? brandColor
            : base.borderColor,
            '&:hover': {
            borderColor: state.isFocused
                ? brandColor
                : base.borderColor,
            },
        }),
            multiValueLabel: (styles) => ({
                ...styles,
                color:'#000',backgroundColor:'whitesmoke',fontSize:'1em', padding:'0.5em'
            }),
        }
            
        function  filterOption (option, inputValue){
            return inputValue ?
                    !option.label.substring(0,inputValue.length).localeCompare(inputValue, 'el-GR',{sensitivity:'base'}): true
        }
        
        function  filterOptionFull (option, inputValue){
            const translate={ά:'α', έ:'ε',ή:'η',ί:'ι',ϊ:'ι',ΐ:'ι',ό:'ο',ύ:'υ',ϋ:'υ',ΰ:'υ', ώ:'ω',ς:'σ'}
            const pattern = /[άέήίϊΐόύϋΰώς]/g
            const optionStr = option.label.trim().toLowerCase().replace(pattern, match=>translate[match])
            const inputStr = inputValue ? inputValue.trim().toLowerCase().replace(pattern, match=>translate[match]) :''
            return inputValue ? optionStr.includes(inputStr) : true
        }

        function formatOptionLabel({ label, folder}){
            return <>
                <div>{label}</div>
                <span className='opt-label'>{folder || '-'}</span> 
                
                </>
        }
        function formatUserOptionLabel({ label, is_active}){
            return <>
                    <FontAwesomeIcon icon={['far','user']}  style={{float:'right',fontSize:'0.7rem',marginLeft:'0.2rem',color:`${is_active? 'var(--dec)':'#800000'}`}} />
                    {label}
                </>
        }


    

        const loadOptions = async (searchContact, prevOptions) => {
            const tkn=localStorage.getItem('token')||false
            const headers= {
              'Content-Type':'application/json',
              'Authorization': `Bearer ${tkn}`,
            }
            const offset = prevOptions.length
            //console.log("offset", offset)
            try{
                const response  = await axiosAPI.post("profile.php",{searchContact,offset}, {headers})
                const {error} = response.data
                if(error===0){
                    const {options, hasMore} = response.data
                    return {
                        options,
                        hasMore
                    }
                }
            }catch(err){
               // console.log(err)
            }
        
            return {
                options:[],
                hasMore:false
            }
        }
        //console.log(appointment)

    if (!isLoad)
         return (<Loader/>)
    if(appointment)
        return (

            <div className='content' style={{paddingBottom:'1rem'}}>
                <header className='head'>{appointment.id ? <span>{`Ραντεβού: ${appointment.id}`}</span>:<span>Νέο Ραντεβού</span>}<span className='ch-pass-btn' style={{marginLeft:'auto',fontWeight:'normal',fontSize:'1rem',padding:'0 0.1rem'}} onClick={(e)=>{navigate(-1)}}>Επιστροφή</span></header>
                <div className='content current'>
                    <div className='left-side'>
                       <label htmlFor='appointment_date'>Ημερομηνία</label>
                       <DatePicker locale={el} name="appointment_date" id="appointment_date" 
                        selected={appointment.appointment_date ? new Date(appointment.appointment_date):''} 
                        onChange={(date)=>handleChangeDate('appointment_date',date)}
                        onKeyDown={ev=>handleKeyDownUNP(ev)}
                        dateFormat="dd / MM / yyyy"
                        preventOpenOnFocus={true}
                        isClearable
                        showMonthDropdown={true}
                        showYearDropdown={true}
                        adjustDateOnChange
                        dropdownMode="select"
                        className="date-input"
                        customInput={<InputMask mask="99 / 99 / 9999"  maskPlaceholder="_"  alwaysShowMask={true}  />}
                        ></DatePicker>
                        <div className='group-time'>
                            <label htmlFor='start_time'>Από </label>
                            <InputMask 
                                mask="99:99"  
                                id='start_time'
                                /*maskPlaceholder="_"  */
                                alwaysShowMask={false} 
                                className ='date-input time-input' 
                                value={appointment.start_time?appointment.start_time:''}
                                onChange={ev=>handleChangeTime(ev)}
                                onKeyDown={(ev)=>handleKeyDownUNP(ev)}
                                ref={startTimeRef}
                                name="start_time"
                            />
                            <label htmlFor='end_time'>Έως </label>
                            <InputMask 
                                mask="99:99"  
                                id='end_time'
                                alwaysShowMask={false} 
                                className ='date-input time-input' 
                                value={appointment.end_time?appointment.end_time:''}
                                onChange={ev=>handleChangeTime(ev)}
                                onKeyDown={ev=>handleKeyDownUNP(ev)}
                                ref={endTimeRef}
                                name="end_time"
                            />
                            <label>{appointment.duration ? `${appointment.duration} min`:'' }</label>
                        </div>
                        <label htmlFor='room_id'>Χώρος </label>
                        <Select 
                            name='room_id' 
                            id='room_id'
                            options={roomOptions} 
                            isClearable={true}
                            placeholder={'Επιλέξτε χώρο...'}
                            isMulti={false}
                            value={roomOptions.find(r=>r.value===appointment.room_id)||null}
                            onChange={v=>handleSelectChange(v, 'room_id')}
                            noOptionsMessage={()=>noOptionsText}
                            className="sel-part-4"
                            styles={customStyles}
                            menuPlacement='auto'
                            filterOption = {filterOptionFull}
                            ref={roomRef}
                        />
                        <label htmlFor='profile_id'>Ωφελουμενος </label>
                        <AsyncPaginate
                            id="profile_id"
                            value={profile}
                            onChange = { e => asyncSelectChange(e)}
                            loadOptions = {loadOptions}
                            isClearable={true}
                            styles={customStyles}
                            placeholder={'Αναζητηση ωφελούμενου ...'}
                            noOptionsMessage={()=>noOptionsText}
                            className="sel-part-4"
                            isDisabled={pflid?true:false}
                            formatOptionLabel={formatOptionLabel}
                        />
                        <label htmlFor='participants'>Συμμετέχοντες</label>
                        <Select 
                            name='participants' 
                            options={userOptions} 
                            isClearable={true}
                            placeholder={'Επιλέξτε ...'}
                            isMulti={true}
                            value={userOptions.filter(x=>participants.includes(x.value))}
                            onChange={handleMultiSelectChange}
                            noOptionsMessage={()=>noOptionsText}
                            className="sel-part-90"
                            styles={customStyles}
                            menuPlacement='auto'
                            filterOption = {filterOptionFull}
                            formatOptionLabel={formatUserOptionLabel}
                            id='participants'
                        />
                        
                    </div>
                    <div className='right-side'>
                        <label htmlFor='appointment_subject_id'>Θέμα </label>
                        <Select 
                            name='appointment_subject_id' 
                            id='appointment_subject_id'
                            options={subjectOptions} 
                            isClearable={true}
                            placeholder={'Επιλέξτε Θέμα...'}
                            isMulti={false}
                            value={subjectOptions.find(s=>s.value===appointment.appointment_subject_id)||null}
                            onChange={v=>handleSelectChange(v, 'appointment_subject_id')}
                            noOptionsMessage={()=>noOptionsText}
                            className="sel-part-4"
                            styles={customStyles}
                            menuPlacement='auto'
                            filterOption = {filterOption}
                        />
                        <textarea name="app_des" style={{marginTop:'1rem',height:'3.5rem'}} onChange={e=>handleChangeText(e)} value={appointment.app_des || ''}></textarea>
                        {/*
                        <label htmlFor='trainee'>Ασκούμενοι</label>
                        <input type="text" name="trainee" id='trainee' value={appointment.trainee||''} onChange={e=>handleChangeText(e)}  style={{width:'95%'}}/>
                        <label htmlFor='volunteer'>Εθελοντές</label>
                        <input type="text"  name="volunteer" id='volunteer' value={appointment.volunteer||''} onChange={e=>handleChangeText(e)} style={{width:'95%'}}/>
                        <label htmlFor='external_partner'>Εξωτερικοί συνεργάτες</label>
                        <input type="text" name="external_partner" id="external_partner"  value={appointment.external_partner||''} onChange={e=>handleChangeText(e)} style={{width:'95%'}}/>
                        <label htmlFor='appointment_tool_id'>Εργαλείο </label>
                        */}
                        <label htmlFor='appointment_tool_id'>Εργαλείο Επικοινωνίας</label>
                        <Select 
                            name='appointment_tool_id' 
                            id='appointment_tool_id'
                            options={toolOptions} 
                            isClearable={true}
                            placeholder={'Επιλέξτε Εργαλείο...'}
                            isMulti={false}
                            value={toolOptions.find(s=>s.value===appointment.appointment_tool_id)||null}
                            onChange={v=>handleSelectChange(v, 'appointment_tool_id')}
                            noOptionsMessage={()=>noOptionsText}
                            className="sel-part-4"
                            styles={customStyles}
                            menuPlacement='auto'
                            filterOption = {filterOption}
                        />
                        
                        <label htmlFor='appointment_result_id'>Αποτέλεσμα </label>
                        <Select 
                            name='appointment_result_id' 
                            id='appointment_result_id'
                            options={resultOptions} 
                            isClearable={true}
                            placeholder={'...'}
                            isMulti={false}
                            value={resultOptions.find(s=>s.value===appointment.appointment_result_id)||null}
                            onChange={v=>handleSelectChange(v, 'appointment_result_id')}
                            noOptionsMessage={()=>noOptionsText}
                            className="sel-part-4"
                            styles={customStyles}
                            menuPlacement='auto'
                            filterOption = {filterOption}
                        />
                        {!appointment.id ?
                        <fieldset className='fld-repeat'>
                            <legend>Επανάληψη</legend>
                            <span>Κάθε </span>
                            <Select 
                                name='repeat_type' 
                                id='repeat_type'
                                options={repeatTypeOptions} 
                                isClearable={true}
                                placeholder={'...'}
                                isMulti={false}
                                value={repeatTypeOptions.find(r=>r.value===repeatType)||null}
                                onChange={v=>handleSelectChange(v, 'repeat_type')}
                                noOptionsMessage={()=>noOptionsText}
                                className="repeat-type"
                                styles={customStyles}
                                menuPlacement='auto'
                                filterOption = {filterOption}
                            />
                            <span>για</span>
                            <input type="number" min="2" max="100" name="repeat" value={repeat||''} onChange={(e)=>handleChangeText(e)}/>
                            <span>{repeatType===1 ? 'ημερες':repeatType===7?'εβδομάδες':'' }</span>
                        </fieldset>
                        :''
                        }
                        <div>
                            
                            {roomError && roomError.length > 0 ?
                                <table className='error-tbl'>
                                    <tr>
                                        <th colspan="4">Χώρος
                                        <FontAwesomeIcon icon={['fas', 'eraser']} style={{cursor:'pointer', float:'right'}}  onClick={()=>setErrorInfo(pr=>({...pr, rooms:[]}))}/>
                                        </th>
                                    </tr>
                                    {roomError}
                                </table>
                                :''
                            }
                            {userError && userError.length > 0?
                                <table className='error-tbl'>
                                    <tr>
                                        <th colspan="4">Συμμετέχοντες
                                        <FontAwesomeIcon icon={['fas', 'eraser']} style={{cursor:'pointer', float:'right'}}  onClick={()=>setErrorInfo(pr=>({...pr, users:[]}))}/>
                                        </th>
                                    </tr>
                                    {userError}
                                </table>
                                :''
                            }
                            
                            {toolError && toolError.length > 0?
                                <table className='error-tbl'>
                                    <tr>
                                        <th colspan="4">Εργαλείο
                                        <FontAwesomeIcon icon={['fas', 'eraser']} style={{cursor:'pointer', float:'right'}}  onClick={()=>setErrorInfo(pr=>({...pr, tools:[]}))}/>
                                        </th>
                                    </tr>
                                    {toolError}
                                </table>
                                :''
                            }

                            {profileError && profileError.length > 0?
                                <table className='error-tbl'>
                                    <tr>
                                        <th colspan="4">Ωφελούμενοι
                                        <FontAwesomeIcon icon={['fas', 'eraser']} style={{cursor:'pointer', float:'right'}}  onClick={()=>setErrorInfo(pr=>({...pr, profiles:[]}))}/>
                                        </th>
                                    </tr>
                                    {profileError}
                                </table>
                                :''
                            }
                            {workHoursError && workHoursError.length > 0 ?
                                <table className='error-tbl'>
                                <tr>
                                    <th colspan="2">Εκτός Ωραρίου
                                    <FontAwesomeIcon icon={['fas', 'eraser']} style={{cursor:'pointer', float:'right'}}  onClick={()=>setErrorInfo(pr=>({...pr, work_hours:[]}))}/>
                                    </th>
                                </tr>
                                {workHoursError}
                            </table>
                            :''

                            }
                        </div>
                        
                    </div>
                   
                </div>
                
                <button type="button" name="btnSave" className='update-btn' onClick={(e=>updateAppointment(e))}>Αποθήκευση</button>
                <span className='ch-pass-btn' style={{marginLeft:'4em'}} onClick={(e)=>{navigate(-1)}}>Επιστροφή</span>
                {/*<footer className="footer">Terms etc...</footer>*/}
                { 
                    updateMessage.message ? 
                    <div className={`message-box ${getMessageClass(updateMessage.error, updateMessage.affected)}`}>{updateMessage.message}</div>
                    :
                    ''
                } 

            </div>
        )
        else 
            return (updateMessage.message ? <pre className={`message-box ${getMessageClass(updateMessage.error, updateMessage.affected)}`}>{updateMessage.message}</pre>:'')
}

export default Appointment