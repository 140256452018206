import React from 'react'
import {CustomDialog, Confirm} from 'react-st-modal'
import RequestDialogComponent from './RequestDialogComponent'
import '../utils/fontawesome'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '../style/request.css'

export default function RequestListComponent(props) {
    const {metadata, request} = props.profile;
    const answerOptions= metadata.filter(o=>o.field==="request_answer_id")
    const outcomeOptions = metadata.filter(o=>o.field==="outcome_id")
    const requestOptions = metadata.filter(o=>o.field==="request_option")
    const options={requestOptions,answerOptions,outcomeOptions}
    const requestContent=  request.map((r,i)=>{
        const requestDate=r.request_date ? new Date(r.request_date).toLocaleDateString('el-GR'):null
        const answer= answerOptions.find(a=> r.request_answer_id === a.id)
        const outcome = outcomeOptions.find(o=>r.outcome_id === o.id)
        const title = `Αίτημα - ${r.id}`
        const requestOptionsComp = r.request_options.map((ro, j)=>{
            //console.log("XXX", ro, requestOptions)
            const opt= requestOptions.find(o => o.id===ro.option_id)
            return (
            <li key={`ro_${j}`}>{opt.des} {ro.request_option_des?` - ${ro.request_option_des}`:null}</li>
            )
        })
        
        return (
            <li key={`rq_${i}`} className="request">
                <div className="req-date">{requestDate}</div>
                
                <div className="req-options">
                    <ul>
                       {requestOptionsComp}
           
                    </ul>
                </div>
                <div className="req-des">{r.des}</div>
                <div className="req-act">
                    <FontAwesomeIcon icon={['fas', 'edit']}  className="grid-icons"
                        onClick={ async ()=>{
                            const result = await CustomDialog(<RequestDialogComponent request={r} options={options} title={title} field="request" section={1}/>,
                                 {title, showCloseIcon:true, isCanClose:false, className:"request-modal"})
                                 
                            if(result){
                                props.update(result, i, 'u')
                            }
                        }}
                    />
                    <FontAwesomeIcon icon={['fas', 'trash-alt']} className="icons" 
                        onClick={async () => {
                            const result = await Confirm('Να Διαγραφεί το στοιχείο ?', 
                            'Διαγραφή', 'Ναι', 'Όχι')
                            if (result) {
                                props.update(r, i,'d')
                            } 
                      }}
                    />
                </div>
                <div className="req-label line-answer">Αντιμετώπιση</div>
                <div className="req-answer">{answer?answer.des:'-'} {r.request_answer_des ? `- ${r.request_answer_des}`:''}</div>
                <div className="req-act line-answer">
                    <FontAwesomeIcon icon={['fas', 'edit']}  className="grid-icons" 
                        onClick={ async ()=>{
                            const result = await CustomDialog(<RequestDialogComponent request={r} options={options} title={title} field="request" section={2}/>,
                                 {title, showCloseIcon:true, isCanClose:false, className:"request-modal"})   
                            if(result){
                                //console.log("request", result)
                                props.update(result, i, 'u')
                            }      
                        }}
                    />
                   
                </div>
                <div className="req-label line-outcome">Έκβαση</div>
                <div className="req-outcome line-outcome">{outcome ? outcome.des:'-'} {r.outcome_des ? `- ${r.outcome_des}`:''} </div>
                <div className="req-act line-outcome">
                    <FontAwesomeIcon icon={['fas', 'edit']}  className="grid-icons" 
                        onClick={ async ()=>{
                            const result = await CustomDialog(<RequestDialogComponent request={r} options={options} title={title} field="request" section={3}/>,
                                 {title, showCloseIcon:true, isCanClose:false, className:"request-modal"})
                            if(result){
                                //console.log("request", result)
                                props.update(result, i, 'u')
                            }      
                        }}
                    />
                    
                </div>
                
            </li>
        )
    })
    return (
        <div className="both-sides">
            <h3>
                <FontAwesomeIcon icon={['fas', 'plus-circle']} className="icons add-new-item" 
                    onClick={ async ()=>{
                        const title="Νέο Αίτημα"
                        const newRequest = {id:null, 
                            request_date: new Date().toLocaleDateString('fr-CA'), //new Date().toISOString().slice(0,10),
                            des:null, 
                            request_answer_id:null, 
                            request_answer_des:null,
                            outcome_id:null,
                            request_options:[]
                        }
                        const result = await CustomDialog(<RequestDialogComponent request={newRequest} options={options} title={title} field="request" section={1}/>,
                             {title, showCloseIcon:true, isCanClose:false, className:"request-modal"})
                             
                        if(result){
                            props.update(result, -1, 'i')
                        }
                    }}
                />
                {props.title}
            </h3>
            <ul>
            {requestContent}
            </ul>
        </div>
    )
}
