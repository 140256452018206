import React from 'react'
import { CustomDialog, Confirm } from 'react-st-modal'
import FamilyDialogContent from './FamilyDialogContent'
import '../utils/fontawesome'
//import "../style/monitored.css"
import '../style/actiondependedfield.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//import {selectComp} from '../utils/util'

function FamilyList(props) {
    const {title, field, profile} =props
    const options = profile.metadata.filter(i=>i.field === "gender_id")
   
    const tblData = profile[field].map((item,i)=>{
        item.id=i+1
        const gender = options.find(o=>item.gender_id===o.id)
        const keyPrefix = field==='children'?"ch":"br"
        return (
         <tr key={`${keyPrefix}_${i}`}>
             <td>{i+1}</td>
             {/*field==='brothers'?<td>{item.name}</td>:undefined*/}
             <td>{item.name}</td>
             <td>{gender?gender.des:''}</td>
             <td>{item.birth_year ? new Date().getFullYear() - item.birth_year || '':''}</td>
             <td>({item.birth_year})</td>
             <td className="tbl-act">
                <FontAwesomeIcon icon={['fas', 'edit']}  className="icons" 
                    onClick={async ()=>{
                        const result = await CustomDialog(<FamilyDialogContent field={field} options={options} data={item} title={title} />,
                            {title, showCloseIcon:true, isCanClose:false, className:"modalfamily"})
                            if(result){
                               console.log(result)
                               props.update(field, result,'u', i)
                            }
                            
                    }}
                />
                <FontAwesomeIcon icon={['fas', 'trash-alt']} className="icons" 
                    onClick={async () => {
                        const result = await Confirm('Να Διαγραφεί το στοιχείο ?', 
                        'Διαγραφή', 'Ναι', 'Όχι')
                        if (result) {
                            props.update(field, null,'d', i)
                        } 
                    }}
                
                />
             </td>
         </tr>)
    })
    
    return (
        <>
            <h3>{title} ({profile[field].length})</h3>
            <table className="family-tbl">
                <thead>
                    <tr> 
                        <th>#</th>
                        {/*field==='brothers'?<th>Όνομα</th> : undefined*/}
                        <th>Όνομα</th>
                        <th>φύλο</th>
                        <th colSpan="2">ηλικία</th>
                        <th className="txt-right">λειτουργίες</th>
                    </tr>
                </thead>
                <tbody>
                {tblData}
                </tbody>
                <tfoot>
                    <tr>
                        <td colSpan="5">
                        <FontAwesomeIcon icon={['fas', 'plus-circle']} className="icons add-new-item" 
                            onClick={async ()=>{
                                
                                const newItem= {id:null,name:null, gender_id:null, birth_year:null}
                                const result = await CustomDialog(<FamilyDialogContent field={field} options={options} data={newItem} title={title}/>,
                                    {title, showCloseIcon:true, isCanClose:false, className:"modalfamily"})
                                    if(result){
                                       // console.log("occupation", result)
                                        props.update(field, result,'i')
                                    }
                            }}
                        />
                        </td>
                    </tr>
                </tfoot>
            </table>
        </>
    )
}
export default FamilyList
