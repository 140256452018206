import React, {useState, useEffect, useContext, useRef} from 'react'
import { AuthContext } from '../AuthContext'
import { Link } from 'react-router-dom'
import Pagination from './Pagination'
import axiosAPI from '../services/axiosapi'
import '../utils/fontawesome'
import { getMessageClass } from '../utils/util'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import "../style/profilelist.css"

function UserList(props) {
    
    const { setToken}  = useContext(AuthContext)
    const searchFieds= {
        surnameSearch:null, 
        firstNameSearch:null,
        specialty_id:null,
        user_name:null,
        amka:null,
        account_status:100,
        is_active:100,
        rpp:50,
        page:1
    }

    const {admin} = props
    
   // console.log("search",searchFields )
    const optionNum=[5,10,20,50,100,200,500]
    const columns=admin ? [
        {text:'#',cn:'aa', flag:0},
        {text:'ID',cn:'rj',flag:0},
        {text:'Επώνυμο Όνομα',cn:null,flag:0},
        {text:'ΑΦΜ',cn:null,flag:0},
        {text:'ΑΜΚΑ',cn:null,flag:0},
        {text:'Ειδικότητα ',cn:null,flag:0},
        {text:'Τηλέφωνο',cn:null,flag:0},
        {text:'Όνομα χρήστη',cn:null,flag:0},
        {text:'mail',cn:'toggle',flag:1}
    ] : [
        {text:'#',cn:'aa', flag:0},
        {text:'ID',cn:'rj',flag:0},
        {text:'Επώνυμο Όνομα',cn:null,flag:0},
        {text:'ΑΦΜ',cn:null,flag:0},
        {text:'ΑΜΚΑ',cn:null,flag:0},
        {text:'Ειδικότητα ',cn:null,flag:0},
        {text:'Τηλέφωνο',cn:null,flag:0}
    ]
    
    //const [search, setSearch]=useState(sessionStorage.getItem('search')?JSON.parse(sessionStorage.search):initSearch())
    const [search, setSearch]=useState(sessionStorage.getItem('usrsrch')?JSON.parse(sessionStorage.usrsrch):searchFieds)
    const [users, setUsers]=useState(null)
    const [totalRows, setTotalRrows] =useState(0) 
    const [go, setGo]=useState(0)
    const [allowMail, setAllowMail]=useState(false)
    const [updateMessage,setUpdateMessage]=useState({error:0,message:'', affected:0})
    const [mailLoad, setMailLoad]=useState(false);
    //const [scrollPos, setScrollPos]=useState(sessionStorage.getItem('pos')||0)
    const timer = useRef(null)
    
    const options=optionNum.map(op=>
        <option key={`ok_${op}`} value={op}>{op}</option>
        )
    const columnsHeader= columns.map((c,i)=>{
        return (
            c.flag===1?
            <th key={`ck_${i+1}`} className={c.cn} onClick={(e)=>setAllowMail(p=>!p)}>
            {c.text} <span className={allowMail?'mail-on':'mail-off'}>{allowMail?'ON':'OFF'}</span>
            </th>
            :
            <th key={`ck_${i+1}`} className={c.cn}>{c.text}</th>)}
        )
   
    useEffect(() => {
     setGo(1)
     return () => {
        if(timer.current)
            clearTimeout(timer.current)
        }
    },[])

    useEffect(() => {
        if(updateMessage.message){
            const err = parseInt(updateMessage.error) === 0 ? 1000:5000
            timer.current = setTimeout(()=>{
                setUpdateMessage({error:0,message:"",affected:0})
            },err)
         }
        return () => {
              clearTimeout(timer.current)
          }
      }, [updateMessage])


    useEffect(() => {
        //console.log("go")
        if(go){
            if(go===2)
               window.scrollTo(0,0)

            sessionStorage.setItem('usrsrch', JSON.stringify(search))
            const tkn=localStorage.getItem('token')||false
            const headers= {
                'Content-Type':'application/json',
                'Authorization': `Bearer ${tkn}`,
              }
            axiosAPI.post("users.php",{search:search},{headers})
            .then(response=>{
                const {users, error, token} = response.data
                //console.log("users", response)
                if(parseInt(error) < 0){
                    localStorage.removeItem('token')
                    setToken(false)
                }else if(parseInt(error) === 0){
                    //console.log("contacts:", contacts)
                    setUsers(users)
                    
                    //setSearch(prev=>({...prev, "page":response.data.page}))
                    setTotalRrows(response.data.totalRows)
                    localStorage.setItem('token',token)
                    setToken(token)
                    if(go===1)
                        handleScroll()
                    setGo(0)
                }
                
            })
        }
    },[go, search, setToken])

    function handleScroll(){
        const position = sessionStorage.getItem("usrpos")
        if(position){

            timer.current = setTimeout(()=>{
                window.scrollTo(0,parseInt(position))
                sessionStorage.removeItem("usrpos")
            },0)  
        }

        
    }


    function handleChange(ev, goSearch=false){
        
        const {name,value}=ev.target
        //console.log(name, value)
        const  res =  name==="folderNumberSearch" && isNaN(value) ? null :value||null
    
        if(name==='rpp'){
            setSearch(prev=>({...prev,[name]:res, page:1}))
           
        }else{
            setSearch(prev=>({...prev,[name]:res}))
        }
        if(goSearch){
            setGo(2)
        }
        
        
    }
    
    function getSearch(ev){

       setSearch(prev=>({...prev, page:1}))
       setGo(2)
    }
    function clearSearch(ev){
        //setSearch(initSearch())
        setSearch(searchFieds)
        sessionStorage.removeItem('usrsrch')
    }

    function storeLocal(){
        sessionStorage.setItem('usrpos', window.scrollY)
        sessionStorage.setItem('usrsrch', JSON.stringify(search))
    }


    
    function changePage(ev){
        const {value}=ev.target
        setSearch(prev=>({...prev,page:value}))
        setGo(2)
        
    }

    function sendMail(ev, uid){
        if(!allowMail)
            return 
        setMailLoad(uid)
        const tkn=localStorage.getItem('token')||false
        const headers= {
            'Content-Type':'application/json',
            'Authorization': `Bearer ${tkn}`,
        }
        axiosAPI.post("users.php",{id:uid, mode:"sMail"}, {headers})
        .then(response=>{
            
            const {error, errorMessage,token} = response.data
            setMailLoad(false)
            if(parseInt(error)<0){
                //setMessage({error:error,message:errorMessage})
                localStorage.removeItem('token')
                setToken(false)  
            } else{
                setUpdateMessage({error:error, message:errorMessage, affected:1})
                localStorage.setItem('token',token)
                setToken(token)  
            }
        })
        
    }



    //console.log("search", search)
    const noData=<tr><td colSpan={columns.length}>Δεν υπάρχουν εγγραφές</td></tr>
    const loadData=<tr><td colSpan={columns.length}>Load...</td></tr>
    const tableFields = users && users.length>0 ? users.map((user, i)=>{
        //const gender = user.gender_id ? user.gender_id===21?'Άνδρας':'Γυναίκα':"-"
       // const age = user.birth_year? new Date().getFullYear() - parseInt(contact.birth_year) : '-'
        return <tr key={`pl_${user.id}`}>
            <td className="aa">{(search.page - 1) * search.rpp + (i+1)}</td>
            <td className="rj">{user.id}
                {parseInt(user.account_status) >=0 ? 
                <FontAwesomeIcon icon={['fas', 'user']}  className="icon-account" /> :
                <FontAwesomeIcon icon={['fas', 'user-slash']}  className="icon-account-disabled" />
                }
          
            </td>
            <td><Link to={`/user/${user.id}`} onClick={()=>storeLocal()}>{user.surname||'.....'} {user.first_name||'.....'}</Link></td>
            <td>{user.afm}</td>
            <td>{user.amka}</td>
            <td>{user.specialty}</td>
            <td>{user.tel}</td>
            {admin ?
                <td>{user.user_name}</td> :''}
            {admin ? 
                <td>
                {parseInt(user.account_status) >=0 ?
                    !mailLoad ?
                        <FontAwesomeIcon icon={['fas', 'paper-plane']}  className={allowMail ?"icon-sm-mail":"icon-sm-mail-off"} onDoubleClick={(e)=>sendMail(e, user.id)}/>
                        : mailLoad===user.id ? 
                            <FontAwesomeIcon icon={['fas', 'spinner']}   className="icon-sm-mail-s fa-spin"/>
                            :<FontAwesomeIcon icon={['fas', 'ban']}   className="icon-sm-mail-s"/>
                    :''
                }
                </td>
                :''
            }
        </tr>
    }):!users  ? loadData:noData
   
    return (
        <div className="list-container">
            
            <table className="list-comp">
                <thead>
                    <tr>
                        <td colSpan={columns.length} className="search-col">
                            <div className="search-form">
                                <div>
                                    <span>Χρήστες - Aναζήτηση </span><FontAwesomeIcon icon={['fas', 'eraser']}  className="icons icon-sm" onClick={(e)=>clearSearch(e)}/>
                                </div>
                                <ul>
                                {admin ?
                                <li>
                                    <label>Κατάσταση Λογαριασμού</label>
                                    <select name="account_status" value={search.account_status} onChange={(e)=>handleChange(e)} style={{fontSize:'1.2em',padding:'0.2em',color:'#634a97', backgroundColor:'#f8f8f8',border:'1px solid var(--bd-color)'}}>
                                        <option value={100}>Όλοι</option>
                                        <option value={1}>Ενεργοποιημένοι</option>
                                        <option value={-1}>Απενεργοποιημένοι</option>
                                    </select>
                                </li>
                                :''
                                }
                                <li>
                                    <label>Επώνυμο</label>
                                    <input type="text" name="surnameSearch" value={search.surnameSearch||''} onChange={(e)=>handleChange(e)}/>
                                </li>
                                <li><label>Όνομα</label>
                                    <input  type="text" name="firstNameSearch" value={search.firstNameSearch||''} onChange={(e)=>handleChange(e)} />
                                </li>
                                {/* <li>
                                    <label>Χρήστες</label>
                                    <select name="is_active" value={search.is_active} onChange={(e)=>handleChange(e)} style={{fontSize:'1.2em',padding:'0.2em',color:'#634a97', backgroundColor:'#f8f8f8',border:'1px solid var(--bd-color)'}}>
                                        <option value={100}>Όλοι</option>
                                        <option value={1}>Ενεργοί</option>
                                        <option value={0}>Ανενεργοί</option>
                                    </select>
                                </li> */}

                                
                                {/*
                                <li><label>Αρ. Φακέλλου</label>
                                    <input type="text" name="folderNumberSearch"  className="sm" value={search.folderNumberSearch||''} onChange={(e)=>handleChange(e)}/>
                                </li>
                                */}
                                {/*
                                <li style={{alignSelf:'end'}}>
                                    <FontAwesomeIcon icon={['fas', 'times']}  className="icons" />
                                </li>
                                */}
                                <li>
                                    <FontAwesomeIcon icon={['fas', 'search']}  className="icons" onClick={(e)=>getSearch(e)}/>
                                </li>
                                <li>
                                {admin ?
                                    <Link to={`/user/-1`} onClick={()=>storeLocal()}>
                                        <FontAwesomeIcon icon={['fas', 'user-plus']}  className="icons" />
                                    </Link>
                                    :''
                                }
                                </li>
                                </ul>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        {columnsHeader}
                    </tr>
                </thead>
                <tbody>
                    {tableFields}
                </tbody>
                <tfoot>
                   
                    <tr>
                        <td colSpan={columns.length}>
                            <ul>
                                <li>
                                    Εγγραφές / Σελίδα:
                                    <select name="rpp" value={search.rpp} onChange={(e)=>handleChange(e, true)}>
                                        {options}
                                    </select>
                                </li>
                                <li>
                                    {totalRows 
                                        ?
                                        `Βρέθηκαν: ${totalRows} εγγραφές. Σελίδα: ${search.page} / ${Math.ceil(totalRows/search.rpp)}` 
                                        :''
                                    }
                                     
                                </li>
                                <li>
                                    <Pagination totalRows={totalRows} currentPage={parseInt(search.page)} rpp={parseInt(search.rpp)} changePage={changePage}/>
                                </li>
                            </ul>
                        </td>
                    </tr>
                </tfoot>

            </table>


            {updateMessage.message ?<div className={`message-box ${getMessageClass(parseInt(updateMessage.error), parseInt(updateMessage.affected))}`}>{updateMessage.message}</div>:''} 
        </div>
        
    )
        
}

UserList.defaultProps={
    admin:false
}


export default UserList











/*


    

    const optionNum=[5,10,20,50,100,200,500]
    const columns=[
        {text:'#',cn:'aa'},
        {text:'ID',cn:'rj'},
        {text:'Ονοματεπώνυμο',cn:null},
        {text:'Ειδικότητα',cn:null},
        {text:'Όνμα χρήστη',cn:null},
        {text:'Λογαριασμός',cn:null},
        {text:'Τηλέφωνο',cn:null}
    ]
    const [search, setSearch]=useState(sessionStorage.getItem('usrsrch')?JSON.parse(sessionStorage.usrsrch):searchFieds)
    const [users, setUsers]=useState({users:[]})

    return (
        <div className="list-container">
            <h2>User list here...</h2>
        </div>
    )
}
*/
