import { library } from '@fortawesome/fontawesome-svg-core'
import { faEdit, faTrashAlt, faPlusCircle, faSearch, faTimes, 
        faUserPlus, faUserCheck, faUserMinus, faEraser, faSpinner, faEye, faEyeSlash, faCopy, faKey,
        faUser, faUserSlash, faSave,faPaperPlane, faBan, faExchangeAlt, faSyncAlt,
        faQuestionCircle, faExclamationTriangle,faMale, faFemale, faDownload,
        faCalendarWeek, faCalendarDay,faCalendarCheck, faClock,faUserNurse,faUserShield,faMobileAlt
        } from '@fortawesome/free-solid-svg-icons'
import  {faUser as farUser} from '@fortawesome/free-regular-svg-icons'

library.add(
    faEdit,
    faTrashAlt,
    faPlusCircle,
    faSearch,
    faTimes,
    faUserPlus,
    faEraser,
    faSpinner,
    faEye,
    faCopy,
    faKey,
    faSave,
    faEyeSlash,
    faUser,
    faUserSlash,
    faUserCheck,
    faPaperPlane,
    faBan,
    faExchangeAlt,
    faSyncAlt,
    faQuestionCircle,
    faExclamationTriangle,
    faMale,
    faFemale,
    faDownload,
    faCalendarWeek, 
    faCalendarDay,
    faCalendarCheck,
    faClock,
    faUserNurse,
    faUserShield,
    faMobileAlt,
    faUserMinus,
    farUser
   
)