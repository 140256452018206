import React, {useState} from 'react'
import {useDialog, ModalContent, ModalFooter} from 'react-st-modal'
import Select from 'react-select'
import DatePicker ,{registerLocale, setDefaultLocale} from 'react-datepicker'
import InputMask from 'react-input-mask'
import {el} from 'date-fns/locale/'
import '../style/modal.css'
import "react-datepicker/dist/react-datepicker.css"
import {selectComp} from '../utils/util'

registerLocale('el',el)
setDefaultLocale('el')

export default function HistoryDialogContent(props) {
    const dialog = useDialog()
    
    const {start_date, groupkey, data} = props.values
    const [value, setValue] = useState(data.length>0?data[0].option_id:null)
    const [startDate, setStartDate]=useState(start_date?start_date:null)
    const [des, setDes] = useState(data.length>0?data[0].des:null)
    const noOptionsText="Δεν υπάρχουν επιλογές"
    
    
    function handleSelectChange(e){
        //setValues(Array.isArray(e)? e.map(x=>x.value):[])
        setValue(e?e.value:null)
        setDes(null)

    }
    function handleChange(e){
        if(e.target.type==='radio'){
            
            setValue(parseInt(e.target.value))
            setDes(null)
        }else{
            setDes(e.target.value)
        }
    }
    let comp=''
    if(selectComp.includes(props.field)){
        const options =props.options.map(o=>({label:o.des, value:o.id}))

        const hasExtra =  props.options.find(o=>o.extra && value === o.id)
        const textFields = hasExtra && JSON.parse(hasExtra.extra).map((t,i)=>{
                return  <input key={`${props.field}_tdes_${i}`} type="text" name={t.field} value={des||''} className="details" style={{marginTop:'2rem'}} onChange={(e)=>handleChange(e)}/>
                //props.change(e, field, k)    
            })

        comp = <div>
            <Select 
                name={props.field}
                id={props.field}
                options={options} 
                isClearable={true}
                placeholder={'Επιλέξτε ...'}
                isMulti={false}
                //value={options.filter(x=>values.includes(x.value))}
                value={options.find(x=>x.value===value)}
                //filterOption={filterOption}
                onChange={handleSelectChange}
                noOptionsMessage={()=>noOptionsText}
                //className="sel-country"
                // formatOptionLabel={formatOptionLabel}
                //styles={customStyles}
            />
            {textFields}
        </div>

    }else{
        comp = props.options.map(opt=>{
            //console.log(value, ' - ', opt.id)
            const inpjson =  opt.extra && value === opt.id ? JSON.parse(opt.extra):null
            const textFields = inpjson && inpjson.map((t,i)=>{
                return  <input key={`${props.field}_tdes_${i}`} type="text" name={t.field} value={des} className="details" onChange={(e)=>handleChange(e)}/>
                //props.change(e, field, k)    
            })
            return(
                <label key={`${props.field}_${opt.id}`} className="modal-label">
                    <input name={props.field} type="radio" value={opt.id} checked={value === opt.id} onChange={(e)=>handleChange(e)}/> 
                    {opt.des}
                    {textFields}
                </label>
            )
           // props.change(e,field,k)

        })
    }
    function getDate(dateVar){
    //const  dateVal  = dateVar ? new Date(dateVar.getTime() + (dateVar.getTimezoneOffset() * 60000) + (3600000*4)):null
    //return dateVal ? dateVal.toJSON().substring(0,10):null
    return   dateVar ? dateVar.toLocaleDateString('fr-CA') :null
       //console.log(value , "--" , dateVal)

    }
    return (
        <>
            <div style={{minHeight:"70vh"}}>
            <ModalContent>
                <label htmlFor="start_date">Ημερομηνία</label>
                <DatePicker locale={el} name="start_date" id="start_date" 
                selected={startDate ? new Date(startDate):''} 
                //selected={startDate ? getDate(new Date(startDate)):''} 
                //onChange={date=>setStartDate(date?date.toJSON().substr(0,10):null)}
                onChange={date=>setStartDate(getDate(date))}
                dateFormat="dd / MM / yyyy"
                preventOpenOnFocus={true}
                isClearable={true}
                showMonthDropdown={true}
                showYearDropdown={true}
                adjustDateOnChange
                dropdownMode="select"
                className="date-input"
                customInput={<InputMask mask="99 / 99 / 9999"  maskPlaceholder="_"  alwaysShowMask={true} />}
                ></DatePicker>

                
                <h3>{props.title}</h3>
                {comp}
                
            </ModalContent>
            </div>
            <ModalFooter>
                <button className="return-btn" onClick={()=>{
                    const result = {option_id:value, start_date:startDate,des:des,groupkey}
                    dialog.close(result)}}>Κλείσιμο</button>
                <button className="return-btn" onClick={()=>{dialog.close(false)}}>Άκυρο</button>
            </ModalFooter>
        </>
    )
}
