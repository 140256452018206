import React, {useState, useEffect, useContext} from 'react'
//import { Link, useParams, useHistory} from "react-router-dom"
//import { AsyncPaginate } from 'react-select-async-paginate';
import DatePicker ,{registerLocale, setDefaultLocale} from 'react-datepicker'
import RendezvousDialog from './RendezvousDialog'
import {el} from 'date-fns/locale/'
import InputMask from 'react-input-mask'
//import {format} from 'date-fns'
import "react-datepicker/dist/react-datepicker.css"
import axiosAPI from '../services/axiosapi'
import "../style/rendezvousscheduler.css"
import Loader from './Loader';
import { AuthContext } from '../AuthContext'
//import Profile from './Profile'

function RendezvousScheduler(props) {
  
    registerLocale('el',el)
    setDefaultLocale('el')
    const appWeek = new Date().toLocaleDateString('fr-CA')
    const curDate = appWeek.split("-")

    const {userId} = props
    //console.log('user:', userId, ' ', 'city:', cityId)
    const searchFieds= {
      searchDate: appWeek, 
      userId:userId,
  }
  
  
    const {setToken}  = useContext(AuthContext)
    const [load, setLoad] = useState(false)
    const [showOver, setShowOver] = useState({status:false, x:0, y:0, app:{}})
    
    //const [week, setWeek] = useState(new Date(wdate||''))
   // const [week, setWeek] =useState(!isNaN(appWeek.getTime())?appWeek:new Date())
    const [weekPeriod, setWeekPeriod] =useState(null)
    const [weekTbl, setWeekTbl] = useState([])
    const [appointments, setAppointments]=useState([])
    const [search, setSearch]=useState(searchFieds)
    
    const daysOfWeekShort = ["ΔΕΥ", "ΤΡΙ","ΤΕΤ", "ΠΕΜ", "ΠΑΡ", "ΣΑΒ", "ΚΥΡ"];
    const monthsNameShort = ["", "ΙΑΝ", "ΦΕΒ", "ΜΑΡ", "ΑΠΡ", "ΜΑΙ", "ΙΟΥΝ", "ΙΟΥΛ", "ΑΥΓ", "ΣΕΠ", "ΟΚΤ", "ΝΟΕ", "ΔΕΚ"];
    
    
    const  weekDaysHeader = weekTbl.map((d,i)=>{
              //const curDate = `${d.year}-${d.month}-${d.day}`
              //const strDate = new Date(curDate).toLocaleDateString('fr-CA');
              const count = appointments.filter(a=> a.left === i).length
              return (
              <div className='flexdayshours' key={`wd_${i}`}>
                  <div className={`week-days ${checkCurrentDate(d.year,d.month, d.day, curDate) ? ' today':''}`}>  
                    {count ? <div className='rv-day-counter'>{count}</div>:''}
                    <div className='week-days-des'>{daysOfWeekShort[i]}</div>
                    <div className='week-days-num' >{d.day}</div>
                    <div className='week-days-month'>{monthsNameShort[parseInt(d.month)]}</div> 
                  </div>
              </div>  
              )})

    const appointmentContent = appointments.map((a,i) =>{
        const h = Math.floor(parseInt(a.duration) * 100/60)
        const left = parseInt(a.left)
        const top=Math.floor(parseInt(a.top)*100/60)
      return (
      <div key={`ap_${i}`} className={`appointment`}  style={{height:`${h}px`,left:`calc(14.28% * ${left} + 0vw )`, transform: `translateY(${top}px)`, borderBottom:`${a.color ? `0.4rem solid ${a.color}`:'1px solid #6399ae'}` }}
        onMouseOver={(e)=>handleOver(a, e)}
        onMouseOut={()=>setShowOver({status:false, x:0,y:0,app:{}})}
      >
          <div className='app-time'>
            {`${a.start_time} - ${a.end_time} (${a.duration} min)`}
          </div>
          <div className='app-room'>
            {a.room_name}
          </div>
          <div>
            {a.tool || '-'}
          </div>
          <div className='app-profile'>
            <span>{a.folder_number}</span>
            {a.profile_name}
          </div>
      </div>)
    })

    function handleOver(app, ev){
        const xPos = ev.pageX + 350 > window.innerWidth ?  window.innerWidth - 350 - 100 :ev.pageX - 50
        const yPos = ev.pageX + 350 > window.innerWidth ?  ev.pageY -75 :ev.pageY -75
        setShowOver({status:true, x:xPos, y:yPos, app:app})
    }
  
    function changeWeek(e, field){
        //console.log("chWeek:",field, " date:",e)
        const dateVal = e ? e.toLocaleDateString('fr-CA') :null
        setSearch(prev=>({...prev,[field]:dateVal}))
    }

    function checkCurrentDate(y,m,d, cd){
      return  parseInt(cd[0]) === parseInt(y) && parseInt(cd[1]) === parseInt(m) && parseInt(cd[2]) === parseInt(d)  
    }
  
  /*
    const loadOptions = async (searchContact, prevOptions) => {
      const tkn=localStorage.getItem('token')||false
      const headers= {
        'Content-Type':'application/json',
        'Authorization': `Bearer ${tkn}`,
      }
      const offset = prevOptions.length
      //console.log("offset", offset)
      try{
          const response  = await axiosAPI.post("profile.php",{searchContact,offset}, {headers})
          const {error} = response.data
          if(error===0){
              const {options, hasMore} = response.data
              return {
                  options,
                  hasMore
              }
          }
      }catch(err){
          console.log(err)
      }
  
      return {
          options:[],
          hasMore:false
      }
    }
  */
    useEffect(() => {
      setLoad(true)
      //const weekDate = week ? week.toLocaleDateString('fr-CA') :null
      
      if(search?.searchDate){
          const tkn=localStorage.getItem('token')||false
          const headers= {
          'Content-Type':'application/json',
          'Authorization': `Bearer ${tkn}`,
          }
          axiosAPI.post("appointment.php",{search, mode:"rvweek"}, 
          {headers})
          .then(response=>{
              setLoad(false)
              const {error,  token} = response.data
              //console.log(response.data)
              if(parseInt(error)<0){
                  //setUpdateMessage({error:error,message:errorMessage,affected:0})
                  localStorage.removeItem('token')
                  setToken(false)
              }else if (parseInt(error)===0){
                  const {weekPeriod, week, appointments} = response.data
                  setAppointments(appointments)
                  setWeekPeriod(weekPeriod)
                  setWeekTbl(week)
                  
                  //setOptions(metadata)
                  localStorage.setItem('token',token)
                  setToken(token)
              }else{
                  //setIsLoad(true)
              // setUpdateMessage({error:error,message:errorMessage,affected:0})
                  localStorage.setItem('token',token)
                  setToken(token)
              }
          })
      }else{
          setLoad(false)
      }
  
      /*
      let t = setTimeout(()=>{
          setLoad(false)
          console.log("week Date:" , weekDate)
      }, 500)
      return  ()=>{
              clearTimeout(t)
          }
          */
    }, [search, setToken])
    
    useEffect(() => {
      sessionStorage.setItem('appsearch', JSON.stringify(search))
  }, [search])
  
  
    
    return (
      load ? 
        <Loader />
      :
        <>
            <div className='schdl-container'>
                <ul className='schdl-control'>
                    <li>
                        <span>Εβδομαδα </span>
                            <DatePicker 
                            locale={el}
                            selected={search.searchDate ? new Date(search.searchDate):''}
                            dateFormat="dd / MM / yyyy"
                            preventOpenOnFocus={true}
                            isClearable={true}
                            showMonthDropdown={true}
                            showYearDropdown={true}
                            adjustDateOnChange
                            dropdownMode="select"
                            customInput={<InputMask mask="99 / 99 / 9999"  maskPlaceholder="_"  alwaysShowMask={true} />}
                            onChange={(e)=>changeWeek(e, "searchDate")}
                            className="date-input-search-app"
                            placeholderText='εβδομαδα'
                            />
                    
                    </li>
                    <li>
                        {weekPeriod && weekPeriod.split('-').length===2 ? <><div>{`${weekPeriod.split('-')[0]}`}</div><div>{`${weekPeriod.split('-')[1]}`}</div></>:''}
                    </li>
                    <li style={{width:'15%'}}>
                        
                    </li>
                    <li style={{width:'15%'}}>
                        
                    </li>
                    <li style={{width:'15%'}}>
                        
                    </li>
                    <li style={{width:'15%'}}>
                        
                    </li>
                </ul>
                {/*
                <div>
                    <AsyncPaginate
                        value={profile}
                        onChange = {setProfile}
                        loadOptions = {loadOptions}
                        isClearable={true}
                        styles={{
                            // Fixes the overlapping problem of the component
                            menu: provided => ({ ...provided, zIndex: 8 })
                        }}
                    />
        
                </div>
                */}
                <div className='schdl-flexcol-container'>
                    <div className='schdl-flexrow head-days'>
                        <div className='flexhours'>
                            <div className='nb'></div>
                            <div></div>
                        </div>
                        <div className='days-hours'>
                            <div className='flexdayshours-container'>
                                {weekDaysHeader}
                            </div>
                        </div>
        
                    </div>
        
                    <div className='schdl-flexrow'>
                        <div className='flexhours'>
                            <div>09:00</div>
                            <div></div>
                            <div>10:00</div>
                            <div></div>
                            <div>11:00</div>
                            <div></div>
                            <div>12:00</div>
                            <div></div>
                            <div>13:00</div>
                            <div></div>
                            <div>14:00</div>
                            <div></div>
                            <div>15:00</div>
                            <div></div>
                            <div>16:00</div>
                            <div></div>
                            <div>17:00</div>
                            <div></div>
                            <div>18:00</div>
                            <div></div>
                            <div>19:00</div>
                            <div></div>
                        </div>
                        
                        <div className='days-hours'> {/*data appointment content here.... */}
                            <div className='flexdayshours-container'>
                                <div className='flexdayshours'>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                                <div className='flexdayshours'>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                                <div className='flexdayshours'>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                                <div className='flexdayshours'>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                                <div className='flexdayshours'>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div ></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                                <div className='flexdayshours'>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                                <div className='flexdayshours'>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                                
                            </div>
                            {appointmentContent}
                        </div>
                    
                    </div>
                </div>
            </div>
            {showOver.status && <RendezvousDialog rvdata={showOver}/>}
        </>
    )
  }

export default RendezvousScheduler