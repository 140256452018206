import React from 'react'
import {Link} from 'react-router-dom'
import '../style/contextmenu.css'

function ContextMenu(props) {
  const {app} = props
  //console.log('room:',app.room_id, 'Ps:',app.participants, 'st', app.start_time)
  //console.log(app)

  function handleClick(app, ev){
    const newAppointment = {
        room_id:app.room_id,
        start_time:app.start_time,
        profile_id:app.profile_id,
        participants:app.participants,
        tool:app?.tool||null,
        subject:app?.subject||null
    }
    //sessionStorage.setItem("rvid",-1)
    sessionStorage.setItem('newrv',JSON.stringify(newAppointment))
  }
  return (
    <div className='context-menu' style={{top:app.y, left:app.x}}>
        <Link className='context-menu-link' to={`/appointment/newrv`} onClick={(e)=>handleClick(app, e)}>
            Νέο Ραντεβού
        </Link>
    </div>
  )
}

export default ContextMenu