import React, {useState} from 'react'
import {useDialog, ModalContent} from 'react-st-modal'
import CheckBoxField from './CheckBoxField'
import "../style/modal.css"

function ActionDialogContent(props) {
    const dialog = useDialog()
    const [values, setValues] = useState(props.values)

    function handleChange(e, id){
        if(e.target.type==='checkbox'){
          const newArr =!e.target.checked ?
          values.filter(item => item.option_id!==parseInt(e.target.value))
          :
          [...values, { option_id:parseInt(e.target.value),des:null}]
          setValues(newArr)
        } else{
          const newArr = values.map(item => item.option_id===id ?{...item, [e.target.name]:e.target.value||null}:item)
          setValues(newArr)
        }
        
    }
    return (
    
            <div>
                <ModalContent>
                    <CheckBoxField  title={props.title} field={props.field} values={values} options={props.options} change={handleChange}/>
                </ModalContent>
                <div className="modalfooter">
                    <button className="return-btn" onClick={()=>{dialog.close(values)}}>Κλείσιμο</button>
                    <button className="return-btn" onClick={()=>{dialog.close(false)}}>Άκυρο</button>
                </div>
           </div>
      
    )
}

export default ActionDialogContent
