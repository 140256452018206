import React, {useState} from 'react'
import {useDialog, ModalContent, ModalFooter} from 'react-st-modal'
import Select from 'react-select'
//import DatePicker ,{registerLocale, setDefaultLocale} from 'react-datepicker'
//import InputMask from 'react-input-mask'
//import {el} from 'date-fns/locale/'
import '../style/modal.css'
import "react-datepicker/dist/react-datepicker.css"

//registerLocale('el',el)
//setDefaultLocale('el')

export default function HistoryDialogContent(props) {
    const dialog = useDialog()
    const {data}=props
    const options = props.options.map(o=>({label:o.des, value:o.id}))
    const [familyMember, setFamilyMember]=useState(data)
    const noOptionsText="Δεν υπάρχουν επιλογές" 

    function handleChange(ev){
        const{name, value} = ev.target
        if(name==='age'){
            const by = ev.target.value ? new Date().getFullYear() - value : null
            setFamilyMember(prev=>({...prev, birth_year:by}))
        }else{
            setFamilyMember(prev=>({...prev, [name]:value}))
        }
    }
    function handleSelectChange(e){
        //setValues(Array.isArray(e)? e.map(x=>x.value):[])
        setFamilyMember(prev=>({...prev, gender_id:e?e.value:null}))
    }

    return(
        <div >
            <ModalContent>
            {/*
                field==='brothers'?
                <>
                <label htmlFor="fml_name">Όνομα</label>
                <input id="fm_name" type="text" name="name" value={familyMember.name||''} onChange={(e)=>handleChange(e)}/>
                </>:''
            */}
                <label htmlFor="fml_name">Όνομα</label>
                <input id="fm_name" type="text" name="name" value={familyMember.name||''} onChange={(e)=>handleChange(e)}/>
                <label htmlFor="fml_age">Ηλικία</label>
                <input type="text" id="fml_age" name="age" value={familyMember.birth_year ? new Date().getFullYear() - familyMember.birth_year : ''} onChange={(e)=>handleChange(e)} />
                <label htmlFor="fml_gender_id">Φύλο</label>
                <Select 
                    name="gender_id"
                    id="fml_gender_id"
                    options={options} 
                    isClearable={true}
                    placeholder={'Επιλέξτε ...'}
                    isMulti={false}
                    //value={options.filter(x=>values.includes(x.value))}
                    value={options.find(x=>x.value===familyMember.gender_id)}
                    //filterOption={filterOption}
                    onChange={handleSelectChange}
                    noOptionsMessage={()=>noOptionsText}
                    className="sm-select"
                    // formatOptionLabel={formatOptionLabel}
                    //styles={customStyles}
                />
            </ModalContent>
            <ModalFooter className="modalfooter">
                <button className="return-btn" onClick={()=>{
                    const result = familyMember
                    dialog.close(result)}}>Close</button>
                <button className="return-btn" onClick={()=>{dialog.close(false)}}>Cancel</button>
            </ModalFooter>
        </div>
    )
}

