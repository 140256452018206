import React, {useState,useContext,useEffect, useRef} from 'react'
import { AuthContext } from '../AuthContext'
import DatePicker ,{registerLocale, setDefaultLocale} from 'react-datepicker'
import Select from 'react-select'
import { AsyncPaginate } from 'react-select-async-paginate'
import InputMask from 'react-input-mask'
import { Link } from 'react-router-dom'
import axiosAPI from '../services/axiosapi'
import '../utils/fontawesome'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {getMessageClass} from "../utils/util"
import RendezvousList from './RendezvousList'
import RendezvousDay from './RendezvousDay'
import "react-datepicker/dist/react-datepicker.css"
import {el} from 'date-fns/locale/'
import "../style/profilelist.css"
import '../style/rendezvous.css'
//import { setSeconds } from 'date-fns'

function RendezvousSearch(props) {
    registerLocale('el',el)
    setDefaultLocale('el')
    const noOptionsText="Δεν υπάρχουν επιλογές"
    const brandColor = 'var(--bd-color)'

    const { setToken}  = useContext(AuthContext)
    const blankFields= {
        appDateStartSearch: new Date().toLocaleDateString('fr-CA'), 
        appDateEndSearch:null, 
        profileIdSearch:null,
        roomSearch:[],
        searchParticipants:props.restrict ? [props.restrict]:[],
        resultSearch:[],
        toolSearch:null,
        subjectSearch:null,
        rpp:50,
        page:1,
        searchMode:0
    }
    const searchFields = !props.restrict && sessionStorage.getItem('rvsearch') ? JSON.parse(sessionStorage.rvsearch) : blankFields;
    const [presentation, setPresentation] = useState(sessionStorage.getItem('rvprs') || 'LST')                      
    const [search, setSearch]=useState(searchFields)
    const [appid] = useState(sessionStorage.getItem('rvid')||null)
    const [appointments, setAppointments]=useState(null)
    const [rendezvousDay, setRendezvousDay]=useState(null)
    const [occupiedParticipants, setOccupiedParticipants] = useState(null)
    const [occupiedTools, setOccupiedTools] = useState(null)
    const [occupiedProfile, setOccupiedProfile] = useState(null)
    const [workingHours, setWorkingHours] = useState(null)
    const [totalRows, setTotalRrows] =useState(0)
    const [go, setGo]=useState(0)
    const [updateMessage,setUpdateMessage]=useState({error:0,message:'', affected:0})
    const [roomList, setRoomList]=useState([])
    const [userList, setUserList]=useState([])
    const [metadata, setMetadata]=useState([])
     

    const timer = useRef(null)

    const roomOptions = roomList.map(r=>({value:r.id, label:r.room_name}))
    const userOptions = userList.map(u=>({value:u.id, label:`${u.fullname}`, is_active:u.is_active}))
    const resultOptions = metadata.filter(m=>m.field==='appointment_result_id').map(r=>({value:r.id, label:r.des}))
    const toolOptions = metadata.filter(m=>m.field==='appointment_tool_id').map(r=>({value:r.id, label:r.des}))
    const subjectOptions = metadata.filter(m=>m.field==='appointment_subject_id').map(r=>({value:r.id, label:r.des}))

    useEffect(() => {  
            setGo(-1)
            return () => {
                if(timer.current){
                    clearTimeout(timer.current)
                }
          }
    },[])

    useEffect(() => {
        if(updateMessage.message){
            const err = parseInt(updateMessage.error) === 0 ? 1000:5000
            timer.current = setTimeout(()=>{
                setUpdateMessage({error:0,message:"",affected:0})
            },err)
        }
        return () => {
              clearTimeout(timer.current)
        }
    }, [updateMessage])

    useEffect(() => {
        //console.log("go")
        if(go){
            if(go===2){
               window.scrollTo(0,0)
            }
            //sessionStorage.setItem('rvsearch', JSON.stringify(search))
            const tkn=localStorage.getItem('token')||false
            const headers= {
                'Content-Type':'application/json',
                'Authorization': `Bearer ${tkn}`,
              }
              //console.log('search:', search)
              
                //console.log('go: ', go)
              
              const postParams = {
                search:search, 
                mode:presentation,
                listOptions: go===-1 ? true : false
              }
            axiosAPI.post("appointment.php",postParams,{headers})
            .then(response=>{
                const {
                    appointments,
                    rendezvousDay, 
                    occupiedParticipants, 
                    workingHours,
                    occupiedTools, 
                    occupiedProfile, 
                    error, 
                    token
                } = response.data
                if(parseInt(error) < 0){
                    localStorage.removeItem('token')
                    setToken(false)
                }else if(parseInt(error) === 0){
                    if(go===-1){
                        const {userList, rooms, metadata} = response.data
                        setUserList(userList)
                        setRoomList(rooms)
                        setMetadata(metadata)
                    }
                    setAppointments(appointments)
                    setRendezvousDay(rendezvousDay)
                    setOccupiedParticipants(occupiedParticipants || null)
                    setOccupiedTools(occupiedTools || null)
                    setOccupiedProfile(occupiedProfile || null)
                    setWorkingHours(workingHours||null)

                    //const goVar = go
                    setGo(0)
                    //setSearch(prev=>({...prev, "page":response.data.page}))

                    setTotalRrows(response.data.totalRows)
                    localStorage.setItem('token',token)
                    setToken(token)
                    if(Math.abs(go)===1)
                        handleScroll()
                    setGo(0)
                }else{
                    setGo(0)
                    const{error, errorMessage}=response.data
                    setUpdateMessage({error:error,message:errorMessage,affected:0})
                    setToken(token)
                }
                
            })
        }
    },[go, search, setToken, presentation])



    useEffect(() => {
        sessionStorage.setItem('rvsearch', JSON.stringify(search))
    }, [search])

    function handleScroll(){
        const position = sessionStorage.getItem("rvpos")
        if(position){
            timer.current = setTimeout(()=>{
                window.scrollTo(0,parseInt(position))
                sessionStorage.removeItem("rvpos")
            },0)  
        }
    }

    function asyncSelectChange(e){
        console.log(e)
        setSearch(prev =>({...prev,profileIdSearch:e}))
    }

    function searchMode(ev){
        const {name, value} =ev.target
        setSearch(prev=>({...prev,[name]: parseInt(value) === 0 ? 1:0}))
    }

    function deleteAppointment(id){
        const tkn=localStorage.getItem('token')||false
        const headers= {
            'Content-Type':'application/json',
            'Authorization': `Bearer ${tkn}`,
        }
        axiosAPI.post("appointment.php",{mode:"DELAPT", appointmentId:id},{headers})
        .then(response=>{
            const {error, message, affected, token}=response.data
            if(parseInt(error)<0){
                setUpdateMessage({error,message,affected:affected})
                localStorage.removeItem('token')
                setToken(false)
              }else{
                localStorage.setItem('token',token)
                setToken(token)
                setUpdateMessage({error,message,affected})
                storeLocal(id)
                setGo(1)
              }
        })  
    }

    function getSearch(ev){
        setGo(0)
        setPresentation('LST')
        setSearch(prev=>({...prev, page:1}))
        sessionStorage.setItem('rvprs', 'LST')
        setGo(2)
        
     }

     function getDaySearch(ev){
        setGo(0)
        setPresentation('CDR')
        sessionStorage.setItem('rvprs', 'CDR')
        setGo(2)

     }
     function clearSearch(ev){
         //setSearch(initSearch())
         setSearch(blankFields)
         sessionStorage.removeItem('rvsearch')
     }

    function changePage(ev){
        const {value}=ev.target
        setSearch(prev=>({...prev,page:value}))
        setGo(2)
        
    }

     function storeLocal(id){
        if(!props.restrict){
            sessionStorage.setItem('rvpos', window.scrollY)
            sessionStorage.setItem('rvsearch', JSON.stringify(search))
            sessionStorage.setItem("rvid",id )
        }
        //sessionStorage.setItem('rvprs', presentation)
    }

    function handleChange(ev, goSearch=false){
        
        const {name,value}=ev.target
        //console.log(name, value)
        //const  res =  name==="folderNumberSearch" && isNaN(value) ? null :value||null
        if(name==='rpp'){
            setSearch(prev=>({...prev,[name]:value, page:1}))
        }else{
            setSearch(prev=>({...prev,[name]:value}))
        }
        if(goSearch){
            //console.log("ok")
            setGo(2)
        }
        
    }

    function handleChangeDate(field, value){ 
        const  dateVal  = value ? value.toLocaleDateString('fr-CA') :null
        setSearch(prev => ({...prev,[field]:dateVal}))
    }

    function handleMultiSelectChange(e,field){
        setSearch(prev=>({...prev, [field]:Array.isArray(e)? e.map(x=>x.value):[]}))
        //console.log(search)
    }

    function handleSelectChange(e, field){
        //console.log(field,e && e.value?parseInt(e.value):null )
        setSearch(prev=>({...prev, [field]:e && e.value?parseInt(e.value):null}))
    }

    const loadOptions = async (searchContact, prevOptions) => {
        const tkn=localStorage.getItem('token')||false
        const headers= {
          'Content-Type':'application/json',
          'Authorization': `Bearer ${tkn}`,
        }
        const offset = prevOptions.length
        //console.log("offset", offset)
        try{
            const response  = await axiosAPI.post("profile.php",{searchContact,offset}, {headers})
            const {error} = response.data
            if(error===0){
                const {options, hasMore} = response.data
                return {
                    options,
                    hasMore
                }
            }
        }catch(err){
           // console.log(err)
        }
    
        return {
            options:[],
            hasMore:false
        }
    }


    
    function  filterOption (option, inputValue){
        return inputValue ?
                !option.label.substring(0,inputValue.length).localeCompare(inputValue, 'el-GR',{sensitivity:'base'}): true
    }
    
    function  filterOptionFull (option, inputValue){
        //console.log(option)
        const translate={ά:'α', έ:'ε',ή:'η',ί:'ι',ϊ:'ι',ΐ:'ι',ό:'ο',ύ:'υ',ϋ:'υ',ΰ:'υ', ώ:'ω',ς:'σ', ü :'u', é:'e', ä:'a'}
        const pattern = /[άέήίϊΐόύϋΰώςüéä]/g
        const optionStr = option.data.label.trim().toLowerCase().replace(pattern, match=>translate[match])
        const inputStr = inputValue ? inputValue.trim().toLowerCase().replace(pattern, match=>translate[match]) :''
        //console.log(option)
        return inputValue ? optionStr.includes(inputStr) : true
      }

    function formatOptionLabel({ label, folder}){
        return <>
            <div>{label}</div>
            <span className='opt-label'>{folder || '-'}</span> 
            
            </>
    }
    
    function formatUserOptionLabel({ label, is_active}){
        return <>
                <FontAwesomeIcon icon={['far','user']}  style={{float:'right',fontSize:'0.7rem',marginLeft:'0.2rem',color:`${is_active? 'var(--dec)':'#800000'}`}} />
                {label}
            </>
    }

    const customStyles = {
        control: (base, state) => ({
            ...base,
            maxHeight: '90px',
            overflowY: 'auto',
            paddingTop:'0.1em',
            paddingBottom:'0.1em',
            
            boxShadow: state.isFocused ? 0 : 0,
            borderColor: state.isFocused
            ? brandColor
            : base.borderColor,
            '&:hover': {
            borderColor: state.isFocused
                ? brandColor
                : base.borderColor,
            },
        }),
        menu: provided => ({ ...provided, zIndex: 8 }),
        multiValueLabel: (styles) => ({
            ...styles,
            color:'#000',backgroundColor:'whitesmoke',fontSize:'1em', padding:'0.5em'
        }),
    }

  return (
    <div className="list-container">
        <div className='rv-search'>
            <div className='rv-search-container'>
                <div className="rv-top-search">
                    <span>Ραντεβού - Aναζήτηση </span>
                    <button name="searchMode" value={search.searchMode} onClick={(e)=>searchMode(e)}>{search.searchMode === 0?'Ημέρας':'Διαστήματος'}</button>
                    <label htmlFor='appDateStartSearch' className='rv-search-lbl'>Ημ/νία {parseInt(search.searchMode)===0?'':'Από'}</label>
                    <DatePicker locale={el} name="appDateStartSearch" id="appDateStartSearch" 
                        selected={search.appDateStartSearch ?new Date(search.appDateStartSearch):''} 
                        onChange={(date)=>handleChangeDate('appDateStartSearch',date)}
                        dateFormat="dd / MM / yyyy"
                        preventOpenOnFocus={true}
                        showMonthDropdown={true}
                        showYearDropdown={true}
                        adjustDateOnChange
                        dropdownMode="select"
                        className="date-input-search-app"
                        isClearable={search.searchMode}
                        showWeekNumbersd
                        customInput={<InputMask mask="99 / 99 / 9999"  maskPlaceholder="_"  alwaysShowMask={true} />}
                    ></DatePicker>
                    { search.searchMode 
                    ? <>
                        <label htmlFor='appDateStartSearch' className='rv-search-lbl'>Ημ/νία Έως</label>
                        <DatePicker locale={el} name="appDateEndSearch" id="appDateStartSearch" 
                            selected={search.appDateEndSearch ?new Date(search.appDateEndSearch):''} 
                            onChange={(date)=>handleChangeDate('appDateEndSearch',date)}
                            dateFormat="dd / MM / yyyy"
                            preventOpenOnFocus={true}
                            isClearable={true}
                            showMonthDropdown={true}
                            showYearDropdown={true}
                            adjustDateOnChange

                            dropdownMode="select"
                            className="date-input-search-app"
                            customInput={<InputMask mask="99 / 99 / 9999"  maskPlaceholder="_"  alwaysShowMask={true} />}
                        ></DatePicker>
                    </>
                    :''
                    }
                    {
                    !props.restrict ?
                    <>
                        <FontAwesomeIcon icon={['fas', 'eraser']}  className="icons icons-app" onClick={(e)=>clearSearch(e)}/>
                        <FontAwesomeIcon icon={['fas', 'search']}  className="icons-app" onClick={(e)=>getSearch(e)} style={{marginLeft:'2rem'}}/>
                        {/* calendar day */}
                        {search.searchMode===0 &&<FontAwesomeIcon icon={['fas', 'calendar-day']}  className="icons-app" onClick={(e)=>getDaySearch(e)}/>}
                        
                        
                        <Link to={`/appointment/new`} onClick={()=>storeLocal(-1)} style={{marginLeft:'auto'}}>
                            <FontAwesomeIcon icon={['fas', 'plus-circle']}  className="icons-app" />
                        </Link>
                    </>:
                        <FontAwesomeIcon icon={['fas', 'search']}  className="icons-app" onClick={(e)=>getSearch(e)} style={{marginLeft:'2rem'}}/>
                    }
                </div>
                {
                !props.restrict ?
                <>
                    <ul className='rv-search-ul'>
                        <li>
                            <Select 
                                name='roomSearch' 
                                id='roomSearch'
                                options={roomOptions} 
                                isClearable={true}
                                placeholder={'Χώροι Επιλέξτε ...'}
                                isMulti={true}
                                value={roomOptions.filter(x=>search.roomSearch.includes(x.value))}
                                onChange={e=>handleMultiSelectChange(e, 'roomSearch')}
                                noOptionsMessage={()=>noOptionsText}
                                className="sel-part-4"
                                styles={customStyles}
                                menuPlacement='auto'
                                filterOption = {filterOption}
                                style={{width:'400px'}}
                            />
                        </li>
                        <li>
                            <Select 
                                name='searchParticipants' 
                                options={userOptions} 
                                isClearable={true}
                                placeholder={'Συμμετέχοντες Επιλέξτε ...'}
                                isMulti={true}
                                value={userOptions.filter(x=>search.searchParticipants.includes(x.value))}
                                onChange={e=>handleMultiSelectChange(e, 'searchParticipants')}
                                noOptionsMessage={()=>noOptionsText}
                                className="sel-part-4"
                                styles={customStyles}
                                menuPlacement='auto'
                                filterOption = {filterOptionFull}
                                formatOptionLabel = {formatUserOptionLabel}
                                id='searchParticipants'
                            />
                        </li>
                        <li>
                            <Select 
                                name='resultSearch' 
                                options={resultOptions} 
                                isClearable={true}
                                placeholder={'Αποτέλεσμα Επιλέξτε ...'}
                                isMulti={true}
                                value={resultOptions.filter(x=>search.resultSearch.includes(x.value))}
                                onChange={e=>handleMultiSelectChange(e, 'resultSearch')}
                                noOptionsMessage={()=>noOptionsText}
                                className="sel-part-4"
                                styles={customStyles}
                                menuPlacement='auto'
                                filterOption = {filterOption}
                                
                            />
                        </li>
                    </ul>
                    <ul className='rv-search-ul'>
                        <li>
                            <AsyncPaginate
                                value={search?.profileIdSearch || null}
                                onChange = { e => asyncSelectChange(e)}
                                loadOptions = {loadOptions}
                                isClearable={true}
                                styles={customStyles}
                                placeholder={'Αναζητηση ωφελούμενου ...'}
                                noOptionsMessage={()=>noOptionsText}
                                className="sel-part-4"
                                formatOptionLabel={formatOptionLabel}
                            />  
                        </li>
                        <li>
                            <Select 
                                name='toolSearch' 
                                options={toolOptions} 
                                isClearable={true}
                                placeholder={'Εργαλεία Επιλέξτε ...'}
                                isMulti={false}
                                value={toolOptions.find(x=>parseInt(search.toolSearch) === parseInt(x.value)) || null}
                                onChange={e=>handleSelectChange(e, 'toolSearch')}
                                noOptionsMessage={()=>noOptionsText}
                                className="sel-part-4"
                                styles={customStyles}
                                menuPlacement='auto'
                                filterOption = {filterOption}
                            
                            />
                        </li>
                        <li>
                            <Select 
                                name='subjectSearch' 
                                options={subjectOptions} 
                                isClearable={true}
                                placeholder={'Θέμα Επιλέξτε ...'}
                                isMulti={false}
                                value={subjectOptions.find(x=>parseInt(search.subjectSearch) === parseInt(x.value)) || null}
                                onChange={e=>handleSelectChange(e, 'subjectSearch')}
                                noOptionsMessage={()=>noOptionsText}
                                className="sel-part-4"
                                styles={customStyles}
                                menuPlacement='auto'
                                filterOption = {filterOption}
                            
                            />
                        </li>
                    </ul>
                </>
                :  
                '' 
                }


            </div>
        </div>
        {presentation === 'LST' ?
            <RendezvousList 
                appointments={appointments}
                deleteAppointment={deleteAppointment}
                handleChange={handleChange}
                appid={appid}
                search={search}
                totalRows={totalRows}
                changePage={changePage}
                storeLocal={storeLocal}
            />
        :
            <RendezvousDay 
                appointments={rendezvousDay}
                occupiedParticipants={occupiedParticipants}
                occupiedTools={occupiedTools}
                occupiedProfile={occupiedProfile}
                workingHours={workingHours}
                search={search}
                rooms={roomList}
            />
        }
        { 
            updateMessage.message ? 
            <div className={`message-box ${getMessageClass(updateMessage.error, updateMessage.affected)}`}>{updateMessage.message}</div>
            :
            ''
        } 

    </div>

  )
}
RendezvousSearch.defaultProps={
    restrict:false
}

export default RendezvousSearch