import React from 'react'

function CheckBoxField(props) {
   // const {metadata} = props.profile
    //const values=props.profile[props.field]
   
   // const checkBoxArray = metadata.filter(item=>item.field===props.field)
    
    const fieldsArray=props.options.map(item =>{
            const extra=item.extra?JSON.parse(item.extra):[]
            const extraFields = extra.map((i, j)=>{
                const value =props.values.find(v=>v.option_id===item.id)
                return value ? 
                    <input type="text" className="details" key={`t${j}`} name={i.field} value={value[i.field]===null?'':value[i.field]} onChange={(e)=>props.change(e,item.id, props.field)}/>
                    :''
            })
        return <li key={item.id}>
                <label htmlFor={`${props.field}_${item.id}`} className="lbl-chk">
                    {item.des}
                    <input 
                        id={`${props.field}_${item.id}`}
                        type="checkbox" 
                        name={props.field} 
                        value={item.id} 
                        checked={props.values.find(v=>parseInt(v.option_id)===parseInt(item.id))?true:false}
                        onChange={(e)=>props.change(e, item.id, props.field)}
                    /> 
                    <span className="checkmark"></span>
                </label>
                {extraFields}
            </li>
    })
        
    return (
        <>
            <h3>{props.title}</h3>
            <ul>
                {
                    fieldsArray
                }
            </ul>
        </>
    )
}

export default CheckBoxField

//defaultValue={item.id} 