import React from "react"
import '../utils/fontawesome'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '../style/radiofield.css'
import {createContent} from "../utils/util"

function RadioField(props){
    const {title, field}= props
    const { metadata} = props.profile
    const obj = props.profile[props.obj]
    
    const {labelTitle='', currentValue='',fieldName='', extraFields=[],options=[]} = createContent(title, field,  obj, metadata)
    const allFields=[...extraFields]
    allFields.push(field)
    const radioBox =  options.map(item=>{
        const shortClass = item.extra.length > 1?'short':''
        const textBox = item.extra.map((textItem, i)=>(
            parseInt(currentValue) === parseInt(item.value) ? <input key={`t${i}`} type="text" className={`details ${shortClass}`} name={textItem.field} value={obj[textItem.field]||""} placeholder={textItem.label} onChange={(e)=>props.change(e)} /> :''
        ))
        const elementId=`${fieldName}_${item.value}`
        return (
            <li key={item.value}>
                <label  htmlFor={elementId} className="lbl-inline">
                <input 
                    type="radio" 
                    name={fieldName} 
                    value={item.value||""}
                    id={elementId}
                    checked={ parseInt(item.value)=== parseInt(currentValue)} 
                    onChange={(e)=>props.change(e,extraFields)}
                />
                {item.des}
                </label>
                {textBox}
                
            </li>
        )
    }) 
     
    return(
        <>
        <h3>{labelTitle}</h3>
        <ul>
            {radioBox}
            <li>
                <FontAwesomeIcon icon={['fas', 'eraser']} className="icons icon-sm"  onClick={(e)=>props.click(e, allFields)}/>clear
                {/*<label className="lbl-clear" onClick={(e)=>props.click(e, allFields)} >clear</label>*/}
            </li>
        </ul>
        </>
    )
}

export default RadioField
/*
 {
    title:'Πηγή παραπομπής/Ενημέρωσης',
    currentValue:1,
    options:[
        {value:1, des:"Οικογένεια" ,extra:[]},
        {value:2, des:"Φίλος/Γνωστός" ,extra:[]},
        {value:3, des:"Άλλος ωφελούμενος της ΚΜΨΥ" ,extra:[]},
        {value:4, des:"Γενικό Νοσοκομείο" ,extra:[{textName:"info_source_des", textValue:""}, {textName:"info_source_details", textValue:""}]},
        {value:5, des:"Ψυχιατρικό Νοσοκομείο " ,extra:[{textName:"info_source_des", textValue:""}]}
    ]
}
*/
