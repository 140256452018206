import Axios from "axios"

const BASE_URL='https://crm.epipsi.eu/api/'
//const BASE_URL='http://localhost/epipsi/api/'

const axiosAPI=Axios.create({
    baseURL: BASE_URL,
    headers:{
        'Content-Type':'application/json',
    },
})

export const axiosDL = Axios.create({
    baseURL:BASE_URL
})

export default axiosAPI