import React from 'react'
import Select from 'react-select'
import {country2emoji2} from '../utils/util'
import '../style/selectfield.css'


 function SelectField(props) {
    const {metadata} = props.profile
    const {field} = props
    const noOptionsText="Δεν υπάρχουν επιλογές"



    let options=null
    let index=-1
    let clNm=""
    if(field==='gender_id'){
        const tempOptions=metadata.filter(m=> m.field === field)
        options=tempOptions.map(o=> ({
            value:o.id.toString(), label:o.des 
        }))
        if(props.obj==='contact')
            index =options.findIndex(o=>parseInt(props.profile.contact[field])===parseInt(o.value))
        else if(props.obj==='children'|| props.obj==='brothers'){
            index =options.findIndex(o=>parseInt(props.profile[props.obj][props.tblId][field])===parseInt(o.value))
        }
        clNm="sel-field" 
    }else if (field==='marital_status_id' || field==='parent_marital_status_id'){
        const tempOptions=metadata.filter(m=> m.field === 'marital_status_id')
        options=tempOptions.map(o=> ({
            value:o.id.toString(), label:o.des 
        }))  
        index =options.findIndex(o=>parseInt(props.profile.contact[field])===parseInt(o.value))
        clNm="sel-country"
    }else if (field==='m_unit_id' || field==='info_source_id' ){
        //const tempOptions=m_unit.filter(m=> m.id === 'marital_status_id')
        const tempOptions=metadata.filter(m=> m.field === field)
        options=tempOptions.map(o=> ({
            value:o.id.toString(), label:o.des 
        }))  
        index =options.findIndex(o=>parseInt(props.profile.contact[field])===parseInt(o.value))
        clNm="sel-country"

    }else if (field==='father_education_level_id' || field==='mother_education_level_id' ||field==='education_level_id' ){
        const tempOptions=metadata.filter(m=> m.field === 'education_level_id')
        options=tempOptions.map(o=> ({
            value:o.id.toString(), label:o.des 
        }))  
        index =options.findIndex(o=>parseInt(props.profile.contact[field])===parseInt(o.value))
        clNm="sel-country"
    }else if (field==='father_occupation_id' || field==='mother_occupation_id' ||  field==='hw_occupation_id' || props.obj==='occupation' ){
        const tempOptions=metadata.filter(m=> m.field === 'occupation')
        options=tempOptions.map(o=> ({
            value:o.id.toString(), label:o.des 
        }))  
        if(props.obj==='occupation'){
            //console.log(props.tblId)
            index =props.tblId!==undefined?options.findIndex(o=>parseInt(props.profile[props.obj][props.tblId].option_id)===parseInt(o.value)):-1
            //index =options.findIndex(o=>parseInt(props.profile.occupation)===parseInt(o.value))
            //console.log("index:", index)
        }else{
            index =options.findIndex(o=>parseInt(props.profile.contact[field])===parseInt(o.value))
        }
        clNm="sel-country"
    }else if(field==='nationality_id'){
        const {countries}=props.profile
        options=countries.map(o=> ({
            value:o.id, label:o.country, code2:o.code2 
        }))
        //console.log("field",props.profile.contact[field], options)
        index =options.findIndex(o=>props.profile.contact[field]===o.value)
    }
    

    function  filterOption (option, inputValue){
        return inputValue ?
             !option.label.substring(0,inputValue.length).localeCompare(inputValue, 'el-GR',{sensitivity:'base'}): true
    }

    function formatOptionLabel({ label, code2}){
        return <>
            <span className="flag">{country2emoji2(code2)}</span> 
            {label}
            </>
    }

    const brandColor = 'var(--bd-color)'

    const customStyles = {
        control: (base, state) => ({
            ...base,
            paddingTop:'0.4em',
            paddingBottom:'0.4em',
            boxShadow: state.isFocused ? 0 : 0,
            borderColor: state.isFocused
        
            ? brandColor
            : base.borderColor,
            '&:hover': {
            borderColor: state.isFocused
                ? brandColor
                : base.borderColor,
            }
        })
    }
       
   
    return (
            <>
             <label htmlFor={field}>{props.title}</label>
            {field==='nationality_id' ? 
            
                <Select 
                name={field} 
                id={field}
                options={options} 
                isClearable={true}
                placeholder={'Επιλέξτε ...'}
                menuPlacement ='auto'
                value={index >=0 ?options[index]:null}
                filterOption={filterOption}
                onChange={(v)=>props.change(v, props.obj, field)}
                noOptionsMessage={()=>noOptionsText}
                className="sel-country"
                formatOptionLabel={formatOptionLabel}
                styles={customStyles}
                />
        
            :
        
                <Select 
                name={field} 
                id={field}
                options={options} 
                isClearable={true}
                placeholder={'Επιλέξτε...'}
                value={index >=0 ?options[index]:null}
                filterOption={filterOption}
                onChange={(v)=>props.change(v, props.obj, field,  props.tblId)}
                noOptionsMessage={()=>noOptionsText}
                className={clNm}
                styles={customStyles}
                />

            }
            </>
    )
}
export default SelectField
