import React, {useState,useEffect} from 'react'
import '../utils/fontawesome'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import "../style/loader.css"

export default function Loader(props) {
    const [isShown, setIsShown]=useState(false)
    useEffect(()=>{
       const t = setTimeout(()=>{
            setIsShown(true)
        },100)
        
        return ()=>{clearTimeout(t)}
    }, [])

    return (
        <>
        {
        isShown ?
        <div className="loader">
            <span>{props.text}</span>
            <FontAwesomeIcon icon={['fas', 'spinner']} pulse  className="icon-loader"/>
        </div>
        :<div />
        }
        </>
    )
}
