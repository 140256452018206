import React from 'react'
//import {CustomDialog} from 'react-st-modal'
//import CustomDialogContent from './CustomDialogContent'
import '../style/actiondependedfield.css'
import '../utils/fontawesome'
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function ActionDependField(props) {
        const fld=props.field
       //.const {title} = props
        //.const {last_actions, metadata,access_action}=props.profile
        const {last_actions, metadata}=props.profile
        const options = fld==='diagnosis'? props.profile.opt_diagnosis:metadata.filter(m=>m.field===fld)
        const resultSymptoms = last_actions.map((a,j)=>{
          //const resultSymptoms = actions.map((a,j)=>{
           //. const actionIDDate={action_id:a.id, action_date:a.action_date}
            const actionDate = a.action_date ? new Date(a.action_date).toLocaleDateString('el-GR'):null
            const values = props.profile[fld].filter(s=> a.id === s.action_id)
            const symptoms = values.map((s,i)=>{
                const optionDes=options.find(o=>o.id===s.option_id)
                return <li className="list-item" key={i}>{optionDes ? optionDes.des:''} {s.des ? ` - ${s.des}`:''}</li>})
            
            return (
                <div className="comp-grid-container" key={`grks_${j}`}>
                    
                    <span className="grid-comp-tool">
                        {/*{
                        access_action ?
                        <FontAwesomeIcon icon={['fas', 'edit']}  className="grid-icons" 
                            onClick={ async ()=>{
                                const result = await CustomDialog(<CustomDialogContent options={options} values={values} action={actionIDDate} title={title}/>,
                                     {title, showCloseIcon:true, isCanClose:false, className:"myclass"})
                                     
                                if(result)
                                    props.update(result, fld, a.id)
                                    //console.log("symptoms", result)
                                   // props.updateName(result)
                            }}
                        />
                        :
                        ''
                        }*/}
                    </span>
                    <ul className="grid-content-list">
                        {symptoms }
                    </ul>
                    {symptoms.length >0 ? <h5>Πράξη {a.id} ({actionDate})</h5> : ''}

                </div>
            )

        })
               
    return (
        <div>
            <h3>{props.title}</h3>
           {resultSymptoms}
        </div>
    )
}
