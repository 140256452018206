import React,{useState} from 'react'

const AuthContext = React.createContext()

function AuthContextProvider(props) {
    
    const tkn = localStorage.getItem('token') || false
    const [token, setToken] = useState(tkn)
    return (
        <AuthContext.Provider value={{token, setToken}}>
            {props.children}
        </AuthContext.Provider>
    )
}

export {AuthContextProvider, AuthContext}
