import React, {useState, useEffect, useContext, useRef}  from 'react'
import { AuthContext } from '../AuthContext'
import DatePicker ,{registerLocale, setDefaultLocale} from 'react-datepicker'
import InputMask from 'react-input-mask'
import Select from 'react-select'
import { AsyncPaginate } from 'react-select-async-paginate'
import { useNavigate} from 'react-router-dom'
import Pagination from './Pagination'
import ActionItem from './ActionItem'
import axiosAPI from '../services/axiosapi'
import {getMessageClass} from '../utils/util'
import '../utils/fontawesome'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import "../style/actionlist.css"
import "react-datepicker/dist/react-datepicker.css"
import {el} from 'date-fns/locale/'
//import {CustomDialog} from 'react-st-modal'
//import ProfileDialog from './ProfileDialog'

export default function ActionList() {
    const timer = useRef(null)
    registerLocale('el',el)
    setDefaultLocale('el')
    const navigate = useNavigate()
    const noOptionsText="Δεν υπάρχουν επιλογές"
    const brandColor = 'var(--bd-color)'
    const {setToken}  = useContext(AuthContext)

    const searchFieds= {
        searchActionDateFrom:null, 
        searchActionDateTo:null,
        searchProfileId:null,
        searchParticipants:[],
        searchAuth:0,
        rpp:50,
        page:1
    }
    const optionNum=[5,10,20,50,100,200,500]

    const [search, setSearch]=useState(sessionStorage.getItem('actionsearch')?JSON.parse(sessionStorage.actionsearch):searchFieds)
    const [aid] = useState(sessionStorage.getItem('aid')||null)
    const [actions, setActions]=useState(null)
    const [diagnosis, setDiagnosis]=useState([])
    const [medication, setMedication]=useState([])
    const [participants, setParticipants]=useState([])
    const [totalRows, setTotalRrows] =useState(0) 
    const [go, setGo]=useState(0)
    const [userList, setUserList] =useState([])
    const [optDiagnosis, setOptDiagnosis]=useState([])
    const [metadata, setMetadata]= useState([])
    //const [m_unit, setM_unit]=useState([])
    const [isLoad, setIsLoad]=useState(false)
    const [updateMessage,setUpdateMessage]=useState({error:0,message:'', affected:0})

    const options=optionNum.map(op=>
        <option key={`ok_${op}`} value={op}>{op}</option>
        )

    const userOptions = userList.map(u=>({value:u.id, label:u.fullname}))
    const actionCount = actions?actions.length:null
    const actionTypeOptions = metadata.filter(o=>o.field==="action_type_id")
    const visitTypeOptions = metadata.filter(o=>o.field==="visit_type_id")
    const actionPlaceOptions = metadata.filter(o=>o.field==="action_place_id")
    const medicationOptions = metadata.filter(o=>o.field==="medication")

    const actionItems=actions ? actions.map((a,i)=>{
        //const dimEn=m_unit.find(m=>m.id===a.m_unit_id)
        const itemData ={
            actionID : a.id,
            action_auth_id:a.action_auth_id,
            is_new : a.is_new,
            //m_unit : dimEn ? `${dimEn.m_unit} ${dimEn.des?`-${dimEn.des}`:''}`:'',
            action_place_des : a.action_place_des,
            action_type_des : a.action_type_des,
            actionDate : a.action_date ? new Date(a.action_date).toLocaleDateString('fr-CA'):null,
            actionType : actionTypeOptions.find(o=>o.id === a.action_type_id) || null,
            visitType : visitTypeOptions.find(o=>o.id===a.visit_type_id) || null,
            actionPlace : actionPlaceOptions.find(o=>o.id===a.action_place_id) || null,
            diagnosis : diagnosis.filter(d=>parseInt(d.action_id)===parseInt(a.id)) ||[],
            medication : medication.filter(m=>parseInt(m.action_id)===parseInt(a.id)) ||[],
            opt_medication:medicationOptions||[],
            members : participants.filter(p=>parseInt(p.action_id)===parseInt(a.id))||[],
            opt_diagnosis:optDiagnosis,
            users:userList
        }   
       
        const contact={
            name:`${a?.surname||''} ${a?.first_name||''}`,
            amka: a.amka,
            folderNumber:a.folder_number,
            gender:a.gender_id,
            birthYear:a.birth_year
        }

        return(
            <ActionItem key={`action_${i}`} itemData={itemData} edit={editAction} curItem={parseInt(a.id)===parseInt(aid)} contact={contact} delete={deleteAction}/>
        )
    }) : ''


    
    function clearSearch(ev){
        //setSearch(initSearch())
        setSearch(searchFieds)
        sessionStorage.removeItem('actionsearch')
    }

    function handleScroll(){
        const position = sessionStorage.getItem("actpos")
        if(position){
            timer.current = setTimeout(()=>{
                window.scrollTo(0,parseInt(position))
                sessionStorage.removeItem("actpos")
            },0)  
        }
    }

    function storeLocal(id){
        sessionStorage.setItem('actpos', window.pageYOffset)
        sessionStorage.setItem('actionsearch', JSON.stringify(search))
        sessionStorage.setItem("aid",id )
        //setAid(id)
    }

    function editAction(id){
        storeLocal(id)
        navigate(`/action/${id}`)
    }

    function handleChangeDate(field, value){ 
        //console.log(ev)
        const  dateVal  = value ? value.toLocaleDateString('fr-CA') :null
        setSearch(prev => ({...prev,[field]:dateVal}))
        //console.log(search)
    }

    function handleChange(ev , goSearch=false){
        const {name,value} = ev.target
        if(ev.target.type==='checkbox'){
            const val=ev.target.checked?1:0
            setSearch(prev=>({...prev,[name]:val}))

        }else if(name==='rpp'){
            setSearch(prev=>({...prev,[name]:value, page:1}))
        }else{
            setSearch(prev=>({...prev,[name]:value||null}))
        }

        if(goSearch){
            //console.log("ok")
            setGo(2)
        }
    }

    function handleMultiSelectChange(e){
        
        setSearch(prev=>({...prev, searchParticipants:Array.isArray(e)? e.map(x=>x.value):[]}))
        
        //console.log(search)
    }

    function getSearch(ev){
        setSearch(prev=>({...prev, page:1}))
        setGo(2)
    }

    function changePage(ev){
        const {value}=ev.target
        setSearch(prev=>({...prev,page:value}))
        setGo(2)
        
    }

    function asyncSelectChange(e){
        console.log(e)
        setSearch(prev =>({...prev,searchProfileId:e}))
    }

    const loadOptions = async (searchContact, prevOptions) => {
        const tkn=localStorage.getItem('token')||false
        const headers= {
          'Content-Type':'application/json',
          'Authorization': `Bearer ${tkn}`,
        }
        const offset = prevOptions.length
        //console.log("offset", offset)
        try{
            const response  = await axiosAPI.post("profile.php",{searchContact,offset}, {headers})
            const {error} = response.data
            if(error===0){
                const {options, hasMore} = response.data
                return {
                    options,
                    hasMore
                }
            }
        }catch(err){
           // console.log(err)
        }
    
        return {
            options:[],
            hasMore:false
        }
    }

    function formatOptionLabel({ label, folder}){
        return <>
            <div>{label}</div>
            <span className='opt-label'>{folder || '-'}</span> 
            </>
    }



    const customStyles = {
        control: (base, state) => ({
            ...base,
            maxHeight: '90px',
            overflowY: 'auto',
            paddingTop:'0.1rem',
            paddingBottom:'0.1rem',
            
            boxShadow: state.isFocused ? 0 : 0,
            borderColor: state.isFocused
            ? brandColor
            : base.borderColor,
            '&:hover': {
            borderColor: state.isFocused
                ? brandColor
                : base.borderColor,
            },
        }),
        menu: provided => ({ ...provided, zIndex: 8 }),
        multiValueLabel: (styles) => ({
            ...styles,
            color:'#000',backgroundColor:'whitesmoke',fontSize:'1rem', padding:'0.5em'
        }),
        
        
    }
            
    function  filterOption (option, inputValue){
        return inputValue ?
                !option.label.substring(0,inputValue.length).localeCompare(inputValue, 'el-GR',{sensitivity:'base'}): true
    }

    function deleteAction(actionId){
        //console.log("Action ID: ",actionId)
        const tkn=localStorage.getItem('token')||false
        const headers= {
          'Content-Type':'application/json',
          'Authorization': `Bearer ${tkn}`,
        }
        axiosAPI.post("profile.php",{mode:"DELACT", actionId:actionId, },{headers})
        .then(response=>{
            const {error, message, affected, token}=response.data
            if(parseInt(error)<0){
                setUpdateMessage({error,message,affected:affected})
                localStorage.removeItem('token')
                setToken(false)
              }else{
                localStorage.setItem('token',token)
                setToken(token)
                setUpdateMessage({error,message,affected})
                storeLocal(actionId)
                setGo(1)
                
              }
        })  
    }


    
    useEffect(() => {
        setGo(1)
        return  () => {
            if(timer.current)
                clearTimeout(timer.current)
            }
        },[])

    useEffect(()=>{
        let t=null
        if(updateMessage.message){
            const err = parseInt(updateMessage.error) === 0 ? 500:3000
            t = setTimeout(()=>{
            setUpdateMessage({error:0,message:"",affected:0})
            },err)
        }
        return ()=>{clearTimeout(t)}
        },[updateMessage])

    useEffect(() => {
        //console.log("go")
        if(go){
            setIsLoad(false)
            if(go===2)
                window.scrollTo(0,0)
                
            sessionStorage.setItem('actionsearch', JSON.stringify(search))
            const tkn=localStorage.getItem('token')||false
            const headers= {
                'Content-Type':'application/json',
                'Authorization': `Bearer ${tkn}`,
                }
                //console.log(search)
            axiosAPI.post("action.php",{search:search, usrLst:go===1?true:false},{headers})
            .then(response=>{
                setIsLoad(true)
                const {actions,diagnosis,medication, participants,error, token} = response.data
                //console.log("contacts", response)
                if(parseInt(error) < 0){
                    localStorage.removeItem('token')
                    setToken(false)
                }else if(parseInt(error) === 0){
                    //console.log("contacts:", contacts)
                    //const goVar=go
                    if(go===1){
                        const {userList, optDiagnosis, metadata} = response.data
                        setUserList(userList)
                        setOptDiagnosis(optDiagnosis)
                        setMetadata(metadata)
                        //setM_unit(m_unit)
                    }
                    setActions(actions)
                    setDiagnosis(diagnosis)
                    setParticipants(participants)
                    setMedication(medication)
                    //setGo(0)
                    //setSearch(prev=>({...prev, "page":response.data.page}))
                    setTotalRrows(response.data.totalRows)
                    localStorage.setItem('token',token)
                    setToken(token)
                    if(go===1)
                        handleScroll()
                    setGo(0)
                }
                
            })
        }
    },[go, search, setToken])

    //console.log(userList)
    
    return (
        <div className="list-container">
           <fieldset className='search'>
           
            <legend>
               Πράξεις - Aναζήτηση <FontAwesomeIcon icon={['fas', 'eraser']}  className="icons icon-sm" onClick={(e)=>clearSearch(e)}/>
            </legend>
        
            <ul>
                <li>
                    <label>Από</label>
                    <DatePicker locale={el} name="searchActionDateFrom" id="searchActionDateFrom" 
                        selected={search.searchActionDateFrom ?new Date(search.searchActionDateFrom):''} 
                        onChange={(date)=>handleChangeDate('searchActionDateFrom',date)}
                        dateFormat="dd / MM / yyyy"
                        preventOpenOnFocus={true}
                        isClearable
                        showMonthDropdown={true}
                        showYearDropdown={true}
                        adjustDateOnChange
                        dropdownMode="select"
                        className="date-input-search"
                        customInput={<InputMask mask="99 / 99 / 9999"  maskPlaceholder="_"  alwaysShowMask={true} />}

                  ></DatePicker>
                </li>
                <li>
                    <label>Εως</label>
                    <DatePicker locale={el} name="searchActionDateTo" id="searchActionDateTo" 
                        selected={search.searchActionDateTo ?new Date(search.searchActionDateTo):''} 
                        onChange={(date)=>handleChangeDate('searchActionDateTo',date)}
                        dateFormat="dd / MM / yyyy"
                        preventOpenOnFocus={true}
                        isClearable
                        showMonthDropdown={true}
                        showYearDropdown={true}
                        adjustDateOnChange
                        dropdownMode="select"
                        className="date-input-search"
                        customInput={<InputMask mask="99 / 99 / 9999"  maskPlaceholder="_"  alwaysShowMask={true} />}

                  ></DatePicker>
                </li>
                
                <li>
                    <label htmlFor="searchParticipants">Συμμετέχοντες</label>
                    <Select 
                        name='searchParticipants' 
                        options={userOptions} 
                        isClearable={true}
                        placeholder={'Επιλέξτε ...'}
                        isMulti={true}
                        value={userOptions.filter(x=>search.searchParticipants.includes(x.value))}
                        onChange={handleMultiSelectChange}
                        noOptionsMessage={()=>noOptionsText}
                        className="sel-part"
                       styles={customStyles}
                        menuPlacement='auto'
                        filterOption = {filterOption}
                        
                    />
                </li>
                <li style={{alignSelf:'end'}}>
                    <label htmlFor='searchAuth' className='lbl-chk'>Υπευθ.
                    <input type="checkbox" name="searchAuth" id="searchAuth"
                        checked={search.searchAuth?true:false}
                        defaultValue={search.searchAuth}
                        onChange={(e)=>handleChange(e)}
                    />
                    <span className="checkmark"></span>
                    </label>
                </li>
                <li>
                    <label htmlFor='searchProfile'>Ωφελούμενος - Αρ. Φακέλλου</label>
                    <AsyncPaginate
                        value={search?.searchProfileId || null}
                        onChange = { e => asyncSelectChange(e)}
                        loadOptions = {loadOptions}
                        isClearable={true}
                        
                        placeholder={'Αναζητηση ωφελούμενου ...'}
                        noOptionsMessage={()=>noOptionsText}
                        className="sel-part-4"
                        formatOptionLabel={formatOptionLabel}
                        id="searchProfile"
                        styles={customStyles}
                    />  
                </li>

                <li>
                    <FontAwesomeIcon icon={['fas', 'search']}  className="icons" onClick={(e)=>getSearch(e)}/>
                </li>
                
                <li>
                    {/*<FontAwesomeIcon icon={['fas', 'plus-circle']}  className="icons" onClick={async ()=>{
                            const result = await CustomDialog(<ProfileDialog  title="Επαφές" />,
                                 { showCloseIcon:true, isCanClose:false, className:"profile-modal"})
                                 
                                if(result){
                                   
                                }
                            }
                        }
                    />*/}
                    {/*
                    <Link to={`/contacts/`}>
                    <FontAwesomeIcon icon={['fas', 'plus-circle']}  className="icons" />
                    </Link>
                    */}
                </li>
            </ul>
           </fieldset>
           
           <div className='action-list-content'>
               
               {!isLoad ?<h2>Load...</h2> : actions && actions.length >0 ? actionItems:<h2>Δεν υπάρχουν δεδομένα</h2>}
               <div className='footer'>
                    <select name="rpp" value={search.rpp} onChange={(e)=>handleChange(e, true)}>
                        {options}
                    </select>
                    {actions && isLoad?
                    <div>
                    
                    {actionCount>0?actionCount:''} {`${actionCount >0 ? `(${(search.page-1)*search.rpp + 1} ~ ${(search.page-1)*search.rpp +actionCount}) από ${totalRows}`:''}`}
                    {/*actions && isLoad ? `${actionCount} απο ${totalRows}`:''*/}
                    </div>
                    :''}
                    <div>
                        {isLoad ? 
                        <Pagination totalRows={totalRows} currentPage={parseInt(search.page)} rpp={parseInt(search.rpp)} changePage={changePage}/>
                        :<FontAwesomeIcon icon={['fas', 'spinner']}   className="icon-sm-mail-s fa-spin"/>
                        }
                    </div> 
               </div>
           </div>
           { 
                updateMessage.message ? 
                <div className={`message-box ${getMessageClass(updateMessage.error, updateMessage.affected)}`}>{updateMessage.message}</div>
                :
                ''
            } 
        </div>
    )
}


