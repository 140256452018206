import React, {useState, useContext, useEffect, useRef} from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import Select from 'react-select'
import axiosAPI from '../services/axiosapi'
import Loader from "./Loader"
import { AuthContext } from '../AuthContext'
import '../utils/fontawesome'
import CreatePass from './CreatePass'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Confirm } from 'react-st-modal'
import { getMessageClass } from '../utils/util'
import InputMask from 'react-input-mask'
import "../style/profile.css"
import "../style/selectfield.css"
import "../style/users.css"


function User(props) {

    const { setToken}  = useContext(AuthContext)
    const {id}=useParams()
    const navigate = useNavigate()
    const location = useLocation()
    const [isLoad,setIsLoad]=useState(false)
    const [user, setUser] = useState(null)
    const [userRights,setUserRights] = useState([])
    const [workHours, setWorkHours] = useState([])
    const [updateMessage,setUpdateMessage]=useState({error:0,message:'', affected:0})
    const [errorInfo , setErrorInfo] =useState([])
    const [specialtyOptions, setSpecialtyOptions]=useState([])
    const [cityOptions, setCityOptions]=useState([])
    const [genderOptions, setgenderOptions]=useState([])
    const [visible, setVisible]=useState({pass:false, vcode:false, vcodeURL:false})
    const [currentSection, setCurrentSection]=useState(1)

    const {admin} = props

    const DOMAIN = 'epipsi.eu'
    //const [passLen, setPassLen] = useState(8)
    
    const weekDay=['Δευτέρα', 'Τρίτη', 'Τετάρτη', 'Πέμπτη', 'Παρασκευή', 'Σάββατο', 'Κυριακή']
    
    const noOptionsText="Δεν υπάρχουν επιλογές"

    const timer = useRef(null)

    const brandColor = 'var(--bd-color)'

    const workHoursError = errorInfo.map((w,i)=> <tr key={`wh${i}`}>
        <td style={{padding:'0.5rem'}}>{w.appointment_date} : {`[${w.start_time} - ${w.end_time}]`}</td>
        <td style={{padding:'0.5rem'}}>{weekDay[w.work_day]} : {w.enabled ? `${w.hour_start} - ${w.hour_end}`:'Δεν έχει ωράριο'}</td>
    </tr>)

    // functions ------------------------------------
    function sectionClick(e,cs){
        e.preventDefault()
        setCurrentSection(cs)
      }

      function handleChange(ev, idx=null){
            const {name, value} = ev.target
           // console.log(ev.target.type, name)
        if(name==='user_name')
            setUser(pr=>({...pr, [name]:value, contact_email:`${value}@${DOMAIN}`}))
        else if(ev.target.type==="checkbox" && name==='account_status'){
            const val = ev.target.checked ?value>=0?value:1:-1
            changeAccountStatus(idx, val)
            //setUser(prev=>({...prev, [name]:val}))
        }else if(ev.target.type==="checkbox" && name==='connection_mode'){
            const val = ev.target.checked ?2:1
            setUser(prev=>({...prev, [name]:val}))
        }else if(ev.target.type==="checkbox" && name==="task"){
            const val = ev.target.checked ?1:0

            setUserRights(p=> p.map((r,i)=>i===idx?{...r,access:val}:r))
        }else
            setUser(prev=>({...prev, [name]:value?value:null}))
      }

      function handleChangeWorkHours(i,e){
        //console.log(e.target)
        const {name, value} = e.target
        const tmpArr = workHours.map((w,j)=> j===i ? ({...w, [name]:value||null}):w)
        setWorkHours(tmpArr)
      }
      function handleSwitchWorkHours(i,e){
        //console.log(e.target)
        const {name, checked} = e.target

        const tmpArr = workHours.map((w,j)=> j===i ? ({...w, 
            hour_start:checked?w.hour_start:null,  
            hour_end:checked?w.hour_end:null,
            [name]:checked?1:0}):w)

        setWorkHours(tmpArr)
      }

      function createWorkHours(e){
        e.preventDefault()
        const tmpArr = weekDay.map((d,i)=>(
            {work_day:i,hour_start:null,hour_end:null,enabled:0}
        ))
        setWorkHours(tmpArr)
      }
      function deleteWorkHours(){
        setWorkHours([])
      }

      function handleChangeNumber(ev){
        ev.preventDefault()
        const {name, value} =ev.target
        const patternAfm = /^\d{0,9}$/
        const patternAmka = /^\d{0,11}$/

        if(name === 'afm' && !patternAfm.test(value) && value!=="")
            return
        if(name==='amka' && !patternAmka.test(value) && value!=="")
            return
    
        setUser(prev=>({...prev, [name]:value||null}))
      }

      function getSection(s){
        if(s===1)  
            return "Στοιχεία Χρήστη"
        else if(s===2)
            return "Στοιχεία Σύνδεσης"
        else if(s===3)
            return "Δικαιώματα Χρήστη"
        else if(s===4)
            return "Ωράριο Εργασίας"
        else
            return ""
      }

      function handleKeyPress(ev){
          const {name, value} = ev.target
          const alfa=[";","ς","ε","ρ","τ","υ","θ","ι","ο","π","λ","κ","ξ","η","γ","φ","δ","σ","α","ζ","χ","ψ","ω","β","ν","μ"]
          const beta=["q","w","e","r","t","y","u","i","o","p","l","k","j","h","g","f","d","s","a","z","x","c","v","b","n","m"]
          
          let str = value.toLowerCase()
          const idx = alfa.findIndex(a=>a===ev.key)
          if(idx >=0){
              ev.preventDefault()
              str+=beta[idx]
          }
          setUser(p=>({...p, [name]:str}))
          
          //console.log(ev.key)
      }
    
   /*  function handleActiveChange(ev){
        const {name, checked} = ev.target
        setUser(prev =>({...prev, [name]:checked?1:0}))
    } */

    
    function updateUser(usp){
        setUser(pr=>({...pr, user_pass:usp}))
    }

    function showSecret(ev, field){
        setVisible(pr=>({...pr, [field]:!pr[field]}))
    }

    function insertUpdateUser(ev){
        ev.preventDefault();
        const tkn=localStorage.getItem('token')||false
        const headers= {
        'Content-Type':'application/json',
        'Authorization': `Bearer ${tkn}`,
        }
        axiosAPI.post("users.php",{
            user:user, mode:"upd", id:user.id, userRights:userRights, workHours:workHours
        },{headers})
        .then(response=>{
            const {error, errorMessage,errorInfo, token, userID, affected} = response.data
            if(parseInt(error)<0){
                setUpdateMessage({error:error,message:errorMessage,affected:0})
                setErrorInfo(errorInfo)
                localStorage.removeItem('token')
                setToken(false)
            }else {
                if(parseInt(error)>0){
                    setUpdateMessage({error:error,message:errorMessage,affected:0})
                    setErrorInfo(errorInfo)
                }else if(parseInt(error)===0){
                    setUpdateMessage({error:error,message:errorMessage,affected:affected})
                    if(id<0){
                        const newUrl=`${location.pathname.slice(0,location.pathname.lastIndexOf("/"))}/${userID}`
                        navigate(newUrl, {replace:true})
                    }
                }
                localStorage.setItem('token',token)
                setToken(token)
                
            }
        })
    }

    function create2FAKey(id, username){
        const tkn=localStorage.getItem('token')||false
        const headers= {
        'Content-Type':'application/json',
        'Authorization': `Bearer ${tkn}`,
        }
        axiosAPI.post("users.php",{
            mode:"CR2FA", id:id, username:username
        },{headers})
        .then(response=>{
            const {error, errorMessage, token, vcode, qrimg, qrurl} = response.data
            if(parseInt(error)<0){
                setUpdateMessage({error:error,message:errorMessage,affected:0})
                localStorage.removeItem('token')
                setToken(false)
            }else if(parseInt(error)===0){
                setUser((pr)=>({...pr, vcode:vcode, qrimg:qrimg, qrurl:qrurl}))
                localStorage.setItem('token',token)
                setToken(token)
            }else{
                setUpdateMessage({error:error,message:errorMessage,affected:0})
                localStorage.setItem('token',token)
                setToken(token)
            }
        })

    }

    function changeAccountStatus( id, status ){
        //console.log("account")
        if(!id)
            return
        const tkn=localStorage.getItem('token')||false
        const headers= {
          'Content-Type':'application/json',
          'Authorization': `Bearer ${tkn}`,
        }
        axiosAPI.post("users.php",{id:id, accStat:status, mode:"enDis"}, {headers})
        .then(response=>{
           
            const {error, errorMessage,token, account_status} = response.data
            if(parseInt(error)<0){
                //setMessage({error:error,message:errorMessage})
                localStorage.removeItem('token')
                setToken(false)
            }else if(parseInt(error)===0){
                const {affected}=response.data
                if(affected > 0){
                    setUser(p=>({...p, account_status:account_status}))
                    setUpdateMessage({error:error, message:errorMessage,affected:affected})
                }
                localStorage.setItem('token',token)
                setToken(token)  
            } else{
                setUpdateMessage({error:error, message:errorMessage,affected:0})
                localStorage.setItem('token',token)
                setToken(token)  
            }
        })
    
      }

      
    //-----------------------------------------------



    const customStyles = {
        control: (base, state) => ({
            ...base,
            paddingTop:'0.4em',
            paddingBottom:'0.4em',
            boxShadow: state.isFocused ? 0 : 0,
            borderColor: state.isFocused
            ? brandColor
            : base.borderColor,
            '&:hover': {
            borderColor: state.isFocused
                ? brandColor
                : base.borderColor,
            }
        })
    }
    

    
    useEffect(()=>{
        const tkn=localStorage.getItem('token')||false
        /*function padNum(obj){
            
            if(obj.hasOwnProperty('amka') && obj.amka)
               obj.amka= obj.amka.toString().padStart(11,0)
            if(obj.hasOwnProperty('afm') && obj.amka)
               obj.afm= obj.afm.toString().padStart(9,0)
            return obj

        }*/
        const headers= {
          'Content-Type':'application/json',
          'Authorization': `Bearer ${tkn}`,
        }
        axiosAPI.post("users.php",{getuserid:id}, {headers})
        .then(response=>{
            //console.log("user response:", response.data)
            
          const {error, errorMessage, token} = response.data
          
          if(parseInt(error)<0){
            setUpdateMessage({error:error,message:errorMessage,affected:0})
            localStorage.removeItem('token')
            setToken(false)
          }else{
            if(parseInt(error)===0){
                const {user, specialty, cities, gender,rights, work_hours} = response.data
               
                const spOpt=specialty.map(sp=>({value:sp.id, label:sp.specialty}))
                const cityOpt=cities.map(c=>({value:c.id, label:c.city}))
                const genderOpt=gender.map(g=>({value:g.id, label:g.des}))
                setSpecialtyOptions(spOpt)
                setCityOptions(cityOpt)
                setgenderOptions(genderOpt)
                //const f =padNum(user)
                setUser(user)
                setUserRights(rights)
                setWorkHours(work_hours)
                //console.log(rights)
                //console.log(response.data)

            } else{
                setUpdateMessage({error:error,message:errorMessage,affected:0})
            }
            localStorage.setItem('token',token)
            setToken(token)
            setIsLoad(true)
          }
          
        })
     
      },[id, setToken])

      useEffect(() => {
        if(updateMessage.message){
            const err = parseInt(updateMessage.error) === 0 ? 500:3000
            timer.current = setTimeout(()=>{
                setUpdateMessage({error:0,message:"",affected:0})
            },err)
         }
        return () => {
              clearTimeout(timer.current)
          }
      }, [updateMessage])

      const  tblRights = userRights?userRights.map((t,i)=>{
          const dis = parseInt(t.disabled)===1?true:false
          return(
            <div key={`ur_${t.id}`}>
                <label className="switch">
                    <input id={`ur${t.id}`} type="checkbox" name="task" disabled={dis} checked={parseInt(t.access)===1?true:false} onChange={(e)=>handleChange(e,i)}/>
                    <span className="sw-slider round"></span>
                </label>
                <label htmlFor={`ur${t.id}`} className="lbl-switch">{t.task_des}</label>     
            </div>              
      )}):[]
        
    const  workHoursComp = workHours.length ? workHours.map((w,i)=>
            <React.Fragment key={`whi${i}`}>
                <div style={{alignSelf:'center', textAlign:'right'}}>
                    { weekDay[w.work_day]}
                </div>
                
                <InputMask mask="99:99"   disabled={!w.enabled}  alwaysShowMask={false} className="wh-time-input" name="hour_start" value={w.hour_start||''} onChange={(e)=>handleChangeWorkHours(i,e)}/>
                <InputMask mask="99:99"   disabled={!w.enabled}  alwaysShowMask={false} className="wh-time-input" name="hour_end" value={w.hour_end||''} onChange={(e)=>handleChangeWorkHours(i,e)}/>
                <label className="switch">
                    <input  type="checkbox" name="enabled"  checked={parseInt(w?.enabled)===1?true:false} onChange={(e)=>handleSwitchWorkHours(i,e)}/>
                    <span className="sw-slider round"></span>
                </label>
                
            </React.Fragment>
    ):''
    if (!isLoad)
        return (<Loader/>)
    if(user)
    return (
        
        <div className="container">
            <header className="header">     
                {user.id ? 
                  <span style={{float:"right", position:'relative'}}>
                    {/* <label className="switch" style={{top:'-0.4rem'}}>
                      <input  type="checkbox" name="is_active"  checked={parseInt(user?.is_active)!==0?true:false} onChange={(e)=>handleActiveChange(e)}/>
                      <span className="sw-slider round"></span>
                    </label> */}
                  </span>
                  :
                  ''
                }
                <h2>
                    {user.surname} {user.first_name} {!user.id?'Νέος χρήστης':`ID-${user.id}`} 
                    {user?.is_active === 1 ?
                        <FontAwesomeIcon icon={['fas','user-check']} style={{padding:'0 1rem',color:'var(--dec)'}}/>
                        : user?.is_active === 0 ?
                        <FontAwesomeIcon icon={['fas','user-minus']}   style={{padding:'0 1rem',color:'#a00000'}}/>
                        :''
                    }
                
                </h2>
                <h4>{getSection(currentSection)}</h4>
            </header>
            <ul className="side-bar">
                <li className={`${currentSection===1?`active-section`:false}`} onClick={(e)=>sectionClick(e,1)}>{getSection(1)}</li>
                {admin ?
                <li className={`${currentSection===2?`active-section`:false}`} onClick={(e)=>sectionClick(e,2)}>{getSection(2)}</li>
                :''
                }
                {admin ?
                <li className={`${currentSection===3?`active-section`:false}`} onClick={(e)=>sectionClick(e,3)}>{getSection(3)}</li>
                :''
                }
                <li className={`${currentSection===4?`active-section`:false}`} onClick={(e)=>sectionClick(e,4)}>{getSection(4)}</li>            
            </ul>
            <div className="content">
                <div className={`section ${currentSection===1?'current':''}`}>
                    <div className="left-side">
                        <label htmlFor="surname">Επώνυμο</label>
                        <input type="text" name="surname" id="surname"  value={user.surname||''} onChange={(e)=>handleChange(e)} />
                        {/*<div style={{backgroundColor:"red",color:"#fff",width:"55%",maxWidth:"75%",position:"relative",top:"3px"}}>error</div> */}
                  
                        <label htmlFor="first_name">Όνομα</label>
                        <input type="text" name="first_name" id="first_name" value={user.first_name||''} onChange={(e)=>handleChange(e)} />
                  
                        <label htmlFor="father_name">Όνομα Πατέρα</label>
                        <input type="text" name="father_name" id="father_name" value={user.father_name||''} onChange={(e)=>handleChange(e)} />

                        <label htmlFor="gender_id">Φύλο</label>
                        <Select 
                            name='gender' 
                            id="gender_id"
                            options={genderOptions} 
                            isClearable={true}
                            placeholder={'Επιλέξτε ...'}
                            isMulti={false}
                            value={genderOptions.find(x=>parseInt(x.value) === parseInt( user.gender_id))}
                            onChange={(e)=>setUser(prev=>({...prev, gender_id:e?e.value:null}))}
                            noOptionsMessage={()=>noOptionsText}
                            className="sel-field"
                            styles={customStyles}
                        />
                        <label htmlFor="specialty_id">Ειδικότητα</label>
                        <Select 
                            name='specialty' 
                            id="specialty_id"
                            options={specialtyOptions} 
                            isClearable={true}
                            placeholder={'Επιλέξτε ...'}
                            isMulti={false}
                            value={specialtyOptions.find(x=>parseInt(x.value) === parseInt( user.specialty_id))}
                            onChange={(e)=>setUser(prev=>({...prev, specialty_id:e?e.value:null}))}
                            noOptionsMessage={()=>noOptionsText}
                            className="sel-country"
                            styles={customStyles}
                            menuPlacement="auto"
                        />

                    </div>
                    <div className="right-side">
                        <label htmlFor="userafm">Α.Φ.Μ.</label>
                        <input type="text" name="afm" id="userafm" value={user.afm||''} onChange={(e)=>handleChangeNumber(e)} />
                        
                        <label htmlFor="useramka">Α.Μ.Κ.Α.</label>
                       <input type="text" name="amka" id="useramka" value={user.amka||''} onChange={(e)=>handleChangeNumber(e)} />
                        
                        <label htmlFor="usertel">Τηλέφωνο</label>
                        <input type="text" name="tel" id="usertel" value={user.tel||''} onChange={(e)=>handleChange(e)} />
                        
                        <label htmlFor="contact_email">e-mail Επικοινωνίας</label>
                        <div className="pass-field">{user.contact_email||'-'}</div>
                       {/*<input type="text"  name="contact_email" id="contact_email" value={user.contact_email||''} onChange={(e)=>handleChange(e)} onKeyDown={(e)=>handleKeyPress(e)} />*/}
                       
                        <label htmlFor="city_id">Φορέας</label>
                        <Select 
                            name='city_id' 
                            id="city_id"
                            options={cityOptions} 
                            isClearable={false}
                            placeholder={'Επιλέξτε ...'}
                            isMulti={false}
                            value={cityOptions.find(x=>parseInt(x.value) === parseInt( user.city_id))}
                            onChange={(e)=>setUser(prev=>({...prev, city_id:e?e.value:null}))}
                            noOptionsMessage={()=>noOptionsText}
                            className="sel-country"
                            styles={customStyles}
                        />
                    
                    </div>
                </div>

                <div className={`section ${currentSection===2?'current':''}`}>
                    <div className="left-side">
                        <label htmlFor="user_name">Όνομα Χρήστη</label>
                        <input type="text" name="user_name" id="user_name"  value={user.user_name||''} onChange={(e)=>handleChange(e)} />
                        {/*<div style={{backgroundColor:"red",color:"#fff",width:"55%",maxWidth:"75%",position:"relative"}}>error</div> */}

                        <label htmlFor="user_pass">Κωδικός Πρόσβασης</label>
                        <div className="pass-field">{user.user_pass? visible.pass ?user.user_pass:"*****":"Δεν έχει"}</div>
                        {
                            !visible.pass ?
                            user.user_pass ? <FontAwesomeIcon icon={['fas', 'eye']}  className="icons icon-sm" onClick={(e)=>showSecret(e,'pass')}></FontAwesomeIcon>:''
                            :
                            <>
                                <FontAwesomeIcon icon={['fas', 'eye-slash']}  className="icons icon-sm" onClick={(e)=>showSecret(e,'pass')}></FontAwesomeIcon>
                                <FontAwesomeIcon icon={['fas', 'copy']}  className="icons icon-sm" onClick={(e)=>''}></FontAwesomeIcon>
                            </>
                        }
                        {/*<input type="password" name="user_pass" id="user_pass"  value={user.user_pass||''} onChange={(e)=>handleChange(e)} />*/}
                        <div>
                            <label className="switch">
                                <input id="s1" type="checkbox" name="connection_mode" checked={parseInt(user.connection_mode)===2?true:false} onChange={(e)=>handleChange(e)}/>
                                <span className="sw-slider round"></span>
                            </label>
                            <label htmlFor='s1'  className="lbl-switch">2FA ?  {user.connection_mode === 2?"ΝΑΙ":"ΟΧΙ"}</label>     
                        </div>             
                        <label htmlFor="vecode">2FA Κλειδί</label>
                        <div className="pass-field">{user.vcode? visible.vcode ?user.vcode:"*****":"Δεν έχει"}</div>
                        {!user.vcode?<FontAwesomeIcon icon={['fas', 'save']} className="icons icon-sm" onClick={()=>create2FAKey(user.id, user.user_name)}></FontAwesomeIcon>:''}
                        {
                            !visible.vcode ?
                            user.vcode?<FontAwesomeIcon icon={['fas', 'eye']}  className="icons icon-sm" onClick={(e)=>showSecret(e,'vcode')}></FontAwesomeIcon>:''
                            :
                            <>
                                <FontAwesomeIcon icon={['fas', 'eye-slash']}  className="icons icon-sm" onClick={(e)=>showSecret(e,'vcode')}></FontAwesomeIcon>
                                <FontAwesomeIcon icon={['fas', 'copy']}  className="icons icon-sm" onClick={(e)=>''}></FontAwesomeIcon>
                            </>
                        }
                        <label htmlFor="recovery_email">e-mail Επαναφοράς</label>
                        <input type="text"  name="recovery_email" id="recovery_email" value={user.recovery_email||''} onChange={(e)=>handleChange(e)} onKeyDown={(e)=>handleKeyPress(e)} />
                        
                          
                    </div>
                    <div className="right-side">
                        {
                            user
                            ?
                            <CreatePass  user={user} updateUser={updateUser} dis={parseInt(user.account_status)<0?true:false}/>
                            :
                            ''
                        }  
                        <div className='qr-container'>
                        {
                            visible.vcode?
                                <>
                                <img src={`data:image/png;base64,${user.qrimg}`} alt="qrCode" />
                                <div className='qr-url'>{user.qrurl}</div>
                                </>
                                :''
                            
                        }     
                        
                        </div>
                        <div className='bottom-place'>
                            <label className="switch">
                                <input id="s10" type="checkbox"  name="account_status" checked={parseInt(user.account_status)>=0?true:false} onChange={(e)=>handleChange(e,user.id)}/>
                                <span className="sw-slider round"></span>
                            </label>
                            <label htmlFor='s10'  className={`lbl-switch ${user.account_status <0?'dis':''}`}>{user.account_status >= 0?`Ενεργός`:`Απενεργοποιημένος`} Λογαριασμος</label>     
                        </div>           
                        
                        
                    </div>
                </div>
                <div className={`section ${currentSection===3?'current':''}`}>
                    <div className="left-side">
                        {tblRights}
                    </div>
                    <div className="right-side">
                       
                    </div>
                </div>
                <div className={`section ${currentSection===4?'current':''}`}>
                    <div  className='left-side work-hours'>
                        {workHours.length
                        ?
                        <>
                            {workHoursComp}
                            <div style={{gridColumn:'3 / -1'}}>
                                <span style={{color:'var(--dec)'}}>Διαγραφή ωραρίου</span>
                                <FontAwesomeIcon icon={['fas', 'trash-alt']} className="icons" style={{marginLeft:'1.5rem'}}  onClick={async () => {
                                    const result = await Confirm("Να διαγραφεί το ωράριο ?", 
                                    'Διαγραφή Ωραρίου', 'Ναι', 'Όχι')
                                    if (result) {
                                        deleteWorkHours()
                                    } 
                                }}/> 
                           </div>
                           
                        </>
                        : 
                         <button type='button' name="btnWH" onClick={(e)=>createWorkHours(e)} style={{gridColumn:'1/3'}}>
                            Δημιουργία Εβομαδιαίου Ωραρίου
                         </button>
                         }
                    </div>
                    <div className="right-side">
                    {errorInfo.length > 0 ?
                        <table className='error-tbl'>
                        <tr>
                            <th colspan="2" style={{padding:'0.5rem'}}>Ραντεβού Εκτός Ωραρίου
                            <FontAwesomeIcon icon={['fas', 'eraser']} style={{cursor:'pointer', float:'right'}}  onClick={()=>setErrorInfo([])}/>
                            </th>
                        </tr>
                        <tr>
                            <th style={{padding:'0.5rem'}}>Ραντεβού</th>
                            <th style={{padding:'0.5rem'}}>Ωράριο Εργασίας</th>
                        </tr>
                       {workHoursError}
                    </table>
                    :''

                    }

                    </div>
                </div>
                
                <button type="button" className='update-btn' name="btnUpdate" onClick={(e)=>insertUpdateUser(e)}>Ενημέρωση</button>
                <span className='ch-pass-btn' style={{marginLeft:'4em'}} onClick={(e)=>{navigate(-1)}}>Επιστροφή</span>
                
            </div>
            {updateMessage.message ?<pre  className={`message-box ${getMessageClass(updateMessage.error, updateMessage.affected)}`}>{updateMessage.message}</pre>:''}
        </div>
    )
    else
     return updateMessage.message ? <pre className={`message-box ${getMessageClass(updateMessage.error, updateMessage.affected)}`}>{updateMessage.message}</pre>:''
    
     
}

User.defaultProps={
    admin:false
}

export default User
