import React, {useState, useEffect, useContext, useRef} from 'react'
import {useNavigate} from 'react-router-dom'
import { AuthContext } from '../AuthContext'
import Select from 'react-select'
import Loader from "./Loader"
import CreatePass from './CreatePass'
import { getMessageClass } from '../utils/util'
import axiosAPI from '../services/axiosapi'
import '../utils/fontawesome'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import "../style/profile.css"
import CurrentPass from './CurrentPass'
import {CustomDialog} from 'react-st-modal'

export default function MyAccount(props) {
    
    const { setToken}  = useContext(AuthContext)
    const [currentSection, setCurrentSection]=useState(1)
    const [isLoad,setIsLoad]=useState(false)
    const [user, setUser] = useState(null)
    const [updateMessage,setUpdateMessage]=useState({error:0,message:'', affected:0})
    const [specialtyOptions, setSpecialtyOptions]=useState([])
    const [genderOptions, setgenderOptions]=useState([])
    const [chPass, setChPass]=useState(false)
    const [newUserPass, setNewUserPass] = useState(null)
    const [verifyNewUserPass, setVerifyNewUserPass] = useState(null)
    const [, setCurrentUserPass]=useState(null)
    const [inputType,setInputType]=useState("password")
    const passInput = useRef(null)
    const timerID = useRef(null)
    const navigate = useNavigate()
    const noOptionsText="Δεν υπάρχουν επιλογές"
    const brandColor = 'var(--bd-color)'

    const {uid} = props
    function getSection(s){
        if(s===1)  
            return "Προσωπικά Στοιχεία"
        else if(s===2)
            return "Στοιχεία Επικοινωνίας"
        else if(s===3)
            return "Στοιχεία Σύνδεσης"
        else
            return ""
    }

    function sectionClick(e,cs){
        e.preventDefault()
        setCurrentSection(cs)
    }

    function handleChange(ev, idx=null){
        const {name, value} = ev.target
       // console.log(ev.target.type, name)
        setUser(prev=>({...prev, [name]:value||null}))
  }

  function handleChangeNewPass(ev){
    const {name, value} = ev.target 
    if (name==='newUserPass')
        setNewUserPass(value)
    else if (name==='verifyNewUserPass')
        setVerifyNewUserPass(value)
    else if(name==="currentUserPass")
        setCurrentUserPass(value)

}

  function handleChangeNumber(ev){
    ev.preventDefault()
    const {name, value} =ev.target
    const patternAfm = /^\d{0,9}$/
    const patternAmka = /^\d{0,11}$/
    if(name === 'afm' && !patternAfm.test(value) && value!=="")
        return
    if(name==='amka' && !patternAmka.test(value) && value!=="")
        return
    setUser(prev=>({...prev, [name]:value}))
  }
  
  function showCreatePass(ev){
    setChPass(c=>!c)
    setCurrentUserPass(null)
  }

  function passChange(val){
      setNewUserPass(val)
      setVerifyNewUserPass(val)
  }

  function mouseDown(ev){
    if(ev.button===0){
        setInputType("text")
    }
}

function mouseUp(ev){
    if(ev.button===0){
        setInputType("password")
        passInput.current.focus()
    }
}

function copyField(val){
    if(!val)
        return
    
    try {
        navigator.clipboard.writeText(val)
        .then(()=>{

            setUpdateMessage({error:0,message:"Αντιγράφηκε",affected:1})
        })
        .catch((err)=>{
            setUpdateMessage({error:1,message:err,affected:0})
           
        })
    }catch (err){
        setUpdateMessage({error:2,message:err,affected:0})
       
    }
}

function handleKeyPress(ev){
    const {name, value} = ev.target
    const alfa=[";","ς","ε","ρ","τ","υ","θ","ι","ο","π","λ","κ","ξ","η","γ","φ","δ","σ","α","ζ","χ","ψ","ω","β","ν","μ","ά","έ","ή","ί","ό","ύ","ώ",":","Q"]

    const beta=["q","w","e","r","t","y","u","i","o","p","l","k","j","h","g","f","d","s","a","z","x","c","v","b","n","m","a","e","h","i","o","y","v","q","q"]

    
    let str = value.toLowerCase()
    const idx = alfa.findIndex(a=>a===ev.key)
    if(idx >=0){
        ev.preventDefault()
        str+=beta[idx]
    }
    setUser(p=>({...p, [name]:str}))
    
    //console.log(ev.key)
}

function updateAccount(pass){
    setIsLoad(false)
    const tkn=localStorage.getItem('token')||false
    const headers= {
      'Content-Type':'application/json',
      'Authorization': `Bearer ${tkn}`,
    }
    axiosAPI.post("users.php",{ user:user,
                                chPass:chPass, 
                                curPass:pass,
                                newPass:newUserPass,
                                verPass:verifyNewUserPass,
                                 mode:"UpdAcc"
                                }, {headers}
    )
    .then(response=>{
        //console.log("user response:", response.data)
        setIsLoad(false)
      const {error, errorMessage, affected, token} = response.data
      if(parseInt(error)<0){
        setUpdateMessage({error:error,message:errorMessage,affected:0})
        localStorage.removeItem('token')
        setToken(false)
      }else{
        setUpdateMessage({error:error,message:errorMessage,affected:parseInt(affected)})
        if(parseInt(error)===0){
            //const {user} = response.data
            setChPass(false)
            setCurrentUserPass(null)
            //setUser(user)
        }
        
        
        localStorage.setItem('token',token)
        setToken(token)
        setIsLoad(true)
      }
      
    })

}

useEffect(() => {
    if(!chPass){
    setNewUserPass(null)
    setVerifyNewUserPass(null)
    }
}, [chPass])


    useEffect(()=>{
        const tkn=localStorage.getItem('token')||false
        const headers= {
          'Content-Type':'application/json',
          'Authorization': `Bearer ${tkn}`,
        }
        axiosAPI.post("users.php",{accountid:uid, mode:"ACC"}, {headers})
        .then(response=>{
            //console.log("user response:", response.data)
          const {error, errorMessage, token} = response.data
          if(parseInt(error)<0){
            setUpdateMessage({error:error,message:errorMessage,affected:0})
            localStorage.removeItem('token')
            setToken(false)
          }else{
            if(parseInt(error)===0){
                const {user, specialty, gender} = response.data
                const spOpt=specialty.map(sp=>({value:sp.id, label:sp.specialty}))
                const genderOpt=gender.map(g=>({value:g.id, label:g.des}))

                setSpecialtyOptions(spOpt)
                setgenderOptions(genderOpt)
                setUser(user)
            } else{
                setUpdateMessage({error:error,message:errorMessage,affected:0})
            }
            localStorage.setItem('token',token)
            setToken(token)
            setIsLoad(true)
          }
          
        })
      },[setToken, uid])

      useEffect(() => {
        if(updateMessage.message){
            const err = parseInt(updateMessage.error) === 0 ? 500:3000
            timerID.current = setTimeout(()=>{
                setUpdateMessage({error:0,message:"",affected:0})
            },err)
         }
        return () => {
              clearTimeout(timerID.current)
          }
      }, [updateMessage])

      const customStyles = {
        control: (base, state) => ({
            ...base,
            paddingTop:'0.4em',
            paddingBottom:'0.4em',
            boxShadow: state.isFocused ? 0 : 0,
            borderColor: state.isFocused
            ? brandColor
            : base.borderColor,
            '&:hover': {
            borderColor: state.isFocused
                ? brandColor
                : base.borderColor,
            }
        })
    }
    




    
    
    if(user)
        return (
            <div className="container">
                <header className="header"> 
                    <h4>{getSection(currentSection)}</h4>
                </header>
                <ul className="side-bar">
                    <li className={`${currentSection===1?`active-section`:false}`} onClick={(e)=>sectionClick(e,1)}>{getSection(1)}</li>
                    <li className={`${currentSection===2?`active-section`:false}`} onClick={(e)=>sectionClick(e,2)}>{getSection(2)}</li>
                    <li className={`${currentSection===3?`active-section`:false}`} onClick={(e)=>sectionClick(e,3)}>{getSection(3)}</li>
                </ul>
                <div className="content">
                    <div className={`section ${currentSection===1?'current':''}`}>
                        <div className="left-side">
                            <label htmlFor="surname">Επώνυμο</label>
                            <input type="text" name="surname" id="surname"  value={user.surname||''} onChange={(e)=>handleChange(e)} />
                            {/*<div style={{backgroundColor:"red",color:"#fff",width:"55%",maxWidth:"75%",position:"relative"}}>error</div> */}
                            
                            <label htmlFor="first_name">Επώνυμο</label>
                            <input type="text" name="first_name" id="first_name"  value={user.first_name||''} onChange={(e)=>handleChange(e)} />
                            
                            <label htmlFor="father_name">Όνομα Πατέρα</label>
                            <input type="text" name="father_name" id="father_name" value={user.father_name||''} onChange={(e)=>handleChange(e)} />

                            <label htmlFor="gender_id">Φύλο</label>
                            <Select 
                                name='gender' 
                                id="gender_id"
                                options={genderOptions} 
                                isClearable={true}
                                placeholder={'Επιλέξτε ...'}
                                isMulti={false}
                                value={genderOptions.find(x=>parseInt(x.value) === parseInt( user.gender_id))}
                                onChange={(e)=>setUser(prev=>({...prev, gender_id:e?e.value:null}))}
                                noOptionsMessage={()=>noOptionsText}
                                className="sel-field"
                                styles={customStyles}
                            />
                            
                        </div>
                        <div className="right-side">

                            <label htmlFor="userafm">Α.Φ.Μ.</label>
                            <input type="text" name="afm" id="userafm" value={user.afm||''} onChange={(e)=>handleChangeNumber(e)} />
                            
                            <label htmlFor="useramka">Α.Μ.Κ.Α.</label>
                            <input type="text" name="amka" id="useramka" value={user.amka||''} onChange={(e)=>handleChangeNumber(e)} />
                            
                            <label htmlFor="specialty_id">Ειδικότητα</label>
                            <Select 
                                name='specialty' 
                                id="specialty_id"
                                options={specialtyOptions} 
                                isClearable={true}
                                placeholder={'Επιλέξτε ...'}
                                isMulti={false}
                                value={specialtyOptions.find(x=>parseInt(x.value) === parseInt( user.specialty_id))}
                                onChange={(e)=>setUser(prev=>({...prev, specialty_id:e?e.value:null}))}
                                noOptionsMessage={()=>noOptionsText}
                                className="sel-country"
                                styles={customStyles}
                                menuPlacement="auto"
                            />
                            <label>Φορέας</label>
                            <div className="pass-field">{user.issuer||''}</div>
                        </div>
                    </div>
                    <div className={`section ${currentSection===2?'current':''}`}>
                        <div className="left-side">
                            <label htmlFor="usertel">Τηλέφωνο</label>
                            <input type="text" name="tel" id="usertel" value={user.tel||''} onChange={(e)=>handleChange(e)} />
                            
                            <label htmlFor="contact_email">e-mail Επικοινωνίας</label>
                            <div className="pass-field">{user.contact_email||'-'}</div>
                        </div>
                        <div className="right-side">
                                
                        </div>
                    </div>
                    <div className={`section ${currentSection===3?'current':''}`}>
                        <div className="left-side">
                            <label>Όνομα χρήστη</label>
                            <div className="pass-field">{user.user_name||''}</div>
                            <label>τρέχων Κωδικός Πρόσβασης</label>
                            <div className="pass-field">{user.user_pass ?"* * * *":'Δεν υπάρχει'}</div>
                             
                            {user.user_pass?<span className='ch-pass-btn' onClick={(e)=>showCreatePass(e)}>{!chPass ? 'Αλλαγή κωδικού':'Άκυρο'}</span>:''
                            /*<FontAwesomeIcon icon={['fas', 'exchange-alt']}  className="icons icon-sm" onClick={(e)=>setChPass(c=>!c)}></FontAwesomeIcon>*/
                            }
                            <label>Τρόπος Σύνδεσης</label><span>{user.connection_mode ? `${user.connection_mode}FA` :'-'}</span>
                            <label htmlFor="recovery_email">e-mail Επαναφοράς</label>
                            <input type="text"  name="recovery_email" id="recovery_email" value={user.recovery_email||''} onChange={(e)=>handleChange(e)} onKeyDown={(e)=>handleKeyPress(e)} />
                        </div>
                        <div className="right-side">
                            {
                                chPass?
                                <>
                                    {/*
                                    <label htmlFor='currentUserPass'>τρέχων Κωδικός Πρόσβασης</label>
                                    <input type="password" name="currentUserPass"  id="currentUserPass"  value={currentUserPass||''}   onChange={(e)=>handleChangeNewPass(e)}/>
                                    */}
                                    <CreatePass updatePass={false}  updateUser={passChange} />
                                    <label htmlFor='newUserPass'>Νέος κωδικός πρόσβασης</label>
                                    <input ref={passInput} type={inputType} name="newUserPass" id="newUserPass"   value={newUserPass||''}   onChange={(e)=>handleChangeNewPass(e)}/>
                                    <FontAwesomeIcon icon={['fas', 'eye']} className="icons" onMouseDown={(e)=>mouseDown(e)} onMouseUp={(e)=>mouseUp(e)}/>
                                    <FontAwesomeIcon icon={['fas', 'copy']} className="icons"  onClick={()=>copyField(newUserPass)}/>
                                    <label htmlFor='verifyNewUserPass'>Επιβεβαίωση νέου κωδικού πρόσβασης</label>
                                    <input type="password" name="verifyNewUserPass"  id="verifyNewUserPass"  value={verifyNewUserPass||''}   onChange={(e)=>handleChangeNewPass(e)}/>
                                </>
                                :
                                ''
                            }
                        </div>                       
                    </div>
                   
                    {/*<button type="button" className='update-btn' name="btnUpdate" onClick={(e)=>updateAccount(e)}>Ενημέρωση</button> */}
                    <button type="button" className='update-btn' name="btnUpdate" onClick={
                        async ()=>{
                            const result = await CustomDialog(<CurrentPass />,{ showCloseIcon:true,  title:"Κωδικός Πρόσβασης",  isCanClose:false})
                            if(result){
                              //setCrrentUserPass(result)
                              updateAccount(result)
                            }
                        }}>Ενημέρωση</button>
                    <span className='ch-pass-btn' style={{marginLeft:'4em'}} onClick={(e)=>{navigate(-1)}}>Επιστροφή</span>
            
                </div>
                {updateMessage.message ? <pre className={`message-box ${getMessageClass(updateMessage.error, updateMessage.affected)}`}>{updateMessage.message}</pre>:''}
                {!isLoad?<Loader />:''}
       
            </div>

        )
    else
        return updateMessage.message ? <pre className={`message-box ${getMessageClass(updateMessage.error, updateMessage.affected)}`}>{updateMessage.message}</pre>:''
    
    
}
