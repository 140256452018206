import React, {useState, useEffect, useContext, useRef} from 'react'
import { AuthContext } from '../AuthContext'
import { Link } from 'react-router-dom'
import Pagination from './Pagination'
import { Confirm } from 'react-st-modal'
import axiosAPI from '../services/axiosapi'
import '../utils/fontawesome'
import {checkAMKA, getMessageClass} from "../utils/util"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import "../style/profilelist.css"

export default function ProfileList(props) {
    
    const { setToken}  = useContext(AuthContext)
    const searchFieds= {
        idSearch:null,
        statusSearch:100,
        surnameSearch:null, 
        firstNameSearch:null,
        folderNumberSearch:null,
        amkaSearch:null,
        rpp:50,
        page:1
    }

    const timer=useRef(null)
    

   // console.log("search",searchFields )
    const optionNum=[5,10,20,50,100,200,500]
    const columns=[
        {text:'#',cn:'aa'},
        {text:'ID',cn:'rj'},
        {text:'Ονοματεπώνυμο',cn:null},
        {text:'r/v',cn:null},
        {text:'',cn:null},
        {text:'ΑΜΚΑ', cn:null},
        {text:'Φύλο',cn:null},
        {text:'Ηλικία',cn:'rj'},
        {text:'Αρ. Φακέλλου',cn:'rj'},
        {text:'Τηλέφωνο',cn:null},
        {text:'Πράξεις',cn:'rj'},
        {text:'Κατάσταση',cn:null},
        {text:'Διαγραφή',cn:null}
        
        
    ]
    
    //const [search, setSearch]=useState(sessionStorage.getItem('search')?JSON.parse(sessionStorage.search):initSearch())
    const [search, setSearch]=useState(sessionStorage.getItem('search')?JSON.parse(sessionStorage.search):searchFieds)
    const [cid] = useState(sessionStorage.getItem('cid')||null)
    const [profile, setProfile]=useState(null)
    const [totalRows, setTotalRrows] =useState(0) 
    const [go, setGo]=useState(0)
    const [updateMessage,setUpdateMessage]=useState({error:0,message:'', affected:0})
    //const [scrollPos, setScrollPos]=useState(sessionStorage.getItem('pos')||0)
    
    const options=optionNum.map(op=>
        <option key={`ok_${op}`} value={op}>{op}</option>
        )
    const columnsHeader= columns.map((c,i)=><th key={`ck_${i+1}`} className={c.cn}>{c.text}</th>)
   
    useEffect(() => {
     setGo(1)
     return () => {
        if(timer.current)
            clearTimeout(timer.current)
        }
    },[])

    useEffect(()=>{
        let t=null
        if(updateMessage.message){
            const err = parseInt(updateMessage.error) === 0 ? 500:3000
            t = setTimeout(()=>{
            setUpdateMessage({error:0,message:"",affected:0})
            },err)
        }
        return ()=>{clearTimeout(t)}
    },[updateMessage])

    


    useEffect(() => {
        //console.log("go")
        if(go){
            if(go===2)
               window.scrollTo(0,0)

            sessionStorage.setItem('search', JSON.stringify(search))
            const tkn=localStorage.getItem('token')||false
            const headers= {
                'Content-Type':'application/json',
                'Authorization': `Bearer ${tkn}`,
              }
            axiosAPI.post("profile.php",{search:search},{headers})
            .then(response=>{
                const {contacts, error, token} = response.data
                //console.log("contacts", response)
                if(parseInt(error) < 0){
                    localStorage.removeItem('token')
                    setToken(false)
                }else if(parseInt(error) === 0){
                    //console.log("contacts:", contacts)
                    setProfile(contacts)
                    //const goVar = go
                    setGo(0)
                    //setSearch(prev=>({...prev, "page":response.data.page}))

                    setTotalRrows(response.data.totalRows)
                    localStorage.setItem('token',token)
                    setToken(token)
                    if(go===1)
                        handleScroll()
                    setGo(0)
                }
                
            })
        }
    },[go, search, setToken])
    


    function handleScroll(){
        const position = sessionStorage.getItem("pos")
        if(position){
            timer.current = setTimeout(()=>{
                window.scrollTo(0,parseInt(position))
                sessionStorage.removeItem("pos")
            },0)  
        }
    }


    function handleChange(ev, goSearch=false){
        
        const {name,value}=ev.target
        //console.log(name, value)
        const  res =  name==="folderNumberSearch" && isNaN(value) ? null :value||null
        if(name==='rpp'){
            setSearch(prev=>({...prev,[name]:res, page:1}))
        }else{
            setSearch(prev=>({...prev,[name]:res}))
        }
        if(goSearch){
            //console.log("ok")
            setGo(2)
        }
        
    }

    function deleteContact(id){
        const tkn=localStorage.getItem('token')||false
        const headers= {
            'Content-Type':'application/json',
            'Authorization': `Bearer ${tkn}`,
        }
        axiosAPI.post("profile.php",{mode:"DELCON", contactId:id},{headers})
        .then(response=>{
            const {error, message, affected, token}=response.data
            if(parseInt(error)<0){
                setUpdateMessage({error,message,affected:affected})
                localStorage.removeItem('token')
                setToken(false)
              }else{
                localStorage.setItem('token',token)
                setToken(token)
                setUpdateMessage({error,message,affected})
                storeLocal(id)
                setGo(1)
              }
        })  
    }

    function enableDisableContact(id, profStatus){
        const checked = parseInt(profStatus) === 0 ? 1:0
        
        const tkn=localStorage.getItem('token')||false
        const headers= {
            'Content-Type':'application/json',
            'Authorization': `Bearer ${tkn}`,
        }
        axiosAPI.post("profile.php",{mode:"ENDISCON", contactId:id, contactStatus:checked},{headers})
        .then(response=>{
            const {error, message, affected, token}=response.data
            if(parseInt(error)<0){
                setUpdateMessage({error,message,affected:affected})
                localStorage.removeItem('token')
                setToken(false)
              }else{
                localStorage.setItem('token',token)
                setToken(token)
                setUpdateMessage({error,message,affected})
                storeLocal(id)
                setGo(1)
              }
        })  
    }

    function downloadProfile(pid){
        const tkn=localStorage.getItem('token')||false
        const headers= {
        'Authorization': `Bearer ${tkn}`,
        'Content-Type' : "application/json",
        }
        axiosAPI.post("report.php",{reportProfileID:pid,mode:"PFLRP" },{headers})
        .then(response=>{
          //console.log(response.data)
          const {error, message, affected, token}=response.data
          if(parseInt(error)<0){
              setUpdateMessage({error,message,affected:affected})
              localStorage.removeItem('token')
              setToken(false)
          }else {
            localStorage.setItem('token',token)
            setToken(token)
            setUpdateMessage({error,message,affected})
            if(parseInt(error)===0){
              const {tempFile, fileName, ext} =response.data
              //console.log(tempFile,' ', fileName,'.', ext)
              const newURL = `${axiosAPI.defaults.baseURL}download.php?t=${tempFile}&f=${fileName}&e=${ext}`;
              window.location.replace(newURL)
            } 
          }
    
        })
      }
    
    
    function getSearch(ev){

       setSearch(prev=>({...prev, page:1}))
       setGo(2)
    }
    function clearSearch(ev){
        //setSearch(initSearch())
        setSearch(searchFieds)
        sessionStorage.removeItem('search')
    }

    function storeLocal(id){
        sessionStorage.setItem('pos', window.scrollY)
        sessionStorage.setItem('search', JSON.stringify(search))
        sessionStorage.setItem("cid",id )
    }


    
    function changePage(ev){
        const {value}=ev.target
        setSearch(prev=>({...prev,page:value}))
        setGo(2)
        
    }


    //console.log("search", search)
    const deleteMessage="Διαγράφοντας την Επαφή θα διαγραφούν όλες οι Πράξεις αυτής της Επαφής.\n Να Διαγραφεί η Επαφή ?"
    const noData=<tr><td colSpan={columns.length}>Δεν υπάρχουν εγγραφές</td></tr>
    const loadData=<tr><td colSpan={columns.length}>Load...</td></tr>
    const tableFields = profile && profile.length>0 ? profile.map((contact, i)=>{
        const gender = contact.gender_id ? contact.gender_id===21?'Άνδρας':'Γυναίκα':"-"
        const age = contact.birth_year? new Date().getFullYear() - parseInt(contact.birth_year) : '-'
        return <tr key={`pl_${contact.id}`} className={parseInt(cid)===contact.id?'last-visit':''}>
            <td className="aa" >{(search.page - 1) * search.rpp + (i+1)}</td>
            <td className="rj">{contact.id}</td>
            <td><Link to={`/contact/${contact.id}`} onClick={()=>storeLocal(contact.id)}>{contact.surname} {contact.first_name}</Link></td>
            <td className='cnt'>
                <Link to={`/appointment/new/${contact.id}`} onClick={()=>storeLocal(contact.id)}>
                    <FontAwesomeIcon icon={['fas', 'calendar-check']}  className="icon-sm-mail" />
                </Link>
            </td>
            <td><FontAwesomeIcon icon={['fas', 'download']}  className="icon-sm-mail" onClick={()=>downloadProfile(contact.id)}/></td>
            <td>{contact.amka} {!checkAMKA(contact.amka)?<FontAwesomeIcon icon={['fas', 'exclamation-triangle']} className='warn' />:''}</td>
            <td>{gender}</td>
            <td className="rj">{age}</td>
            <td className="rj">{contact.folder_number}</td>
            <td>{contact.tel}</td>
            <td className="rj">{contact.actions}</td>
            <td className="cnt">
                {contact.profile_status === 1 ?
                    <FontAwesomeIcon icon={['fas', 'user-check']}  className="icon-sm-mail" onClick={()=>enableDisableContact(contact.id, contact.profile_status)}/>
                :
                    <FontAwesomeIcon icon={['fas', 'user-slash']}  className="icon-sm-mail" style={{color:'#a00000'}} onClick={()=>enableDisableContact(contact.id, contact.profile_status)}/>
                }
                    {/* <label className="switch" style={{top:'-0.4rem'}}>
                    <input  type="checkbox" name="profile_status"  checked={parseInt(contact?.profile_status)!==0?true:false} onChange={(e)=>enableDisableContact(contact.id, e)}/>
                    <span className="sw-slider round"></span>
                </label>*/}
            </td>
            <td className="cnt">
                <FontAwesomeIcon icon={['fas', 'trash-alt']} className="icon-sm-mail"  onClick={async () => {
                            const result = await Confirm(deleteMessage, 
                            'Διαγραφή Επαφής', 'Ναι', 'Όχι')
                            if (result) {
                                deleteContact(contact.id)
                            } 
                      }}/>
                
                </td>
        </tr>
    }):!profile ? loadData:noData
    return (
        <div className="list-container">
            
            <table className="list-comp">
                <thead>
                    <tr>
                        <td colSpan={columns.length} className="search-col">
                            <div className="search-form">
                                <div>
                                    <span>Επαφές - Aναζήτηση </span><FontAwesomeIcon icon={['fas', 'eraser']}  className="icons icon-sm" onClick={(e)=>clearSearch(e)}/>
                                </div>
                                <ul>
                                <li>
                                    <label>ID</label>
                                    <input type="text" name="idSearch" value={search.idSearch||''} onChange={(e)=>handleChange(e)} style={{width:'100%', color:'#634a97', backgroundColor:'#f8f8f8'}}/>
                                </li>
                                <li>
                                    <label>Κατάσταση</label>
                                    <select name="statusSearch" value={search.statusSearch} onChange={(e)=>handleChange(e)} style={{fontSize:'1.2em',padding:'0.2em',color:'#634a97', backgroundColor:'#f8f8f8',border:'1px solid var(--bd-color)'}}>
                                        <option value={100}>Όλοι</option>
                                        <option value={1}>Ενεργοί</option>
                                        <option value={0}>Ανενεργοί</option>
                                    </select>
                                </li>
                                <li>
                                    <label>Επώνυμο</label>
                                    <input type="text" name="surnameSearch" value={search.surnameSearch||''} onChange={(e)=>handleChange(e)}/>
                                </li>
                                <li><label>Όνομα</label>
                                    <input  type="text" name="firstNameSearch" value={search.firstNameSearch||''} onChange={(e)=>handleChange(e)} />
                                </li>
                               
                                <li><label>ΑΜΚΑ/ΠΑΑΥΠΑ</label>
                                    <input type="text" name="amkaSearch"   value={search.amkaSearch||''} onChange={(e)=>handleChange(e)}/>
                                </li>
                                
                                <li><label>Αρ. Φακέλλου</label>
                                    <input type="text" name="folderNumberSearch"  className="sm" value={search.folderNumberSearch||''} onChange={(e)=>handleChange(e)}/>
                                </li>
                                
                                {/*
                                <li style={{alignSelf:'end'}}>
                                    <FontAwesomeIcon icon={['fas', 'times']}  className="icons" />
                                </li>
                                */}
                                <li style={{alignSelf:'center'}}>
                                    <FontAwesomeIcon icon={['fas', 'search']}  className="icons" onClick={(e)=>getSearch(e)}/>
                                </li>
                                <li>
                                    <Link to={`/contact/-1`} onClick={()=>storeLocal(-1)}>
                                        <FontAwesomeIcon icon={['fas', 'user-plus']}  className="icons" />
                                    </Link>
                                </li>
                                </ul>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        {columnsHeader}
                    </tr>
                </thead>
                <tbody>
                    {tableFields}
                </tbody>
                <tfoot>
                   
                    <tr>
                        <td colSpan={columns.length}>
                            <ul>
                                <li>
                                    Εγγραφές / Σελίδα:
                                    <select name="rpp" value={search.rpp} onChange={(e)=>handleChange(e, true)}>
                                        {options}
                                    </select>
                                </li>
                                <li>
                                    {totalRows 
                                        ?
                                        `Βρέθηκαν: ${totalRows} εγγραφές. Σελίδα: ${search.page} / ${Math.ceil(totalRows/search.rpp)}` 
                                        :''
                                    }
                                     
                                </li>
                                <li>
                                    <Pagination totalRows={totalRows} currentPage={parseInt(search.page)} rpp={parseInt(search.rpp)} changePage={changePage}/>
                                </li>
                            </ul>
                        </td>
                    </tr>
                </tfoot>

            </table>
            { 
                updateMessage.message ? 
                <div className={`message-box ${getMessageClass(updateMessage.error, updateMessage.affected)}`}>{updateMessage.message}</div>
                :
                ''
            } 



        </div>
    )
}
