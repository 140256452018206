//import logo from './logo.svg';
import React, { useState, useContext, useEffect, useCallback, useRef} from "react"
import {Routes, Link, Route, Navigate} from 'react-router-dom'
import Profile from "./components/Profile"
import ProfileList from "./components/ProfileList"
import ActionList from "./components/ActionList"
import ActionForm from "./components/ActionForm"
import UserList from "./components/UserList"
import Login from "./components/Login"
import Gauth from "./components/Gauth"
import User from "./components/User"
import MyAccount from "./components/MyAccount"
import ChangeCity from "./components/ChangeCity"
import Report from "./components/Report"
import RendezvousScheduler from "./components/RendezvousScheduler" 
//import SchedulerDay from "./components/SchedulerDay"
//import AppointmentList from "./components/AppointmentList"
import Appointment from "./components/Appointment"
import RendezvousSearch from "./components/RendezvousSearch"
import {AuthContext} from "./AuthContext"
import { decodeToken, remainColor } from "./utils/util"
import './utils/fontawesome'
import axiosAPI from './services/axiosapi'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './App.css'
//import ActionItem from "./components/ActionItem"
//import { set } from "date-fns"

function App() {
 // const prefix="/demo/"
  //const path="/contact/"
  //const location = useLocation()
  //const history = useHistory()

  const sInterval = useRef(null)
  //const counter =useRef(0)
  const {token, setToken} = useContext(AuthContext)
  
  const payload = decodeToken(token)

  const [remain, setRemain]=useState(null)
  

  
  function logout(ev){
    //ev.preventDefault()
    sessionStorage.clear();
    localStorage.removeItem('token')
    setToken(false)
  }

  function  getRights(){
    //const tkn=localStorage.getItem('token') || false
    const headers= {
          'Content-Type':'application/json',
          'Authorization': `Bearer ${token}`,
      }
      if(payload && payload.userID){
        axiosAPI.post("refresh.php",{user:payload.userID}, {headers})
          .then(response=>{
            const {error, errorMessage, token} = response.data
            if(parseInt(error)<0){
              //setMessage({error:error,message:errorMessage})
              localStorage.removeItem('token')
              setToken(false)
            }else if(parseInt(error)===0){
              localStorage.setItem('token',token)
              setToken(token)  
            }else{
              console.log(error, errorMessage)
            }
          })
      }
      //console.log("refresh")
  }


  

   
   const unlfn= useCallback((ev)=>{
   
    const tkn=localStorage.getItem('token') || false
    //console.log("tokenFocus:",tkn)
    setToken(tkn)
    
  },[setToken])

  useEffect(() => {
    window.addEventListener('focus',(unlfn))
    return () => {
      window.removeEventListener('focus', unlfn)
    }
  }, [unlfn])
/*
  useEffect(() => {
    if(remain <0 ){
      localStorage.removeItem('token')
      setToken(false)
    }
  }, [remain,setToken])
  */
  useEffect(() => {
    sInterval.current = setInterval(()=>{
        const tkn=localStorage.getItem('token')||false 
        if(tkn){
          const {exp} = decodeToken(tkn)
          const remainVal = Math.floor(exp - Date.now()/1000)
          //console.log("remain:", remainVal , ms)
          setRemain(remainVal>=0?remainVal:0)
          if(remainVal<0){
            //clearInterval(sInterval.current)
            // TODO REFRESH
            localStorage.removeItem("token")
            setToken(false)
          }
        }
    },1000)
    return () => {
      clearInterval(sInterval.current)
      //console.log('clear')
    }
  }, [remain,setToken])

  

  
  //console.log('payload', payload)
  if(payload  && payload.connection_status==="LOGON" ){
    return(
      <div className="app-container">
        <nav> 
          <Link to="/">Αρχική</Link>  
          {(payload.rights.includes(5) || payload.rights.includes(7))  && payload.ucityID? <Link to="/users">Χρήστες</Link> :''  }
          {payload.rights.includes(1) && payload.ucityID ?  <Link to="/contacts">Επαφές</Link> :''  }
          {payload.rights.includes(2) && payload.ucityID ?  <Link to="/actions">Πράξεις</Link> :''  }
          {payload.rights.includes(4) && payload.ucityID ?  <Link to="/report">Αναφορές</Link> :''  }
          {/*payload.rights.includes(3) && payload.ucityID ?  <Link to="/appointments">Ραντεβού</Link> :''  */}
          {payload.rights.includes(3) && payload.ucityID ?  <Link to="/rendezvous">Ραντεβού</Link> :''  }
          <span className="remain-des">{remain ? "Απομένουν":''}</span>
          <span className={`${ remain ? remainColor(remain):''} ${remain <= 60?'blink_me':''}`}>
            {remain ? ` ${Math.floor(remain/60).toString().padStart(2,'0')}:${(remain % 60).toString().padStart(2,'0')}`:''}
            {remain <= 120 ? 
            <FontAwesomeIcon icon={['fas', 'sync-alt']} className={`icon-remain ${remain?remainColor(remain,'i'):''}`} spin onClick={getRights}/>
            :
            <FontAwesomeIcon icon={['fas', 'sync-alt']} className={`icon-remain ${remain?remainColor(remain,'i'):''}`} onClick={()=>getRights()}/>
            }     
          </span>
          {<span className="city">{payload.ucity} - {payload.issuer}</span>}
          <Link  to="/login" onClick={(e)=>logout(e)}>Αποσύνδεση</Link>
        </nav>

        <Routes>
          <Route  path="/" element={
          <>
              <h3>  {payload.uinfo} ({payload.usp}) {payload.ucity} {payload.issuer}</h3>
              {payload.rights.includes(6) ? 
              <ChangeCity  city={payload.ucityID || 0}/>
              :""}
              <div >
                {/*style={{marginBottom:'4rem'}} */}
                <Link to="/account" className="btn-link">Ο Λογαριασμός μου</Link>
              </div>
              {<RendezvousScheduler userId={payload.userID} />}
              {/*<AppointmentList restrict={payload.userID} />*/}
            </>}
          />

          
          <Route path="/users" element={(payload.rights.includes(5,0) || payload.rights.includes(7,0)) && payload.ucityID? <UserList admin={payload.rights.includes(5)}  />:<Navigate to="/" />} />
          <Route  path="/user/:id"
            element={(payload.rights.includes(5,0)  || payload.rights.includes(7,0)) && payload.ucityID ?<User admin={payload.rights.includes(5)}  />:<Navigate to="/" />} 
          />
          <Route  path="/contacts"
            element={payload.rights.includes(1,0) && payload.ucityID? <ProfileList />:<Navigate to="/" />}
          />
          <Route  path="/contact/:id"
            element={payload.rights.includes(1,0) && payload.ucityID ? <Profile />:<Navigate to="/" />}
          />
          <Route  path="/actions"
            element={payload.rights.includes(2,0) && payload.ucityID ? <ActionList/>:<Navigate to="/" />}
          />
          <Route  path="/action/:id" 
            element={payload.rights.includes(2,0) && payload.ucityID ? <ActionForm/>:<Navigate to="/" />}
          />

          <Route  path="/report"
            element={payload.rights.includes(4,0) && payload.ucityID ? <Report admin={payload.rights.includes(6,0)}/>:<Navigate to="/" />}
          />
        
          {/*
          <Route path="/appointments">
            {payload.rights.includes(3,0) && payload.ucityID?
            <AppointmentList/>:<Redirect to="/" />}
          </Route>
          */}
          <Route path="/rendezvous" 
            element={payload.rights.includes(3,0) && payload.ucityID ? <RendezvousSearch />:<Navigate to="/" />}
          />
           
          
          {/*
          <Route path="/scheduler-day/:day">
            {payload.rights.includes(3,0) && payload.ucityID?
            <SchedulerDay/>:<Redirect to="/" />}
          </Route>
          */}
          <Route path="/appointment/:id/:pflid?" 
            element={payload.rights.includes(3,0) && payload.ucityID ? <Appointment/>:<Navigate to="/" />}
          />
          

          <Route  path="/account" element={<MyAccount uid={payload.userID}/>} />
          <Route  path="/login" element ={<Navigate to="/" />}/>
        </Routes>
        {/*<span className={`remain ${remain<= 10 ? 'timeout':''}`}>{remain ? `Aπομενουν ${Math.floor(remain/60)}:${remain % 60}`:''}</span>*/}
      </div>

    )
  }
  return (
    <div className="app-container">
       <Routes>
          <Route  path="/login" element={<Login />} />
          <Route  path="/gauth/:key" element={<Gauth />} />
          <Route  path="/" element={<Navigate to="/login" />} />
          <Route  path="/*" element ={<Navigate to="/" />}/>
        </Routes>
     
    </div>
  )
}
export default App;
