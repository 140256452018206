import React, {useState, useEffect, useContext, memo, useRef} from 'react'
import { useParams, useNavigate, useLocation, Link } from 'react-router-dom'
import DatePicker ,{registerLocale, setDefaultLocale} from 'react-datepicker'
import {el} from 'date-fns/locale/'
import RadioField from "./RadioField"
import CheckBoxField from "./CheckBoxField"
import SelectField from "./SelectField"
//import MonitoredList from "./MonitoredList"
//import { v4 as uuidv4 } from 'uuid'
//import Modal from "./Modal"
//import ModalFamily from "./ModalFamily"
//import Confirm from "./Confirm"
import axiosAPI from '../services/axiosapi'
//import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock' 
import '../style/profile.css'
import InputMask from 'react-input-mask'
import "react-datepicker/dist/react-datepicker.css"
import FamilyList from './FamilyList'
import {country2emoji2, getMessageClass, checkAMKA} from '../utils/util'
import ActionDependField from "./ActionDependField"
import DiagnosisList from "./DiagnosisList"
import HistoryListComponents from "./HistoryListComponents"
import RequestListComponent from "./RequestListComponent"
import ActionProfileList from './ActionProfileList'
import EditTextarea from './EditTextarea'
import Action from './Action'
import Loader from "./Loader"
import { AuthContext } from '../AuthContext'
import '../utils/fontawesome'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

//import { localeIncludes } from 'locale-includes'

registerLocale('el',el)
setDefaultLocale('el')


export default memo(function Profile(props) {
    
  const { setToken}  = useContext(AuthContext)
  const {id}=useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const [profile, setProfile]=useState(null)
  const [isLoad,setIsLoad]=useState(false)
  const [currentSection, setCurrentSection]=useState(1)
  const [updateMessage,setUpdateMessage]=useState({error:0,message:'', affected:0})
  const [editAction, setEditAction]=useState(0)
  const [pos, setPos] = useState(0)
  const [currentAction, setCurrentAction] = useState(0)
  const [edit, setEdit]=useState(0)
  const [inProgress, setInProgress] = useState(false)
  const listPoint = useRef(null)
  const position = useRef(null)

   


   
    function getFlag(){
      const org = profile.contact.nationality_id ? profile.countries.find(c=>c.id===profile.contact.nationality_id):null
      //console.log("coyntry ", org) 
      return org?country2emoji2(org.code2):null;
    }
    
    function closeEdit(){
      setEdit(0)
      window.scrollTo(0, position.current)
    }
    function openEdit(val){
      position.current = window.scrollY
      setEdit(val)

    }
    function handleChange(e,tbl=[]){ 
        let clear={}
        let value=e.target.value
        const patternAmka = /^\d{0,11}$/
        const patternYear = /^\d{0,4}$/
        const patternNumber = /^\d{0,9}$/
        const patternFloat = /^[+-]?\d{0,3}(\.?\d{0,1})?$/ // /\-?\d+\.\d+/
        const numFields = ['times_hosp', 'suicide_attempts']
        if(e.target.type==='radio' && tbl.length > 0){
            clear =tbl.reduce((a, i) => Object.assign(a, {[i]:null}), {})
           // console.log("val:",value)
        }else if(e.target.type==='checkbox'){
          value=e.target.checked?1:0
        }else if((e.target.name==='amka' || e.target.name==='doctor_amka' )  && !patternAmka.test(value) && value!==""){
            return
        }else if(e.target.name==='birth_year' && !patternYear.test(value) && value!==""){
          return
        }else if(numFields.includes(e.target.name) && !patternNumber.test(value) && value!==""){
          return
        }else if(e.target.name==='duration_hosp' && !patternFloat.test(value.replace(",",".")) && value!==""){
          return
        }
        const val = e.target.name==='duration_hosp'?value.replace(",","."):value
        setProfile(prev =>({...prev, contact:Object.assign({...prev.contact,[e.target.name]:val||null},clear)}))
    }

    function handleChangeAge(field, e){
     
      const nowYear = new Date().getFullYear()
      const birthYear = e.target.value ? nowYear - e.target.value:null
      
      setProfile(prev=>({...prev, contact:{...prev.contact, [field]:birthYear||null }}))
      
    }

    function showAge(field=''){
        return new Date().getFullYear() - parseInt(profile.contact[field]) || ''
    }


    function handleCheckBoxChange(e, id=0, field=''){
      if(e.target.type==='checkbox'){
        
        const newArr =!e.target.checked ?
        profile[e.target.name].filter(item => item.option_id!==parseInt(e.target.value))
        :
        [...profile[e.target.name], {option_id:parseInt(e.target.value),des:null}]
        setProfile(prev=>({...prev,[e.target.name]:newArr }))
      } else{
        const newArr = profile[field].map(item => item.option_id===id ?{...item, [e.target.name]:e.target.value}:item)
        setProfile(prev=>({...prev,[field]:newArr}))
      }
      
    }
    function handleStatusChange(ev){
      const {name, checked} = ev.target
      setProfile(prev =>({...prev, contact:Object.assign({...prev.contact, [name]:checked?1:0})}))
    }
  
  function handleChangeDate(obj, field, value){
    if(obj==='contact'){
     
       //const  dateVal  = value ? new Date(value.getTime() + (value.getTimezoneOffset() * 60000) + (3600000*4)):null 
       //console.log(value , "--" , dateVal ? dateVal.toJSON(): null)
       const  dateVal  = value ? value.toLocaleDateString('fr-CA') :null
       //console.log(value , "--" , dateVal)
       
      
    
       setProfile(prev=>({...prev, contact:{...prev.contact, [field]:dateVal}}))
      //setProfile(prev=>({...prev, contact:{...prev.contact, [field]:dateVal? dateVal.toJSON().substring(0,10):null}}))
    }else if(obj==='remain_status'|| 'greek_knowlege_level'){
      const gk=field
      //const dateVal=value||null
      //const dateVal=value?new Date(value).toLocaleDateString('el-GR'):null
      const dateVal=value?value.toJSON():null
      const items=profile[obj].map(i=>i.groupkey===gk?{...i, start_date:dateVal}:i)
      setProfile(prev => ({...prev,[obj]:items}))
    }
  }

  function clearClick(e, tbl=[]){
    e.preventDefault()
    const clear =tbl.reduce((a, i) => Object.assign(a, {[i]:null}), {})
    setProfile(prev =>({...prev, contact:Object.assign({...prev.contact},clear)}))
    return false
  }

  function sChange(v, obj, field, i=null){
    //console.log('i:',i)
    if(obj==='contact'){
      
      const arr = ['m_unit_id', 'info_source_id']
      if(arr.includes(field)){
        //const opt = v? profile.metadata.filter(i=>i.field===field).find(i=>parseInt(i.id)===parseInt(v.value)):null
        
        const fieldDes = `${field.substring(0, field.length - 2)}des` 
        setProfile(prev=>({...prev,[obj]:{...prev[obj], [field]:v?parseInt(v.value):null,[fieldDes]:null} }))
      }else{
        setProfile(prev=>({...prev,[obj]:{...prev[obj], [field]:v?parseInt(v.value):null} }))
      }
      
    }else if(obj==='children'|| obj==='brothers'){ 
      const items = profile[obj].map((it,indx)=>
          indx===i? {...it,[field]:v?parseInt(v.value):null}:it
        )
        setProfile(prev=>({...prev,[obj]:items}))
    }else if(obj==='occupation' ){
      const items = profile[obj].map((it,indx)=>
          indx===i? {...it,option_id:v?parseInt(v.value):null}:it
        )
        setProfile(prev=>({...prev,[obj]:items}))
    }
  }
  
  function getExtraFields(opt, fld, postFix=""){
    const extraComp =opt.map(item =>{
        const extra=item.extra?JSON.parse(item.extra):[]
        const extraFields = extra.map((i, j)=>{
            //console.log(i.field)
            const fieldName= `${i.field}${postFix}`
            return(
            profile.contact[fld] && profile.contact[fld] === item.id ? 
            <input type="text" className="details" key={`t${j}`} name={fieldName} value={profile.contact[fieldName]||''} onChange={(e)=>handleChange(e)} maxLength={255}/>
                :''
            )
        })
        return extraFields
    })
    return extraComp
}

function changeWithExtra(ev, extraField=null){
  const {name, value} = ev.target
  
  if(Array.isArray(extraField)){
    const clear =extraField.reduce((a, i) => Object.assign(a, {[i]:null}), {})
    setProfile(prev =>({...prev, contact:Object.assign({...prev.contact,[name]:value},clear)}))
  }else if(extraField){
    setProfile(prev=>({...prev, contact:{...prev.contact,[name]:value,[extraField]:null} }))
  }else{
    setProfile(prev=>({...prev, contact:{...prev.contact,[name]:value} }))
  }

}



function copyPasteField(ev, fromField=null, toField=null){
  const value = profile.contact[fromField]
  const valueTo = profile.contact[toField]
  if(!value || valueTo){
    return
  }
  setProfile(prev=>({...prev, contact:{...prev.contact, [toField]:value}}))
}


  function updateArrayFields(arr, field, key){
    //  TODO update
    //if(profile.id!==0)
    //console.log(arr, field, key)
    const actionField = {diagnosis:"action_diagnosis", 
                         symptoms:"action_symptoms", 
                         therapeutic_plan:"action_therapeutic_plan", 
                         medication:"action_medication"
                        }
                        
    const tempArr = profile[field].filter(i=>parseInt(i.action_id) !== parseInt(key))
    const newArr = [...tempArr, ...arr].sort((a,b)=>{
      if (a.option_id > b.option_id)
       return 1
      else if (a.option_id < b.option_id)
       return -1
      else
       return 0
    })
    if(profile.actions.find(a=>a.id===key)){
      const tempActArr = profile[actionField[field]].filter(i=>i.action_id !== key)
      const na = [...tempActArr, ...arr].sort((a,b)=>{
        if (a.option_id > b.option_id)
        return 1
        else if (a.option_id < b.option_id)
        return -1
        else
        return 0
      })
      setProfile(prev=>({...prev, [field]:newArr, [actionField[field]]:na}))
    }else{
      setProfile(prev=>({...prev, [field]:newArr}))
    }
    
    
  // console.log("new Array:", newArr)
   // setProfile(prev=>({...prev, [field]:newArr}))
  }

  function updateHistoryFields(field, result, act='u'){
    const index = profile[field].findIndex(f=>f.groupkey===result.groupkey)
    let newArr = [...profile[field]]
    if(index >=0 && act==='u'){
      newArr[index]=result
    }else if (index >=0 && act==='d'){
      newArr=newArr.filter(item=>item.groupkey!==result.groupkey)
    }else if(act==='i'){
      newArr=[...newArr, result]
    }
    newArr.sort((a,b)=>{
      if( a.start_date < b.start_date)
       return 1
      else if(a.start_date > b.start_date)
        return -1
      else
        return 0
    })
    setProfile(prev=>({...prev,[field]:newArr}))
    
  }

  function updateFamily(field, result, act='u', index=-1){
    let newArr=[...profile[field]]
    if(index >=0 && act==='u'){
      newArr[index]=result
    }else if (index >=0 && act==='d'){
      newArr=newArr.filter((item,i)=>i!==index)
    }else if(act==='i'){
      newArr=[...newArr, result]
    }
    
    newArr.sort((a,b)=>{
      if( a.birth_year < b.birth_year)
      return -1
      else if(a.birth_year > b.birth_year)
        return 1
      else
        return 0
    })
    //console.log('na', newArr)
    setProfile(prev=>({...prev,[field]:newArr}))

  }

  function requestUpdate(req=null, index=-1, act=''){
    let tempArr=[]
    tempArr=profile.request.map(r=>r)
    if(act==='u'){
      tempArr[index]=req
    }else if(act==='i'){
      tempArr=[...tempArr, req]
    }else if (act==='d'){
      tempArr = tempArr.filter(r=>r.id!==req.id)
    }
    if(act!=='d'){
      tempArr.sort((a,b)=>{
        if( a.request_date < b.request_date)
        return 1
        else if(a.request_date > b.request_date)
          return -1
        else
          return 0
      })
    }
    setProfile(prev=>({...prev, request:tempArr}))
  }

  function sectionClick(e,cs){
    e.preventDefault()
    setCurrentSection(cs)
  }

  function changeActionPage(ev, scroll=true){
    const {value} = ev.target
    const tkn=localStorage.getItem('token')||false
    const headers= {
      'Content-Type':'application/json',
      'Authorization': `Bearer ${tkn}`,
    }
    axiosAPI.post("profile.php",{
      actionListProfileId:id,
      page:parseInt(value)
    },{headers})
    .then(response=>{
      const {error, message, token}=response.data
      //console.log("resp",response)
      if(parseInt(error)<0){
        setUpdateMessage({error,message,affected:0})
        localStorage.removeItem('token')
        setToken(false)
      }else{
        localStorage.setItem('token',token)
        setToken(token)
        if(parseInt(error)===0){
          const {
            actions,
            actionTotalRows, 
            actionsRPP,
            action_symptoms, 
            action_diagnosis, 
            action_medication, 
            action_therapeutic_plan,
            action_participants,
            actionCurrentPage
          }=response.data
          //console.log(actionCurrentPage)
          setProfile((prev)=>({...prev, 
            actionCurrentPage:parseInt(actionCurrentPage),//page here....
            actions,
            actionTotalRows,
            actionsRPP,
            action_symptoms,
            action_diagnosis,
            action_medication,
            action_therapeutic_plan,
            action_participants
          }))
          if(scroll){
            window.scrollTo({
              top: listPoint.current.offsetTop-70, 
              left: 0,
              behavior: 'smooth'
            } )
          }
        }else{
          setUpdateMessage({error,message,affected:0})
        }
      } 
    })
  }

 /*  function findDoctor(drAmka){
    const tkn=localStorage.getItem('token')||false
    if(!drAmka){
      setUpdateMessage({error:0,message:"Το ΑΜΚΑ δεν είναι έγκυρο", affected:0})
      return
    }
    const headers= {
      'Content-Type':'application/json',
      'Authorization': `Bearer ${tkn}`,
    }
    axiosAPI.post("profile.php",{ doctorAmka:drAmka},{headers})
    .then(response=>{
      const {error, message, affected, token} = response.data
      if(parseInt(error)<0){
        setUpdateMessage({error,message,affected:affected})
        localStorage.removeItem('token')
        setToken(false)
      }else {
        localStorage.setItem('token',token)
        setToken(token)
        if(parseInt(error)===0 && affected > 0 ){
          const {doctor_name, doctor_sp} = response.data
          setProfile(prev=>({...prev, contact:Object.assign({...prev.contact, doctor_name, doctor_sp})}))
        }
        setUpdateMessage({error,message,affected})
      } 
    })
  } */

  useEffect(()=>{
    if(editAction===0){
      window.scrollTo(0,parseInt(pos))
    }
  },[editAction,pos])

  function saveAction(ev, actID){
    setEditAction(actID)     
  }

  function handleEditAction(act){
    setPos(window.pageYOffset)
    setEditAction(act)
    setCurrentAction(act)
  }

  function updateRecord(ev){
    setInProgress(true)
    const tkn=localStorage.getItem('token')||false
    const headers= {
      'Content-Type':'application/json',
      'Authorization': `Bearer ${tkn}`,
    }

    axiosAPI.post("updateprofile.php",{
        contact:profile.contact, 
        children:profile.children,
        brothers:profile.brothers,
        symptoms:profile.symptoms,
        therapeutic_plan:profile.therapeutic_plan, 
        diagnosis:profile.diagnosis,
        medication:profile.medication,
        occupation:profile.occupation,
        insurance:profile.insurance,
        request:profile.request,
        addiction:profile.addiction,
        update_mode:"PFL"
    },{headers})
    .then(response=>{
      const {error, message, affected, token}=response.data
      //console.log("resp",response)
      if(parseInt(error)<0){
        setUpdateMessage({error,message,affected:affected})
        localStorage.removeItem('token')
        setToken(false)
      }else {
        localStorage.setItem('token',token)
        setToken(token)
        if(parseInt(error)===0 && parseInt(affected) > 0){
          const {contactID}=response.data
          //setProfile(profile)
          if(id<=0){
            const newUrl=`${location.pathname.slice(0,location.pathname.lastIndexOf("/"))}/${contactID}`
            navigate(newUrl, {replace:true})
          }
        }
        setUpdateMessage({error,message,affected})
      } 
      setInProgress(false)
    })
  }

  function deleteAction(actionId){

    //console.log(actionId)
    const {actionCurrentPage, symptoms, diagnosis, therapeutic_plan, medication}=profile
    //console.log("page:", actionCurrentPage)
    const tkn=localStorage.getItem('token')||false
    const headers= {
      'Content-Type':'application/json',
      'Authorization': `Bearer ${tkn}`,
    }
    axiosAPI.post("profile.php",{mode:"DELACT", actionId:actionId, },{headers})
    .then(response=>{
      const {error, message, affected, token}=response.data
      if(parseInt(error)<0){
        setUpdateMessage({error,message,affected:affected})
        localStorage.removeItem('token')
        setToken(false)
      }else{
        localStorage.setItem('token',token)
        setToken(token)
        if(parseInt(error)===0 && parseInt(affected) > 0){
          const diagn = diagnosis.filter(i=>parseInt(i.action_id)!==parseInt(actionId))
          const tp = therapeutic_plan.filter(i=>parseInt(i.action_id)!==parseInt(actionId))
          const sym = symptoms.filter(i=>parseInt(i.action_id)!==parseInt(actionId))
          const mdc = medication.filter(i=>parseInt(i.action_id)!==parseInt(actionId))

          setProfile(prev=>({...prev, diagnosis:diagn, symptoms:sym, therapeutic_plan:tp, medication:mdc }))
          const cp={target:{
                    value:actionCurrentPage
                  }
          }
          changeActionPage(cp, false)
        }
        setUpdateMessage({error,message,affected})
      }

    })


  }

  function downloadProfile(pid){
    const tkn=localStorage.getItem('token')||false
    const headers= {
    'Authorization': `Bearer ${tkn}`,
    'Content-Type' : "application/json",
    }
    axiosAPI.post("report.php",{reportProfileID:pid,mode:"PFLRP" },{headers})
    .then(response=>{
      //console.log(response.data)
      const {error, message, affected, token}=response.data
      if(parseInt(error)<0){
          setUpdateMessage({error,message,affected:affected})
          localStorage.removeItem('token')
          setToken(false)
      }else {
        localStorage.setItem('token',token)
        setToken(token)
        setUpdateMessage({error,message,affected})
        if(parseInt(error)===0){
          const {tempFile, fileName, ext} =response.data
          //console.log(tempFile,' ', fileName,'.', ext)
          const newURL = `${axiosAPI.defaults.baseURL}download.php?t=${tempFile}&f=${fileName}&e=${ext}`;
          window.location.replace(newURL)
        } 
      }

    })
  }

  useEffect(()=>{
    //console.log("ok", id)
    setIsLoad(false)
    const tkn=localStorage.getItem('token')||false
    const headers= {
      'Content-Type':'application/json',
      'Authorization': `Bearer ${tkn}`,
    }
    axiosAPI.post("profile.php",{id:id}, {headers})
    .then(response=>{
      //console.log("response:", response.data)
      const {error, errorMessage, token} = response.data
      if(parseInt(error)<0){
        setUpdateMessage({error:error,message:errorMessage,affected:0})
        localStorage.removeItem('token')
        setToken(false)
      }else if (parseInt(error)===0){
        setProfile(response.data)
        setIsLoad(true)
        localStorage.setItem('token',token)
        setToken(token)
      }else{
        setIsLoad(true)
        setUpdateMessage({error:error,message:errorMessage,affected:0})
        localStorage.setItem('token',token)
        setToken(token)
      }
    })
 
  },[id, setToken])

  useEffect(()=>{
    let t=null
    if(updateMessage.message){
      const err = parseInt(updateMessage.error) === 0 ? 500:3000
       t = setTimeout(()=>{
        setUpdateMessage({error:0,message:"",affected:0})
      },err)
    }
    return ()=>{clearTimeout(t)}
  },[updateMessage])
 //----------------------------------------------------------------------------------------------------------------
 //console.log(profile)
 //console.log(profile?profile.contact.id?profile.contact.id:'no':'--')

if (!isLoad)
  return (<Loader/>)
if(profile)
  return (
        <div className={`container`} >
            { 
              !isLoad ? <Loader  text="Load" />:
              <header className="header"> 
                
                {profile.contact.id ? 
                  <span style={{float:"right", position:'relative'}}>
                    <FontAwesomeIcon icon={['fas', 'download']}  className="icons" onClick={()=>downloadProfile(id)}/>
                  </span>
                  :
                  ''
                }
                {profile.contact.id ? 
                  <span style={{float:"right", position:'relative'}}>
                    <Link to={`/appointment/new/${id}`}>
                    <FontAwesomeIcon icon={['fas', 'calendar-check']}  className="icons"/>
                    </Link>
                  </span>
                  :
                  ''
                }
                {profile.contact.id ? 
                  <span style={{float:"right", position:'relative'}}>
                    <label className="switch" style={{top:'-0.4rem'}}>
                      <input  type="checkbox" name="profile_status"  checked={parseInt(profile?.contact?.profile_status)!==0?true:false} onChange={(e)=>handleStatusChange(e)}/>
                      <span className="sw-slider round"></span>
                    </label>
                  </span>
                  :
                  ''
                }
                <h2>
                 {profile.contact.surname} {profile.contact.first_name} ({profile.contact.birth_year?new Date().getFullYear()-profile.contact.birth_year:''})
                 {profile?.contact?.profile_status === 1 ?
                  <FontAwesomeIcon icon={['fas','user-check']} style={{padding:'0 1rem',color:'var(--dec)'}}/>
                  : profile?.contact?.profile_status === 0 ?
                  <FontAwesomeIcon icon={['fas','user-slash']}   style={{padding:'0 1rem',color:'#a00000'}}/>
                  :''
                }
                 <span className="origin-flag">{getFlag()}</span>
                </h2>
                <h2>Αρ. Φακέλλου:
                  <span style={{color:profile?.contact?.profile_status === 0?'#a00000':'var(--dec)', paddingLeft:'0.5rem', fontSize:'1.1em'}}>
                    {profile.contact.folder_number}
                  </span></h2>
              </header>
            }         
            <ul className="side-bar">
                <li className={`${currentSection===1?`active-section`:false}`} onClick={(e)=>sectionClick(e,1)}><div>Προσωπικά Στοιχεία</div><div>Πρώτη τηλεφωνική επικοινωνία</div></li>
                <li className={`${currentSection===2?`active-section`:false}`} onClick={(e)=>sectionClick(e,2)}>Στοιχεία από την πρώτη επαφή</li>
                <li className={`${currentSection===3?`active-section`:false}`} onClick={(e)=>sectionClick(e,3)}>Οικογενειακό ιστορικό</li>
                <li className={`${currentSection===4?`active-section`:false}`} onClick={(e)=>sectionClick(e,4)}>Ατομικό Ιστορικό</li>
                <li className={`${currentSection===5?`active-section`:false}`} onClick={(e)=>sectionClick(e,5)}>Ψυχιατρικό Ιστορικό</li>
                <li className={`${currentSection===6?`active-section`:false}`} onClick={(e)=>sectionClick(e,6)}>Αίτημα / Αντιμετώπιση / Έκβαση</li>
                {/*
                <li className={`${currentSection===7?`active-section`:false}`} onClick={(e)=>sectionClick(e,7)}>Καθεστώς παραμονής / Πηγή παραπομπής-ενημέρωσης</li>
                <li className={`${currentSection===8?`active-section`:false}`} onClick={(e)=>sectionClick(e,8)}>Ευάλωτη κοινωνική ομάδα / Εμπειρία έμφυλης βίας </li>
                <li className={`${currentSection===9?`active-section`:false}`} onClick={(e)=>sectionClick(e,9)}>Νόσος / Πρόβλημα</li>
                */}
            </ul>
            <div className="content">
              <div className={`section ${currentSection===1?'current':''}`}>
                <div className="left-side">
                <label htmlFor="folder_number">Αρ. Μητρώου / Φακέλλου</label>
                  <input type="text" name="folder_number" id="folder_number" value={profile.contact.folder_number||''} onChange={(e)=>handleChange(e)} />
                  <label htmlFor="surname">Επώνυμο</label>
                  <input type="text" name="surname" id="surname"  value={profile.contact.surname||''} onChange={(e)=>handleChange(e)} />
                  
                  {/*<div style={{backgroundColor:"red",color:"#fff",width:"55%",maxWidth:"75%",position:"relative"}}>error</div> */}
                  <label htmlFor="first_name">Όνομα</label>
                  <input type="text" name="first_name" id="first_name" value={profile.contact.first_name||''} onChange={(e)=>handleChange(e)} />
                  
                  <label htmlFor="amka">ΑΜΚΑ</label>
                  <input type="text"   name="amka" id="amka" value={profile.contact.amka||''} onChange={(e)=>handleChange(e)} />
                  <FontAwesomeIcon icon={['fas', 'exclamation-triangle']} className={checkAMKA(profile.contact.amka)?'no-visible':'icons-warn'} />

                  <SelectField title="Φύλο" obj={'contact'} field="gender_id" tblId={null} profile={profile} change={sChange}/>
                  
                  <label htmlFor="father_name">Όνομα Πατέρα</label>
                  <input type="text" name="father_name" id="father_name" value={profile.contact.father_name||''} onChange={(e)=>handleChange(e)} />
                  
                  <label htmlFor="mother_name">Όνομα Μητέρας</label>
                  <input type="text" name="mother_name" id="mother_name" value={profile.contact.mother_name||''} onChange={(e)=>handleChange(e)} />

                  <label htmlFor="birth_year">Έτος γέννησης</label>
                  <input type="text" name="birth_year" id="birth_year"  value={profile.contact.birth_year||''} onChange={(e)=>handleChange(e)} />
                  
                  <label htmlFor="birth_place">Τόπος Γέννησης</label>
                  <input type="text" name="birth_place" id="birth_place"  value={profile.contact.birth_place||''} onChange={(e)=>handleChange(e)} />

                  <SelectField title="Χώρα καταγωγής" obj={'contact'} field="nationality_id" tblId={null} profile={profile} change={sChange} />

                  <label htmlFor="address">Διεύθυνση κατοικίας</label>
                  <input type="text" name="address" id="address"  value={profile.contact.address||''} onChange={(e)=>handleChange(e)} />
                  
                  <SelectField title="Δημος τομέα ευθύνης" obj={'contact'} field="m_unit_id" tblId={null} profile={profile} change={sChange}/>
                  {getExtraFields(profile.metadata.filter(o=>o.field==="m_unit_id"),"m_unit_id","")}
                  
                  <label htmlFor="tel">Τηλέφωνο</label>
                  <input type="text" name="tel" id="tel"  value={profile.contact.tel||''} onChange={(e)=>handleChange(e)} />
                 
                  <SelectField title="Οικογενειακή κατάσταση *" obj={'contact'} field="marital_status_id" tblId={null} profile={profile} change={sChange}/>
                
                  <RadioField title="Διαμένει" obj='contact' field="living_with_id" profile={profile}  change={handleChange} click={clearClick}/>

                  <SelectField title="Εκπαίδευση" obj={'contact'} field="education_level_id" tblId={null} profile={profile} change={sChange}/>
                
                  <HistoryListComponents title="Απασχόληση" profile={profile}  field="occupation" update={updateHistoryFields} />
                  
                  <HistoryListComponents title="Ασφάλιση / Επίδομα" profile={profile}  field="insurance" update={updateHistoryFields} />
                
                </div>
                <div className="right-side">
                  <label htmlFor="call_date">Ημερομηνία κλήσης</label>
                  <DatePicker locale={el} name="call_date" id="calll_date" 
                    selected={profile.contact.call_date ?new Date(profile.contact.call_date):''} 
                    onChange={date=>handleChangeDate('contact', 'call_date', date)}
                    dateFormat="dd / MM / yyyy"
                    preventOpenOnFocus={true}
                    isClearable
                    showMonthDropdown={true}
                    showYearDropdown={true}
                    adjustDateOnChange
                    dropdownMode="select"
                    className="date-input"
                    customInput={<InputMask mask="99 / 99 / 9999"  maskPlaceholder="_"  alwaysShowMask={true} />}
                  ></DatePicker>
                  
                  <label htmlFor="call_fullname">Ονοματεπώνυμο καλούντος</label>
                  <input type="text" name="call_fullname" id="call_fullname"  value={profile.contact.call_fullname||''} onChange={(e)=>handleChange(e)} />
                  
                  <label htmlFor="call_request">Αίτημα</label>
                  <textarea name="call_request" id="call_request" className='profile'  value={profile.contact.call_request||''} onChange={(e)=>handleChange(e)} maxLength="255"/>
                
                  <RadioField title="Σχέση με τον ωφελούμενο" obj='contact' field="call_rel_type_id" profile={profile}  change={handleChange} click={clearClick}/>

                  <label htmlFor="call_problem_duration">Πόσο καιρό σας συμβαίνει αυτό;</label>
                  <input type="text" name="call_problem_duration" id="call_problem_duration"  value={profile.contact.call_problem_duration||''} onChange={(e)=>handleChange(e)} maxLength={64} />

                  <label htmlFor='call_visit_specialist' >
                    <div>Έχετε επισκεφτεί ξανά κάποιον ειδικό;</div>
                    <input type="radio" name="call_visit_specialist" id="yes_call_visit_specialist" value={1}
                      checked={parseInt(profile.contact.call_visit_specialist)===1?true:false} 
                      onChange={(e)=>{changeWithExtra(e, "call_visit_sp_des")}}
                    />
                    <label htmlFor="yes_call_visit_specialist" className='lbl-inline'>Ναι</label>

                    <input type="radio" name="call_visit_specialist" id="no_call_visit_specialist" value={0}
                      checked={parseInt(profile.contact.call_visit_specialist)===0?true:false} 
                      onChange={(e)=>{changeWithExtra(e, "call_visit_sp_des")}}
                    />
                    <label htmlFor="no_call_visit_specialist" className='lbl-inline'>Όχι</label>
                    <FontAwesomeIcon  icon={['fas', 'eraser']} className="icons icon-sm"  onClick={(e)=>setProfile(prev=>({...prev, contact:{...prev.contact,call_visit_specialist:null,call_visit_sp_des:null} }))}/>
                  </label>
                  {
                    parseInt(profile.contact.call_visit_specialist) ?
                    <input type="text" className="details"  name='call_visit_sp_des' value={profile.contact.call_visit_sp_des||''} onChange={(e)=>handleChange(e)}/>
                    :''
                  }
                  <label htmlFor="call_address">Διεύθυνση κατοικίας καλούντος</label>
                  <input type="text" name="call_address" id="call_address"  value={profile.contact.call_address||''} onChange={(e)=>handleChange(e)} />
                  {!profile.contact.address?
                  <FontAwesomeIcon  icon={['fas', 'copy']} className="icons icon-sm"  onClick={(e)=>{copyPasteField(e,"call_address", "address")}}/>
                    :''
                  }

                  <label htmlFor="call_tel">Τηλέφωνο επικοινωνίας καλούντος</label>
                  <input type="text" name="call_tel" id="call_tel"  value={profile.contact.call_tel||''} onChange={(e)=>handleChange(e)} />
                  {!profile.contact.tel?
                  <FontAwesomeIcon  icon={['fas', 'copy']} className="icons icon-sm"  onClick={(e)=>{copyPasteField(e,"call_tel", "tel")}}/>
                  :''
                  }

                  <SelectField title="Πηγή παραπομπής/Ενημέρωσης" obj={'contact'} field="info_source_id" tblId={null} profile={profile} change={sChange}/>
                  {getExtraFields(profile.metadata.filter(o=>o.field==="info_source_id"),"info_source_id","")}
                </div>
              </div>

              <div className={`section ${currentSection===2?'current':''}`}>
                <div className="left-side">
               
                  <label htmlFor='current_problem'>Αιτία προσέλευσης / παρόν πρόβλημα
                  <FontAwesomeIcon  icon={['fas', 'edit']} className="icons icon-sm"  onClick={()=>{openEdit(2)}}/>
                  </label>
                  {edit===2 ? 
                    <EditTextarea close={closeEdit} title={['Στοιχεία από την πρώτη επαφή','Αιτία προσέλευσης / παρόν πρόβλημα']}>
                      <textarea autoFocus name="current_problem" className='edit-content'   id="current_problem" value={profile.contact.current_problem||''} onChange={(e)=>handleChange(e)} />
                    </EditTextarea>
                  :
                  <p className='text-container'>{profile.contact.current_problem}</p>
                  }
                  
                  <label htmlFor="timeline_cur_problem">Χρονικό ιστορικό του παρόντος προβλήματος
                  <FontAwesomeIcon  icon={['fas', 'edit']} className="icons icon-sm"  onClick={()=>{openEdit(3)}}/>
                  </label>
                  {edit===3 ? 
                    <EditTextarea  close={closeEdit} title={['Στοιχεία από την πρώτη επαφή','Χρονικό ιστορικό του παρόντος προβλήματος']}>
                      <textarea  autoFocus className='edit-content'  name="timeline_cur_problem" id="timeline_cur_problem"  value={profile.contact.timeline_cur_problem ||''} onChange={(e)=>handleChange(e)} />
                    </EditTextarea>
                  :
                  <p className='text-container'>{profile.contact.timeline_cur_problem}</p>
                  }       

                  <label htmlFor="other_body_issue">Άλλα σωματικά προβλήματα **
                    <FontAwesomeIcon  icon={['fas', 'edit']} className="icons icon-sm"  onClick={()=>{openEdit(4)}}/>
                  </label>
                  {edit===4 ? 
                    <EditTextarea  close={closeEdit} title={['Στοιχεία από την πρώτη επαφή','Άλλα σωματικά προβλήματα']}>
                      <textarea  autoFocus className='edit-content' id="other_body_issue" name="other_body_issue" value={profile.contact.other_body_issue ||''} onChange={(e)=>handleChange(e)} />
                    </EditTextarea>
                  :
                  <p className='text-container'>{profile.contact.other_body_issue}</p>
                  }       
                  
                </div>
                <div className="right-side">  
                 
                  <h3>Άλλα προβλήματα ψυχικής υγείας στο παρελθόν</h3>
                  <label htmlFor="times_hosp">Συνολικός αριθμός νοσηλειών</label>
                  <input type="text" className='short' name="times_hosp" id="times_hosp"  value={profile.contact.times_hosp||''} onChange={(e)=>handleChange(e)} />
                
                  <label>Πιο πρόσφατη νοσηλεία</label>
                  <label htmlFor="last_time_hosp">Πότε</label>
                  <input type="text" className='short' name="last_time_hosp" id="last_time_hosp"  value={profile.contact.last_time_hosp||''} onChange={(e)=>handleChange(e)} />
                  <label htmlFor="hospital">Που</label>
                  <input type="text" name="hospital" id="hospital"  value={profile.contact.hospital||''} onChange={(e)=>handleChange(e)} />
                  <label htmlFor="duration_hosp">Συνολικός χρόνος νοσηλείας (Μήνες)</label>
                  <input type="text" className='short' name="duration_hosp" id="duration_hosp"  value={profile.contact.duration_hosp||''} onChange={(e)=>handleChange(e)} />
                  <label htmlFor="reason_hosp">Λόγος νοσηλείας</label>
                  <input type="text" className='lg' name="reason_hosp" id="reason_hosp"  value={profile.contact.reason_hosp||''} onChange={(e)=>handleChange(e)} />
                  <RadioField title="Εισαγωγή" obj='contact' field="hospital_admission_id" profile={profile}  change={handleChange} click={clearClick}/>
                  <label htmlFor="past_medication">Φαρμακευτική αγωγή κατά την πιο πρόσφατη νοσηλεία ***</label>
                  <input type="text" className='lg' name="past_medication" id="past_medication"  value={profile.contact.past_medication||''} onChange={(e)=>handleChange(e)} />
                  
                  <label htmlFor='rehab_program' >
                    <div>Έχει παρακολουθήσει πρόγραμμα ψυχοκοινωνικής αποκατάστασης;</div>
                    <input type="radio" name="rehab_program" id="yes_rehab_program" value={1}
                      checked={parseInt(profile.contact.rehab_program)===1?true:false} 
                      onChange={(e)=>{changeWithExtra(e, "rehab_program_des")}}
                    />
                    <label htmlFor="yes_rehab_program" className='lbl-inline'>Ναι</label>

                    <input type="radio" name="rehab_program" id="no_rehab_program" value={0}
                      checked={parseInt(profile.contact.rehab_program)===0?true:false} 
                      onChange={(e)=>{changeWithExtra(e, "rehab_program_des")}}
                    />
                    <label htmlFor="no_rehab_program" className='lbl-inline'>Όχι</label>
                    <FontAwesomeIcon  icon={['fas', 'eraser']} className="icons icon-sm"  onClick={(e)=>setProfile(prev=>({...prev, contact:{...prev.contact,rehab_program:null,rehab_program_des:null} }))}/>
                  </label>
                  {
                    parseInt(profile.contact.rehab_program) ?
                    <input type="text" className="details"  name='rehab_program_des' value={profile.contact.rehab_program_des||''} onChange={(e)=>handleChange(e)}/>
                    :''
                  }

                  <label htmlFor="past_hosp_comments">Σχόλια για προβλήματα ψυχικής υγείας στο παρελθόν
                    <FontAwesomeIcon  icon={['fas', 'edit']} className="icons icon-sm"  onClick={()=>{openEdit(5)}}/>
                  </label>
                  {edit===5 ? 
                    <EditTextarea close={closeEdit} 
                    title={['Στοιχεία από την πρώτη επαφή','Άλλα προβλήματα ψυχικής υγείας στο παρελθόν','Σχόλια για προβλήματα ψυχικής υγείας στο παρελθόν']}
                  >
                      <textarea  autoFocus className='edit-content'  name="past_hosp_comments" value={profile.contact.past_hosp_comments ||''} onChange={(e)=>handleChange(e)} />
                    </EditTextarea>
                  :
                  <p className='text-container'>{profile.contact.past_hosp_comments}</p>
                  }      
                  
                  <h3>Κληρονομικότητα</h3>
                  <label htmlFor='inherit_history' >
                    <div>Υπάρχει κληρονομικό Ψυχιατρικό ιστορικό;</div>
                    <input type="radio" name="inherit_history" id="yes_rinherit_history" value={1}
                      checked={parseInt(profile.contact.inherit_history)===1?true:false} 
                      onChange={(e)=>{changeWithExtra(e, "inherit_history_des")}}
                    />
                    <label htmlFor="yes_inherit_history" className='lbl-inline'>Ναι</label>

                    <input type="radio" name="inherit_history" id="no_inherit_history" value={0}
                      checked={parseInt(profile.contact.inherit_history)===0?true:false} 
                      onChange={(e)=>{changeWithExtra(e, "inherit_history_des")}}
                    />
                    <label htmlFor="no_inherit_history" className='lbl-inline'>Όχι</label>
                    <FontAwesomeIcon  icon={['fas', 'eraser']} className="icons icon-sm"  onClick={(e)=>setProfile(prev=>({...prev, contact:{...prev.contact,inherit_history:null,rehab_program_des:null} }))}/>
                  </label>
                  {parseInt(profile.contact.inherit_history)?
                    <label> Περιγραφή κληρονομικού Ψυχιατρικού ιστορικού
                      <FontAwesomeIcon  icon={['fas', 'edit']} className="icons icon-sm"  onClick={()=>{openEdit(6)}}/>
                    </label>
                    :''
                  }
                  {edit===6 ? 
                    <EditTextarea close={closeEdit} 
                    title={['Στοιχεία από την πρώτη επαφή','Κληρονομικότητα','Περιγραφή κληρονομικού Ψυχιατρικού ιστορικού']}
                  >
                      <textarea autoFocus className='edit-content'  name="inherit_history_des" value={profile.contact.inherit_history_des ||''} onChange={(e)=>handleChange(e)} />
                    </EditTextarea>
                  :
                  parseInt(profile.contact.inherit_history)?
                  <p className='text-container'>{profile.contact.inherit_history_des}</p>
                  :''
                  }      
                </div>
              </div>

              <div className={`section ${currentSection===3?'current':''}`}>
                <div className="left-side">
                  <h3>Γάμος – Παρούσα οικογενειακή κατάσταση και σύνθεση</h3>
                  <SelectField title="Οικογενειακή κατάσταση *" obj={'contact'} field="marital_status_id" tblId={null} profile={profile} change={sChange}/>
                  <label htmlFor="husbandwife_name">Όνομα συντρόφου / συζύγου</label>
                  <input type="text" name="husbandwife_name" id="husbandwife_name"  value={profile.contact.husbandwife_name||''} onChange={(e)=>handleChange(e)} />
                  
                  <label htmlFor="hw_age">Ηλικία συντρόφου / συζύγου</label>
                  <input type="text" name="hw_age" id="hw_age" className="short" value={showAge('hw_birth_year')} onChange={(e)=>handleChangeAge('hw_birth_year',e)}/>
                  <span> {profile.contact.hw_birth_year}</span>
                  <SelectField title="Επάγγελμα συντρόφου / συζύγου" obj={'contact'} field="hw_occupation_id" tblId={null} profile={profile} change={sChange}/>
                  <label htmlFor="hw_rel">Σχέση</label>
                  <input type="text" name="hw_rel" id="hw_rel"  value={profile.contact.hw_rel||''} onChange={(e)=>handleChange(e)} />
                  
                  <label >Άλλες πληροφορίες
                    <FontAwesomeIcon  icon={['fas', 'edit']} className="icons icon-sm"  onClick={()=>{openEdit(7)}}/>
                  </label>
                  {edit===7 ? 
                    <EditTextarea close={closeEdit} 
                    title={['Οικογενειακό ιστορικό','Γάμος – Παρούσα οικογενειακή κατάσταση και σύνθεση','Άλλες πληροφορίες']}
                    >
                      <textarea autoFocus  name="hw_info" className='edit-content' value={profile.contact.hw_info ||''} onChange={(e)=>handleChange(e)} />
                    </EditTextarea>
                  :
                  <p className='text-container'>{profile.contact.hw_info}</p>
                  }       
                  <FamilyList  
                    title="Παιδιά" 
                    field="children" 
                    profile={profile}  
                    update={updateFamily}
                  /> 

                  <label >Άλλοι συγγενείς
                    <FontAwesomeIcon  icon={['fas', 'edit']} className="icons icon-sm"  onClick={()=>{openEdit(8)}}/>
                  </label>
                  {edit===8 ? 
                    <EditTextarea close={closeEdit} 
                    title={['Οικογενειακό ιστορικό','Γάμος – Παρούσα οικογενειακή κατάσταση και σύνθεση','Άλλοι συγγενείς']}
                    >
                      <textarea autoFocus className='edit-content'  name="relatives" value={profile.contact.relatives ||''} onChange={(e)=>handleChange(e)} />
                    </EditTextarea>
                  :
                  <p className='text-container'>{profile.contact.relatives}</p>
                  }       
                  
                </div>
                <div className="right-side">
                  <h3>Πατρική Οικογένεια</h3>
                  <label>Όνομα Πατέρα</label>
                  <input type="text" readOnly={true} value={profile.contact.father_name ||''}/>
                  <label htmlFor="father_age">Ηλικία Πατέρα</label>
                  <input type="text" name="father_age" id="father_age" className="short" value={showAge('father_birth_year')} onChange={(e)=>handleChangeAge('father_birth_year',e)}/>
                  <span>{profile.contact.father_birth_year}</span>
                  <SelectField title="Επίπεδο εκπαίδευσης πατέρα" obj={'contact'} field="father_education_level_id" tblId={null} profile={profile} change={sChange}/>
                  <SelectField title="Επάγγελμα πατέρα" obj={'contact'} field="father_occupation_id" tblId={null} profile={profile} change={sChange}/>
                  <label htmlFor="father_rel">Σχέση</label>
                  <input type="text" name="father_rel" id="father_rel"  value={profile.contact.father_rel||''} onChange={(e)=>handleChange(e)} />
                  <label>Όνομα Μητέρας</label>
                  <input type="text" readOnly={true} value={profile.contact.mother_name ||''}/>
                  <label htmlFor="mother_age">Ηλικία Μητέρας</label>
                  <input type="text" name="mother_age" id="mother_age" className="short" value={showAge('mother_birth_year')} onChange={(e)=>handleChangeAge('mother_birth_year',e)}/>
                  <span>{profile.contact.mother_birth_year}</span>
                  <SelectField title="Επίπεδο εκπαίδευσης μητέρας" obj={'contact'} field="mother_education_level_id" tblId={null} profile={profile} change={sChange}/>
                  <SelectField title="Επάγγελμα μητέρας" obj={'contact'} field="mother_occupation_id" tblId={null} profile={profile} change={sChange}/>
                  <label htmlFor="mother_rel">Σχέση</label>
                  <input type="text" name="mother_rel" id="mother_rel"  value={profile.contact.mother_rel||''} onChange={(e)=>handleChange(e)} />
                  

                  <SelectField title="Οικογενειακή κατάσταση γονιών" obj={'contact'} field="parent_marital_status_id" tblId={null} profile={profile} change={sChange}/>
                  
                  <FamilyList  
                    title="Αδέλφια" 
                    field="brothers" 
                    profile={profile}  
                    update={updateFamily}
                  />  
                  <label >Άλλες πληροφορίες και σχόλια για την πατρική οικογένεια
                    <FontAwesomeIcon  icon={['fas', 'edit']} className="icons icon-sm"  onClick={()=>{openEdit(9)}}/>
                  </label>
                  {edit===9 ? 
                    <EditTextarea close={closeEdit} 
                    title={['Οικογενειακό ιστορικό','Πατρική Οικογένεια','Άλλες πληροφορίες και σχόλια για την πατρική οικογένεια']}
                    >
                      <textarea autoFocus className='edit-content'  name="father_family_info" value={profile.contact.father_family_info ||''} onChange={(e)=>handleChange(e)} />
                    </EditTextarea>
                  :
                  <p className='text-container'>{profile.contact.father_family_info}</p>
                  }       
                </div>
              </div>

              <div className={`section ${currentSection === 4?'current':''}`}>
                <div className="left-side">

                <label >Σπουδές / Επαγγελματική απασχόληση
                    <FontAwesomeIcon  icon={['fas', 'edit']} className="icons icon-sm"  onClick={()=>{openEdit(10)}}/>
                  </label>
                  {edit===10 ? 
                    <EditTextarea close={closeEdit} 
                    title={['Ατομικό ιστορικό','Σπουδές / Επαγγελματική απασχόληση']}
                    >
                      <textarea autoFocus className='edit-content'  name="stocc_comments" value={profile.contact.stocc_comments ||''} onChange={(e)=>handleChange(e)} />
                    </EditTextarea>
                  :
                  <p className='text-container'>{profile.contact.stocc_comments}</p>
                  }       
                  <label >Κοινωνική προσαρμογή
                    <FontAwesomeIcon  icon={['fas', 'edit']} className="icons icon-sm"  
                      onClick={()=>{openEdit(11)}}
                    />
                  </label>
                  {edit===11 ? 
                    <EditTextarea close={closeEdit} 
                    title={['Ατομικό ιστορικό','Κοινωνική προσαρμογή']}
                    >
                      <textarea autoFocus className='edit-content'  name="social_adaption" value={profile.contact.social_adaption ||''} onChange={(e)=>handleChange(e)} />
                    </EditTextarea>
                  :
                  <p className='text-container'>{profile.contact.social_adaption}</p>
                  }       
                  <label htmlFor="other_body_issue">Σωματικές νόσοι και συμπτώματα **
                    <FontAwesomeIcon  icon={['fas', 'edit']} className="icons icon-sm"  onClick={()=>{openEdit(12)}}/>
                  </label>
                  {edit===12 ? 
                    <EditTextarea close={closeEdit} 
                      title={['Ατομικό ιστορικό','Σωματικές νόσοι και συμπτώματα']} 
                    >
                      <textarea autoFocus className='edit-content' id="obi2" name="other_body_issue" value={profile.contact.other_body_issue ||''} onChange={(e)=>handleChange(e)} />
                    </EditTextarea>
                  :
                  <p className='text-container'>{profile.contact.other_body_issue}</p>
                  }             
            
                </div>
                <div className="right-side">
                  <CheckBoxField title="Έξεις και συνήθειες" field="addiction" options={profile.metadata.filter(o=>o.field==='addiction')} values={profile['addiction']} change={handleCheckBoxChange}/>
                  <label htmlFor="other_body_issue">Παρατηρήσεις
                    <FontAwesomeIcon  icon={['fas', 'edit']} className="icons icon-sm"  onClick={()=>{openEdit(13)}}/>
                  </label>
                  {edit===13 ? 
                    <EditTextarea close={closeEdit} 
                      title={['Ατομικό ιστορικό','Έξεις και συνήθειες','Παρατηρήσεις']} 
                    >
                      <textarea autoFocus className='edit-content'  name="addiction_comments" value={profile.contact.addiction_comments ||''} onChange={(e)=>handleChange(e)} />
                    </EditTextarea>
                  :
                  <p className='text-container'>{profile.contact.addiction_comments}</p>
                  }                 
                  <label htmlFor='law_problem' ><div>Υπάρχει εμπλοκή με το νόμο;</div>
                    <input type="radio" name="law_problem" id="yes_law_problem" value={1}
                      checked={parseInt(profile.contact.law_problem)===1?true:false} 
                      onChange={(e)=>{changeWithExtra(e, "law_problem_comments")}}
                    />
                    <label htmlFor="yes_law_problem" className='lbl-inline'>Ναι</label>

                    <input type="radio" name="law_problem" id="no_law_problem" value={0}
                      checked={parseInt(profile.contact.law_problem)===0?true:false}  
                      onChange={(e)=>{changeWithExtra(e, "law_problem_comments")}}
                    />
                    <label htmlFor="no_law_problem" className='lbl-inline'>Όχι</label>
                    <FontAwesomeIcon icon={['fas', 'eraser']} className="icons icon-sm"  onClick={(e)=>setProfile(prev=>({...prev, contact:{...prev.contact,law_problem:null,law_problem_comments:null } }))}/>
                  </label>
                  {parseInt(profile.contact.law_problem)?
                    <label> Διευκρίνηση
                      <FontAwesomeIcon  icon={['fas', 'edit']} className="icons icon-sm"  onClick={()=>{openEdit(14)}}/>
                    </label>
                    :''
                  }
                  {edit===14 ? 
                    <EditTextarea close={closeEdit} 
                    title={['Ατομικό ιστορικό','εμπλοκή με το νόμο','Διευκρίνηση']}
                  >
                      <textarea autoFocus className='edit-content'  name="law_problem_comments" value={profile.contact.law_problem_comments ||''} onChange={(e)=>handleChange(e)} />
                    </EditTextarea>
                  :
                    parseInt(profile.contact.law_problem)?
                    <p className='text-container'>{profile.contact.law_problem_comments}</p>
                    :''
                  }     
                   
                  <label>Άλλες σημαντικές πληροφορίες ή γεγονότα
                    <FontAwesomeIcon  icon={['fas', 'edit']} className="icons icon-sm"  onClick={()=>{openEdit(15)}}/>
                  </label>
                  {edit===15 ? 
                    <EditTextarea close={closeEdit} 
                      title={['Ατομικό ιστορικό','Άλλες σημαντικές πληροφορίες ή γεγονότα']} 
                    >
                      <textarea autoFocus className='edit-content'  name="info_events" value={profile.contact.info_events ||''} onChange={(e)=>handleChange(e)} />
                    </EditTextarea>
                  :
                  <p className='text-container'>{profile.contact.info_events}</p>
                  }                
                </div>
              </div>
              <div className={`section ${currentSection===5?'current':''}`}>
                <div className="left-side">
                  <label>Κλινική εκτίμηση
                    <FontAwesomeIcon  icon={['fas', 'edit']} className="icons icon-sm"  onClick={()=>{openEdit(16)}}/>
                  </label>
                  {edit===16 ? 
                    <EditTextarea close={closeEdit} 
                      title={['Ψυχιατρικό Ιστορικό','Κλινική εκτίμηση']} 
                    >
                      <textarea autoFocus className='edit-content'  name="clinic_est" value={profile.contact.clinic_est ||''} onChange={(e)=>handleChange(e)} />
                    </EditTextarea>
                  :
                  <p className='text-container'>{profile.contact.clinic_est}</p>
                  }                
                  <label htmlFor='suicidality' ><div>Αυτοκτονικότητα</div>
                    <input type="radio" name="suicidality" id="yes_suicidality" value={1}
                      checked={parseInt(profile.contact.suicidality)===1?true:false} 
                      onChange={(e)=>{changeWithExtra(e, ['suicide_attempts','has_hosp_after_attempt','hosp_after_attempt_date'])}}
                    />
                    <label htmlFor="yes_suicidality" className='lbl-inline'>Ναι</label>

                    <input type="radio" name="suicidality" id="no_suicidality" value={0}
                      checked={parseInt(profile.contact.suicidality)===0?true:false} 
                      onChange={(e)=>{changeWithExtra(e, ['suicide_attempts', 'has_hosp_after_attempt', 'hosp_after_attempt_date'])}}
                    />
                    <label htmlFor="no_suicidality" className='lbl-inline'>Όχι</label>
                    <FontAwesomeIcon  icon={['fas', 'eraser']} className="icons icon-sm"  
                    onClick={(e)=>setProfile(prev=>({...prev, contact:{...prev.contact,
                      suicidality:null,suicide_attempts:null, has_hosp_after_attempt:null, hosp_after_attempt_date:null
                    }}))}/>
                  </label>
                  <label htmlFor="suicide_attempts">Απόπειρες αυτοκτονίας</label>
                  <div className="explain">(Συνολικός Αριθμός)</div>
                  <input type="text" className='short' name="suicide_attempts" id="suicide_attempts"  
                    value={profile.contact.suicide_attempts||''} onChange={(e)=>handleChange(e)} 
                    disabled={!parseInt(profile.contact?.suicidality)}
                  />
                  <label htmlFor='has_hosp_after_attempt' >
                    <div>Νοσηλεία σαν αποτέλεσμα της απόπειρας</div>
                    <input type="radio" name="has_hosp_after_attempt" id="yes_has_hosp_after_attempt" value={1}
                      checked={parseInt(profile.contact.has_hosp_after_attempt)===1?true:false} onChange={(e)=>{handleChange(e)}}
                      disabled={!parseInt(profile.contact?.suicidality)}
                    />
                    <label htmlFor="yes_has_hosp_after_attempt" className='lbl-inline'>Ναι</label>

                    <input type="radio" name="has_hosp_after_attempt" id="no_has_hosp_after_attempt" value={0}
                      checked={parseInt(profile.contact.has_hosp_after_attempt)===0?true:false} onChange={(e)=>{handleChange(e)}}
                      disabled={!parseInt(profile.contact?.suicidality)}
                    />
                    <label htmlFor="no_has_hosp_after_attempt" className='lbl-inline'>Όχι</label>
                    <FontAwesomeIcon  icon={['fas', 'eraser']} className="icons icon-sm"  onClick={(e)=>setProfile(prev=>({...prev, contact:{...prev.contact,has_hosp_after_attempt:null} }))}/>
                  </label>
                  <label htmlFor="hosp_after_attempt_date">Πότε</label>
                  <div className="explain">Η πιό πρόσφατη</div>
                  <DatePicker locale={el} name="hosp_after_attempt_date" id="hosp_after_attempt_date" 
                    selected={profile.contact.hosp_after_attempt_date ?new Date(profile.contact.hosp_after_attempt_date):''} 
                    onChange={date=>handleChangeDate('contact', 'hosp_after_attempt_date', date)}
                    dateFormat="dd / MM / yyyy"
                    preventOpenOnFocus={true}
                    isClearable
                    showMonthDropdown={true}
                    showYearDropdown={true}
                    adjustDateOnChange
                    dropdownMode="select"
                    className="date-input"
                    disabled={!parseInt(profile.contact?.suicidality) || !parseInt(profile.contact?.has_hosp_after_attempt)}
                    customInput={<InputMask mask="99 / 99 / 9999"  maskPlaceholder="_"  alwaysShowMask={true} />}
                  ></DatePicker>

                  <label>Ιστορικό αυτοκτονίες/απόπειρες στην οικογένεια & σχόλια
                    <FontAwesomeIcon  icon={['fas', 'edit']} className="icons icon-sm"  onClick={()=>{openEdit(17)}}/>
                  </label>
                  {edit===17 ? 
                    <EditTextarea close={closeEdit} 
                      title={['Ψυχιατρικό Ιστορικό','Ιστορικό με αυτοκτονίες/απόπειρες στην ευρύτερη οικογένεια &  σχόλια']} 
                    >
                      <textarea autoFocus className='edit-content'  name="suicide_comments" value={profile.contact.suicide_comments ||''} onChange={(e)=>handleChange(e)} />
                    </EditTextarea>
                  :
                  <p className='text-container'>{profile.contact.suicide_comments}</p>
                  }              
                  <label htmlFor="past_medication">Προηγούμενη φαρμακευτική αγωγή ***</label>
                  <input type="text" className='lg' name="past_medication" id="past_medication_2"  value={profile.contact.past_medication||''} onChange={(e)=>handleChange(e)} />
                </div>
                <div className="right-side">
                  
                  <ActionDependField profile={profile} field='symptoms' title="Συμπτώματα" update={updateArrayFields}/>
                  <DiagnosisList profile={profile} field='diagnosis'  update={updateArrayFields} />
                  {/*<ActionDependField profile={profile} field='diagnosis' title="Διάγνωση" update={updateArrayFields}/>*/}
                  <ActionDependField profile={profile} field='medication' title="Φαρμακευτική αγωγή" update={updateArrayFields}/>
                  <ActionDependField profile={profile} field='therapeutic_plan' title="Θεραπευτικό Πλάνο" update={updateArrayFields}/>
                  
                  <label>Ψυχιατρικό Οικογενειακό Ιστορικό
                    <FontAwesomeIcon  icon={['fas', 'edit']} className="icons icon-sm"  onClick={()=>{openEdit(18)}}/>
                  </label>
                  {edit===18 ? 
                    <EditTextarea close={closeEdit} 
                      title={['Ψυχιατρικό Ιστορικό','Ψυχιατρικό Οικογενειακό Ιστορικό']} 
                    >
                      <textarea  autoFocus className='edit-content'  name="psy_family_hist" value={profile.contact.psy_family_hist ||''} onChange={(e)=>handleChange(e)} />
                    </EditTextarea>
                  :
                  <p className='text-container'>{profile.contact.psy_family_hist}</p>
                  }  
                  <label>Προτεινόμενες Θεραπευτικές Παρεμβάσεις
                    <FontAwesomeIcon  icon={['fas', 'edit']} className="icons icon-sm"  
                      onClick={()=>{openEdit(19)}}
                    />
                  </label>
                  {edit===19 ? 
                    <EditTextarea close={closeEdit} 
                      title={['Ψυχιατρικό Ιστορικό','Προτεινόμενες Θεραπευτικές Παρεμβάσεις']} 
                    >
                      <textarea autoFocus className='edit-content' name="therapeutic_actions" value={profile.contact.therapeutic_actions ||''} onChange={(e)=>handleChange(e)} />
                    </EditTextarea>
                  :
                  <p className='text-container'>{profile.contact.therapeutic_actions}</p>
                  }                          
                  
                </div>
              </div>
              

              <div className={`section ${currentSection===6?'current':''}`}>
                
                  {/*<CheckBoxField title="Συμπτώματα" field="symptoms" options={profile.metadata.filter(o=>o.field==='symptoms')} values={profile['symptoms']} change={handleCheckBoxChange}/>*/}
                  <RequestListComponent profile={profile} title="Αίτημα" update={requestUpdate}/>
               
              </div>
            
            {/**
              <div className={`section ${currentSection===7?'current':''}`}>
                <div className="left-side">
                  <HistoryListComponents title="Καθεστώς παραμονής στην Ελλάδα" profile={profile}  field="remain_status" update={updateHistoryFields} />
                  <fieldset>
                    <legend>Παραπέμπων γιατρός</legend>
                    <label htmlFor="doctor_amka">ΑΜΚΑ</label>
                    <input type="text" name="doctor_amka" id="doctor_amka"  value={profile.contact.doctor_amka||''} onChange={(e)=>handleChange(e)} />
                    <FontAwesomeIcon icon={['fas', 'exclamation-triangle']} className={checkAMKA(profile.contact.doctor_amka)?'no-visible':'icons-warn'} />
                    <FontAwesomeIcon icon={['fas', 'search']} className="icons" onClick={()=>findDoctor(profile.contact.doctor_amka)}/>

                   
                    
                    <label htmlFor="doctor_name">Ονοματεπώνυμο</label>
                    <input type="text" name="doctor_name" id="doctor_name"  value={profile.contact.doctor_name||''} onChange={(e)=>handleChange(e)} />

                    <label htmlFor="doctor_sp">Ειδικότητα</label>
                    <input type="text" name="doctor_sp" id="doctor_sp"  value={profile.contact.doctor_sp||''} onChange={(e)=>handleChange(e)} />

                  </fieldset>
                </div>
                <div className="right-side">
                  <RadioField title="Πηγή παραπομπής/Ενημέρωσης" obj='contact' field="info_source_id" profile={profile}  change={handleChange} click={clearClick}/>
                  
                </div>
              </div>
             */}
              
             {/*
              <div className={`section ${currentSection===8?'current':''}`}>
                <div className="left-side">
                 
                </div>
                <div className="right-side">
                  <h3>Εμπειρία έμφυλης βίας</h3>
                  <CheckBoxField title="Τύπος βίας" field="violence_type" options={profile.metadata.filter(o=>o.field==='violence_type')} values={profile['violence_type']} change={handleCheckBoxChange}/>
                  <RadioField title="Σχέση θύματος - δράστη" obj='contact' field="victim_rel_id" profile={profile}  change={handleChange} click={clearClick}/>
                </div>
              </div>
              */}
              
              

              {/*<button type="button" name="btn" onClick={(e)=>handleClick(e)}>go!</button>*/}

              {!editAction?
              <>
                <button type="button" disabled={inProgress} name="btnSave" className='update-btn' onClick={(e)=>updateRecord(e)}>
                  {inProgress?<FontAwesomeIcon icon={['fas', 'spinner']}  spin />:'Αποθήκευση'}
                  </button>
                <span className='ch-pass-btn' style={{marginLeft:'4em'}} onClick={(e)=>{navigate(-1)}}>Επιστροφή</span>
                {id>0?'':''}
              </>
               :''     
             }
              {/** checck for roles here.... */}
              <div ref={listPoint}>
                {editAction ?
                  <Action profile={profile} saveAction={saveAction} actionId={editAction} msg={setUpdateMessage} updateProfile={setProfile}/>
                  :
                  <ActionProfileList profile={profile}  changePage={changeActionPage} edit={handleEditAction} curAction={currentAction} deleteAction={deleteAction}/>
                }
              </div>
              
            
            </div>
           
            <footer className="footer">Terms etc...</footer>
            
            { 
                updateMessage.message ? 
                <div className={`message-box ${getMessageClass(updateMessage.error, updateMessage.affected)}`}>{updateMessage.message}</div>
                :
                ''
            } 

        </div>
    )
    else 
    return updateMessage.message ? <pre className={`message-box ${getMessageClass(updateMessage.error, updateMessage.affected)}`}>{updateMessage.message}</pre>:''
    
})
