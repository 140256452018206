
import React, {useState} from 'react'
import {useDialog, ModalContent, ModalFooter} from 'react-st-modal'
import Select from 'react-select'
//import axiosAPI from '../services/axiosapi'
import "../style/modal.css"

export default function DiagnosisDialogContent(props) {
    
    const dialog = useDialog()
    const noOptionsText="Δεν υπάρχουν επιλογές"
    //const action_id = props.action
    const options=props.options.map(o=>({value:o.id, label:`(${o.diagnosis_code}) ${o.des}`}))
    const valarr=props.values.map(v=>v.option_id)
    const temp = options.filter(o=>valarr.includes(o.value)).map(v=>v.value)
    const [values, setValues] = useState(temp)
    const [isLoad]=useState(true)
   // const [action, setAction]=useState({})
    
    
/*
    useEffect(()=>{
        setIsLoad(false)
        axiosAPI.post("action.php",{id:action_id})
        .then(response=>{
          //console.log("response:", response.data)
          setIsLoad(true)
          setAction(response.data)
        })
      },[action_id])
   */   
      function handleChange(e){
          console.log('ch:',e)
          setValues(Array.isArray(e)? e.map(x=>x.value):[])
      }
      /*
      function  filterOption (option, inputValue){
        return inputValue ?
             !option.label.substring(0,inputValue.length).localeCompare(inputValue, 'el-GR',{sensitivity:'base'}): true
        }
        */
       /* function formatOptionLabel({label}){
        return (
            <span style={{fontSize:'1.4em',background:'var(--dec)',color:'#fff'}}>
            {label}
            </span>
            )
        }*/
        
        const brandColor = 'var(--dec)'
        const customStyles = {
            control: (base, state) => ({
                ...base,
                paddingTop:'0.4em',
                paddingBottom:'0.4em',
                boxShadow: state.isFocused ? 0 : 0,
                borderColor: state.isFocused
                ? brandColor
                : base.borderColor,
                '&:hover': {
                borderColor: state.isFocused
                    ? brandColor
                    : base.borderColor,
                },
            }),
            multiValueLabel: (styles) => ({
                ...styles,
               color:brandColor,backgroundColor:'whitesmoke',fontSize:'1em', padding:'0.5em'
              }),
            
              
            }
    return (
        !isLoad ? 
    
        <h3>Load...</h3>
        :
        <>
        {console.log(props)}
        <div style={{minHeight:"70vh"}}>
        <ModalContent>
           
               
            <Select 
                name='diagnosis' 
                options={options} 
                isClearable={true}
                placeholder={'Επιλέξτε ...'}
                isMulti={true}
                //value={options.filter(x=>values.includes(x.value))}
                value={options.filter(x=>values.includes(x.value))}
                //filterOption={filterOption}
                onChange={handleChange}
                noOptionsMessage={()=>noOptionsText}
                //className="sel-country"
                // formatOptionLabel={formatOptionLabel}
                styles={customStyles}
              //menuIsOpen={true}
              // minMenuHeight={500}
               //maxMenuHeight={800}
               //menuPlacement="top"
               //menuPosition="fixed" 
            />
           </ModalContent>
           </div>
           <ModalFooter>
                <button className="return-btn" onClick={()=>{dialog.close(values)}}>Κλείσιμο</button>
                <button className="return-btn" onClick={()=>{dialog.close(false)}}>Άκυρο</button>
          </ModalFooter>
        </>
           
        
        
    
    )
}
